import React from 'react'
//import PropTypes from 'prop-types'
import {Col} from 'react-bootstrap'
import {Link} from 'react-router-dom';

import './css/Content.css'

import {trackEvent} from "../../utils/tracking"

const TAX_RATE=1.1

function round10(num){
  return Math.round(num / 1) * 1
}
class Content extends React.Component {
  constructor(props) {
    super(props);
    this.onImageLoad = this.onImageLoad.bind(this);
    this.state = {
      imageHeight: 0
    }
  }

  onImageLoad() {
    this.setState({
      imageHeight: this.imageRef.width
    })
  }

  render() {
    let content = (
      <Col className="content">
        {
          this.props.state === 'prepare' &&
          <div className="coming_soon">
            Coming Soon
          </div>
        }
                {
          this.props.state === 'soldout' &&
          <div className="coming_soon">
            Sold Out
          </div>
        }
        <Col className="main_image">

          <img className="img-responsive" src={this.props.thumbnail} alt={this.props.name + " image"}
               onLoad={this.onImageLoad} ref={ref => {
            this.imageRef = ref
          }}/>
        </Col>
        <Col className="name" xs={12}>
          {this.props.name}
        </Col>
        <Col className="price" xs={12}>
          {this.props.sale_price &&
          <span className="sale_price">
              <s>
                ¥{round10(this.props.price*TAX_RATE).toLocaleString()}
              </s>
            </span>
          }
          {this.props.sale_price ?
            <span>¥{round10(this.props.sale_price*TAX_RATE).toLocaleString()}</span>
            :
            <span>¥{round10(this.props.price*TAX_RATE).toLocaleString()}</span>
          }
        </Col>
        <Col className="age" xs={12}>
          対象年齢: {this.props.tags[0]}
        </Col>
        <Col className="manufacturer" xs={12}>
          出版社: {this.props.manufacturer}
        </Col>
        {this.props.sophy_textbook &&
          <Col className="sophy_textbook" xs={12}>
            ※Sophy対応教材
          </Col>
        }
      </Col>
    );

    if (this.props.state === 'prepare') {
      return (
        content
      )
    } else if (this.props.link) {
      return (
        <a href={this.props.link} onClick={() => {trackEvent("PRODUCT", "Click product: " + this.props.link, "")}}>
          {content}
        </a>
      );
    } else {
      return (
        <Link to={"/product/" + this.props.id} onClick={() => {trackEvent("PRODUCT", "Click product: " + this.props.id, "")}}>
          {content}
        </Link>
      );
    }
  }
}

export default Content;
