import React from 'react'
//import PropTypes from 'prop-types'
import {Row, Col} from 'react-bootstrap'
import Goods from './Goods'

class GoodsList extends React.Component {
  render() {
    return (
      <Row>
                <div style={{marginTop:'20px', marginLeft:'10px'}}>＊商品はすべて税込価格で表示されています。</div>
        {
          this.props.goods.map((good) => (
            <Col key={good.id} sm={3} xs={4}>
              <Goods key={good.id} {...good} />
            </Col>
          ))
        }
      </Row>
    );
  }
}

export default GoodsList;
