import React, {Component} from 'react'
import {Row, Col, Grid} from 'react-bootstrap'
import review_web from './img/review_web.png'
import review_tablet from './img/review_tablet.png'
import review_mobile from './img/review_mobile.png'

class RealReview extends Component {
  render() {
    return (
      <Grid>
        <Row>
          <Col className="real_review" xs={12}
               xsHidden={true} smHidden={true}>
            <img src={review_web} alt="real_review"/>
          </Col>

          <Col className="real_review" xs={12}
               lgHidden={true} mdHidden={true} xsHidden={true}>
            <img src={review_tablet} alt="real_review"/>
          </Col>

          <Col className="real_review" xs={12}
               lgHidden={true} mdHidden={true} smHidden={true}>
            <img src={review_mobile} alt="real_review"/>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default RealReview;
