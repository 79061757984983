import axios from "axios";
import React from "react";
import { Col, Grid, Panel, PanelGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Footer from "../../footer/Footer";
import Loading from "../../utils/Loading";
import { LOG } from "../../utils/Log";
import {
  STORE_API_URL,
  STORE_DOMAIN,
  STORE_REST_PRODUCTS,
} from "../../utils/url";
import "./css/Mamari.css";
//import DetailReview from './DetailReview'
import MamariDetailContent from "./MamariDetailContent";
import MamariDetailInfo from "./MamariDetailInfo";

class Mamari extends React.Component {
  state = {
    product: undefined,
  };

  getDetail = (jwt) => {
    LOG("Detail @ getDetail , jwt : " + jwt);

    const request_url =
      STORE_API_URL + STORE_REST_PRODUCTS + "/" + this.props.productId;
    LOG("request_url : " + request_url);

    axios
      .get(request_url, {
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((response) => {
        LOG(response.data.data);
        if (response.data.data === null || response.data.data === undefined) {
          alert("No access this product");
          window.location = STORE_DOMAIN;
          return;
        }

        this.setState({
          ...this.state,
          product: response.data.data,
        });
      })
      .catch((error) => {
        LOG(error);
      });
  };

  componentWillMount() {
    LOG("Detail @ componentWillMount");

    this.getDetail(this.props.jwt);
  }

  componentDidMount() {
    LOG("Detail @ componentDidMount");
    window.scrollTo(0, 0);
  }

  render() {
    LOG("Detail @ render");

    LOG("this.state.product : " + this.state.product);
    if (this.state.product === undefined) {
      return <Loading />;
    }

    return (
      <div>
        <MamariDetailContent
          description_image={this.state.product.description_image}
          description={this.state.product.description}
        />
        <MamariDetailInfo
          product={this.state.product}
          jwt={this.props.jwt}
          history={this.props.history}
          isAuthenticated={this.props.isAuthenticated}
        />
        {/* <Grid>
          <Row>
            <Link to={"/product/cbc8b9e9-69f6-5471-88d4-89b631b6ffa3"}>
              <span>購入ページに進む</span>
            </Link>
          </Row>
        </Grid> */}
        <Grid style={{ paddingTop: "20px" }}>
          <Row>
            <Col xs={12} className="intro_p8_faq text-center">
              <PanelGroup accordion id="p8a" className="p8_accordion">
                {qAndA &&
                  qAndA.contents.map((data, i) => (
                    <Panel eventKey={i} key={data.category}>
                      <Panel.Heading>
                        <Panel.Title toggle>{data.category}</Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <PanelGroup
                          accordion
                          id="p8a2"
                          className="p8_accordion accordion_2"
                        >
                          {data.data.map((item, j) => (
                            <Panel eventKey={j} key={data.category + j}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span>{item.question}</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span>{item.answer}</span>
                                </div>
                              </Panel.Body>
                            </Panel>
                          ))}
                        </PanelGroup>
                      </Panel.Body>
                    </Panel>
                  ))}
              </PanelGroup>
            </Col>
          </Row>
        </Grid>
        <Footer marginTop={0} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Mamari);

const qAndA = {
  contents: [
    {
      category: "サービスについて",
      data: [
        {
          question: "レンタルする商品は新品ですか？",
          answer:
            "レンタルサービスのため、新品の場合もありますが、中古品の場合もございます。 中古品の場合も、動作確認やクリーニング済みのため、 安心してお使いいただけます。新品のご指定はできかねますことご了承ください。尚、学習データはアカウントに紐づけて管理されており、初期設定時にご利用者様のアカウントにてMusioにログインすることで前のご利用者様のデータが引き継がれることはありませんのでご安心ください。",
        },
        {
          question: "決められた利用期間がありますか？",
          answer:
            "ありません。お客様ご自身により利用終了のお申込をされるまでは、継続的にご利用になれるサービスとなっております。",
        },
        {
          question: "入会費などの初期費用はありますか？",
          answer:
            "入会金やシステム利用料などの初期費用はかかりません。そのため、ご利用開始に必要なのは、お届け月の翌月からかかります月額利用料金のみとなっております。",
        },
        {
          question:
            "途中で引っ越した場合、サービスを引き続き利用することはできますか？",
          answer:
            "国内でお引越しする場合は引き続きご利用になれます。海外へお引越しされる場合はご継続できませんので、大変申し訳ありませんがWebサイト上にてご利用終了のお申込をお願いします。",
        },
        {
          question: "利用している商品を購入することはできますか？\n",
          answer:
            "はい、一部商品を除き、お客様による購入をご選択いただけます。例えば、6ヶ月プランをご利用の場合は、最低利用月数である6ヶ月が経過すれば購入いただけます。商品小売価格とその時点でお支払い済みの合計月額利用料との差額をお支払いいただきます。",
        },
        {
          question: "商品を売却、廃棄、譲渡することはできますか？\n",
          answer:
            "交換時、ご利用終了時に商品を弊社にご返却いただきますので、途中で売却、廃棄、譲渡についてはご容赦いただいております。商品をご返却いただけない場合は、違約金(中途解約手数料と同額)を追加で請求する場合がありますのであらかじめご了承ください。",
        },
      ],
    },
    {
      category: "Musio関連",
      data: [
        {
          question: "Musioはどのようなロボットですか。",
          answer:
            "人工知能を搭載した英語学習ロボットです。詳しくは、下記のページをご覧ください。https://themusio.com",
        },
        {
          question: "Musioを使うにはどのような環境が必要ですか？",
          answer:
            "Wi-Fiネットワーク環境が必要です。ご購入前にご使用のWi-Fiルーターやモデムにてご確認ください。\n\n●ネットワークの規格：WPA/WPA2-PSK暗号化ネットワーク\n●ルーター/モデムの規格：2.4GhzのB/G/Nルーター",
        },
        {
          question: "Musioの起動方法は？",
          answer:
            "Musioのおしりのふたを開けてください。真ん中の丸いボタンを、Musioのディスプレイが表示されるまで強めに押してください。詳細は、ユーザーガイドもご参照ください。\n\n●ユーザーガイド：https://themusio.com/support/guide",
        },
        {
          question: "Musioの充電方法は？",
          answer:
            "Musioのおしりのふたを開けてください。 一番左にあるポートの左半分が充電ポートになります。付属のアダプター及びケーブルをご利用ください。\n充電方法の詳細はユーザーガイド及びMusio公式ブログもご参照ください。\n\n●ユーザーガイド：https://themusio.com/support/guide\n●公式ブログ：http://blog.themusio.com/2016/12/02/how-to-charge/\n\nまた、長い間ご使用いただくためにも充電の際は下記ご留意ください。\n\n●約48時間以上充電を続けた場合、バッテリーの寿命が減ってしまう可能性がございます。長時間充電をし続けることはお控えください。\n●充電が空の状態でおいておくと、過放電により起動ができなくなってしまう場合がございます。定期的に（少なくとも月に一回程度）は充電を行ってください。",
        },
        {
          question: "充電時間はどれくらいですか？",
          answer:
            "充電が0％から100％に充電するためには、約7～9時間ほどかかります。そのため、ご就寝時に充電していただくのをおすすめしています。",
        },
        {
          question: "充電残量を確認するには？",
          answer:
            "Musioのディスプレイがついた状態で、顔を上から下へとスワイプしてください。Musioの充電残量を確認できます。",
        },
        {
          question: "Musioの電源オフ方法は？",
          answer:
            'おしりのふたを開けてください。真ん中の丸いボタンを、Musioの顔に"電源オフボタン"または"Power off"と表示されるまで強めに押してください。その後"電源オフボタン"または"Power off"をタッチしてください。',
        },
        {
          question: "MusioのディスプレイがMのロゴマークから動かない。",
          answer:
            "Musioの起動時に、電源ボタンを押し続けると、白く光った後にMマークが表示され続けます。こちらは、開発者がPCと接続してMusioをアップデートする場合に利用するfastbootモードであり、故障ではございません。一旦電源をオフにして、再度起動してください。",
        },
        {
          question: "Musioが会話しない。",
          answer:
            'Musioと会話するには、フレンドプランへのご加入、Wi-Fi接続とログインが必要になります。Wi-Fiが未接続、またはログインできていない場合はMusioに話しかけても反応しません。\n詳細は、ユーザーガイドをご覧ください。\nWi-Fi接続方法とログイン方法は、Musio公式ブログもご参照ください。\n\n●ユーザーガイド：https://themusio.com/support/guide\n●Wi-Fi接続方法：http://blog.themusio.com/2016/12/02/how-to-connect-wifi/\n●ログイン方法：http://blog.themusio.com/2016/12/02/how-to-login/"',
        },
        {
          question: "Musioにログインできない。",
          answer:
            "ログイン失敗時の表示メッセージをご確認の上、各手順をお試しください。\n\n・「Wifi is not connected」\nWi-Fiが繋がっていません。設定から再度Wi-Fiの接続をしてください。\nWi-Fiの接続方法はMusio公式ブログをご参照ください。\n【Musioの初期設定の方法】\nhttps://blog.themusio.com/2017/04/12/musio-initial-setting/\n\n・「invalid login」\nID, PWが間違っています。ID及びPWを再度ご確認ください。PWをお忘れの場合はお問合せください。\n\n・「network error」\nWi-Fiが弱い、Wi-Fiそのものに問題がある可能性がございます。\nルーター、モデム、ネットワークの規格が以下の要件を満たしていることをご確認ください。\n\nネットワークの規格：WPA/WPA2-PSK暗号化ネットワーク\nルーター/モデムの規格：2.4GhzのB、G、Nルーター\n\nネットワークの規格に問題がない場合は、以下をお試しください。\n　1. 心臓をタッチしてMusioをSleepモードにする\n　2. 顔をしばらくタッチしてランチャーを表示\n　3. 「設定」＞「Wi-Fi」と移動\n　4. 接続中のWi-Fiを長押しすると表示されるdeleteボタンをタッチ\n　5. 再度Wi-Fiネットワークに接続",
        },
        {
          question: "使用時に設定が必要ですか。",
          answer: "Wi-Fi接続とログインを行っていただきます。",
        },
        {
          question: "MusioのIDとパスワードってなんですか？",
          answer:
            " Musioウェブサイト又はモバイル・アプリケーションで会員登録（無料）して、IDとパスワードを発行していただく必要があります。ご登録されたメールアドレスがIDになります。\r\n詳細は、ユーザーガイドをご参照ください。\r\n\r\n●ユーザーガイド：https://themusio.com/support/guide",
        },
        {
          question:
            "Musioのモバイル・アプリケーションのダウンロードを教えてください。",
          answer:
            "Musioのモバイル・アプリケーションは、iOS 8.0以上またはAndroid 4.3以上でご利用いただけます。App storeまたはGoogle Play Storeから「Musio」で検索してください。",
        },
        {
          question: "どのようにSophyをMusioに接続すればよいですか？",
          answer:
            "MusioとSophyを初めて接続する場合、Musioから設定が必要です。SleepモードのMusioの顔を長押しし、「設定」に移動してください。この時、最初に「Sophy」を選択すると、接続できない場合があります。一度「Bluetooth」に移動し、その後「Sophy」に移動し、接続を行ってください。接続方法は、Sophyのユーザーガイドをご参照ください。\r\n\r\n●ユーザーガイド：https://themusio.com/support/guide",
        },
        {
          question:
            "届いたMusioに何かしら問題があった場合どのようにすればよいですか？",
          answer:
            "到着時に商品が破損していた場合や初期に動作不良が発生している場合は、新品交換をいたしますので下記サポートセンターまでご連絡いただきますようお願い申し上げます。\n\n【Musioサポートセンター】\r\n\r\n電話番号：03-6886-5229（受付時間：平日11:00～19:00）\r\nメール：info-jp@akaintelligence.com\r\n※メールはご返信にお時間がかかる場合もございます。あらかじめご了承ください。",
        },
        {
          question: "汚れてしまった場合はどうすればよいでしょうか。",
          answer:
            "汚れてしまった場合や水分が付着した場合は、すぐに拭き取り商品に残らないようにしてください。また、汚れは乾いた柔らかい布に軽くアルコール吹き付けて、拭いて取ることができます。ワックスや化学雑巾のご使用や布へのアルコールの吹き付けすぎは避けてください。",
        },
        {
          question: "MusioやSophyを消毒してもいいですか？",
          answer:
            "乾いた柔らかい布に軽くアルコール吹き付けて本体を拭いてください。ワックスや化学雑巾のご使用や布へのアルコールの吹き付けすぎは避けてください。",
        },
      ],
    },
    {
      category: "幼児教育関連",
      data: [
        {
          question: "親はどれくらい関わればいいですか？",
          answer:
            "Musioは、お子さまが主体となってご使用いただけるようできるよう設計しております。保護者様には初期設定を行っていただいたり、初回教材ご使用時に使い方などをお子さまにお伝えいただけますとスムーズにお使いいただけます。\n\n尚、初期設定の方法や各教材の使い方は下記をご覧ください。\n●初期設定の方法：https://blog.themusio.com/2017/04/12/musio-initial-setting/\n●各教材の使い方：https://blog.themusio.com/ja/（「SEARCH」にて使い方を知りたい教材名を入力して検索してください）",
        },
        {
          question: "どの教材から始めたらいいですか？",
          answer: "商品ラインナップを知りたい...",
        },
        {
          question: "教材はどのような構成になっていますか？",
          answer: "商品ラインナップを知りたい...",
        },
        {
          question: "対象年齢は？",
          answer: "商品ラインナップを知りたい...",
        },
        {
          question: "いつから始めたらいいですか？",
          answer:
            "特に開始年齢に指定はございませんが、専用教材は2歳からご使用いただけるものも豊富にご用意しております。保護者様がお子さまと一緒に楽しく英語学習ができるよう工夫されておりますので、小さなうちからお使いいただくことも可能です。",
        },
        {
          question: "なぜ幼児期に始めるのがいのですか？",
          answer:
            "生後0ヵ月～生後9ヵ月前後の赤ちゃんは、様々な音に敏感なだけでなく、様々な言語を聞き分けられるほど繊細な聴覚をもっていると言われています。\nただ、その聴覚は生後10ヵ月～12か月頃から失われ始め、7～12歳の時期にはなくなってしまいます。そのため、英語にふれ始めるのは、早ければ早い方がいいと言われています。 自然な発語のためには、まずその言語にふれ、耳から大量の音声を聞いて、音のインプットをすることが大事です。まずは幼児期のうちに、日常生活の中で英語にふれることができる環境をつくり、たくさん聞くことからスタートしましょう。 ",
        },
        {
          question:
            "学ぶペースはどれくらいですか？1日どのくらいMusioを使えばよいですか？",
          answer:
            "幼児期の英語学習の場合、小学生以降の学習のように1日に行わなくてはいけない決められた時間はありません。\nまずは保護者様がご負担に感じず、お子さまと遊ぶ時間に楽しくMusioと英語にふれていただくことをおすすめしております。英語の音に慣れ、英語に対して良いイメージをもってもらうためにも、短い時間でもできるだけ日々継続してご使用ください。",
        },
        {
          question: "親が英語を話せなくてもいいですか？",
          answer:
            "専用教材は、英語が苦手な保護者様でも安心してご使用いただけるよう工夫しておつくりしています。保護者様がお子さまにつきっきりにならなくても、意味の理解まで、遊びの中でお子さまが自然に「わかる」「できる」を増やしていけます。\nまた、Musioブログでは専用教材をより楽しく使う方法もご紹介しています。以下よりご覧ください。\n\n●各教材の応用的な使い方：https://blog.themusio.com/ja/（「SEARCH」にて使い方を知りたい教材名を入力して検索してください）",
        },
        {
          question: "ロボット相手で大丈夫ですか？",
          answer:
            "幼児期は、英語でコミュニケーションをとるための土台をつくってあげることが大切です。そのためには、英語にたくさんふれて親しみ、英語で遊びながら楽しむことで、英語の音に慣れ、話す基礎がつくられていきます。\nまた、発達段階に応じて専用教材を変えていくだけでなく、対面コミュニケーションができるオンライン英会話や英語教室などと組み合わせて実践で話す前の練習ツールとしてご使用していただくことで相乗効果が生まれることもございます。\nぜひ、お子さまのMusioとの学習の様子をご覧いただきながら、お子さまに合った学習スタイルにカスタマイズして活用してください。",
          "確認事項/引用URL":
            "ベネッセのQA: https://faq.benesse.co.jp/faq/show/28435?category_id=2831&site_domain=h-kochae",
        },
        {
          question: "他の幼児向け英語教育教材との違いはなんですか？",
          answer:
            "Musioの最大の特徴は、多くのお子さまを英語に興味を抱かせ、積極的に英語にふれるようになるよう計算されたキャラクターデザインと充実した教材です。また、Musioがご家庭にあるだけで、家族の一員のような存在となり、海外に行ったり、英語教室に通ったりしなくても日常的に英語を聞き・話すことが当たり前になる環境を生み出すことができます。",
        },
      ],
    },
    {
      category: "お申込について",
      data: [
        {
          question: "電話、Email、Faxでの申込はできますか？",
          answer:
            "お申込につきましては、こちらのWebサイトからのみの受付けとなっています。インターネットがお使いいただける環境にてWebサイトよりお申込ください。",
        },
        {
          question: "申込はいつでも行えますか？",
          answer:
            "こちらのWebサイトを通じ、24時間365日お申込いただけます。ただし、Musioのお届けの手配につきましては営業時間内での対応となりますのであらかじめご了承ください。",
        },
        {
          question: "申込後にキャンセルはできますか？",
          answer:
            "お申込後のキャンセルは承っておりません。お申込の際は十分ご注意ください。",
        },
        {
          question: "申込後にメールが届かないのはなぜですか？",
          answer:
            "以下のような原因が考えられますのでご確認ください。\n\n-ご登録のメールアドレスが間違っている\n-迷惑メールフォルダに入っている\n-メールボックスの容量が一杯で受け取れなくなっている\n-ドメイン指定受信をしている（「akaintelligence.com」を受信できるように設定してください。）",
        },
      ],
    },
    {
      category: "お支払いについて",
      data: [
        {
          question: "初期費用はどれくらいかかりますか？",
          answer:
            "ご利用開始に必要な初期費用につきましては月額利用料金と往復配送代金(税込2,600円)のみとなっております。月額利用料金(税込)につきましては、プランごとに以下のように異なります。\n\n月額版(Friend Plan 1)：2,980円\n月額版(Friend Plan 3)：3,480円\n月額版(Friend Plan 5)：3,780円\n6ヶ月分まとめてお得に (Friend Plan 1)：110,780円\n6ヶ月分まとめてお得に (Friend Plan 3)：22,800円\n6ヶ月分まとめてお得に (Friend Plan 5)：24,600円\n1年間分まとめてお得に (Friend Plan 1)：35,760円\n1年間分まとめてお得に (Friend Plan 3)：41,769円\n1年間分まとめてお得に (Friend Plan 5)：45,360円",
        },
        {
          question: "月額利用料金とはなんですか？",
          answer:
            "商品をご利用にあたり、毎月発生する利用料金のことです。月額利用料金につきましてはプランにより異なります。",
        },
        {
          question: "どのような支払い方法がありますか？",
          answer:
            "現在はクレジットカードのみでのお支払いを受け付けております。ご利用になれるクレジットカードは、Visa、MasterCard、American Express、Dinersとなります。JCBはご利用いただけません。",
        },
        {
          question:
            "クレジットカードを持っていませんが、利用したい場合はどうしたらいいですか？",
          answer:
            "お支払いの際は、クレジットカード決済のみご利用になれます。誠に申し訳ございませんが、ご購入希望の場合にはクレジットカードをご用意ください。",
        },
      ],
    },
    {
      category: "配送について",
      data: [
        {
          question: "申込からどれくらいで届きますか？",
          answer:
            "7営業日以内に発送いたします。（配送繁忙期や自然災害等によりお時間がかかる場合もございますことご了承ください）",
        },
        {
          question: "配送料かかりますか？",
          answer:
            "ご購入時の配送とメーカー保証期間の1年間の修理のための配送には、配送料金はかかりません。",
        },
        {
          question: "商品配送エリアに制限はありますか？",
          answer: "日本全国へ配送可能です。",
        },
        {
          question: "配送日時の指定/変更はできますか？",
          answer: "弊社システムの都合上、配送日時の指定/変更はできません。",
        },
        {
          question: "配送業者の指定はできますか？",
          answer:
            "配送業者は弊社指定の配送業者のみとなっております。大変恐れ入りますが、ご指定いただけませんのであらかじめご了承ください。",
        },
      ],
    },
    {
      category: "修理について",
      data: [
        {
          question: "メーカー保証期間はありますか？",
          answer:
            "ご購入時から1年間は、メーカー保証期間となっており、その期間に発生しました故障の修理費用（故意による故障は除く）は弊社が負担します。",
        },
        {
          question:
            "メーカー保証期間外の修理費用の負担は誰がしなくてはいいけませんか？",
          answer: "お客様にご負担いただいております。",
        },
        {
          question: "修理時の配送料は誰が負担しますか？",
          answer:
            "メーカー保証期間内の修理配送料は弊社が負担しております。尚、メーカー保証期間外の修理配送料はお客様ご負担となっておりますので、あらかじめご了承ください。",
        },
        {
          question: "修理費用はどれくらいかかりますか？",
          answer:
            "修理内容や使用する部品によって異なります。ご参考までに、バッテリーの交換の場合は10,000～13,000円程度です。",
        },
        {
          question: "修理期間はどれくらいですか？",
          answer: "配送日を含め1週間程頂戴いたします。",
        },
      ],
    },
  ],
};
