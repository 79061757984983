import React from 'react'
import {Row, Col, Grid} from 'react-bootstrap'
import Footer from '../../footer/Footer'
import ChargeContents from './ChargeContents'
import FreeContents from './FreeContents'

import './css/ContentsMain.css'

//import ContentsList from '../public_components/ContentsList'
//import ContentCategory from '../public_components/ContentCategory'
//import defaultContents from '../home/defaultContents'
//import { connect } from 'react-redux'
//import { get_contents } from '../../actions/contents'
//import Loading from '../Loading';

class ContentsMain extends React.Component {
  constructor(props) {
    super(props);
    let type = this.props.location.search.split('=')[1];

    if (type === undefined) type = 1;
    else type = parseInt(type);

    this.state = {
      selector: type
    };

    this.onSelectorClick = this.onSelectorClick.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onSelectorClick(i) {
    this.setState({
      selector: i
    })
  }

  render() {
    return (
      <div>
        <Grid>
          <Row>
            <Col xs={6} className="text-center selector"
                 onClick={() => this.onSelectorClick(0)}
                 style={this.state.selector === 0 ? {color: 'black'} : {}}>
              Free
            </Col>
            <Col xs={6} className="text-center selector"
                 onClick={() => this.onSelectorClick(1)}
                 style={this.state.selector === 1 ? {color: 'black'} : {}}>
              Paid
            </Col>
          </Row>
        </Grid>
        {this.state.selector === 0 && <FreeContents/>}
        {this.state.selector === 1 && <ChargeContents/>}
        <Footer/>
      </div>
    );
  }
}

export default ContentsMain;
