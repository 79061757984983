import React, {Component} from 'react'
import './css/Home.css'

import MainCarousel from './MainCarousel'
import HomeGoods from './HomeGoods'
import HomeContents from './HomeContents'
import FreeContents from './FreeContents'
//import OnlineShowRoom from './OnlineShowRoom'
import MusioAndSophy from './MusioAndSophy'
import Edge from './Edge'
import RealReview from './RealReview'
import Footer from '../../footer/Footer'

import MovingImage from './MovingImage'

class Home extends Component {
  render() {
    return (
      <div>
        <MainCarousel/>
        <MusioAndSophy/>
        {/* <Edge/> */}
        {/* <MovingImage/> */}

        <HomeContents/>
        <FreeContents/>
        <HomeGoods/>
        <RealReview/>
        <Footer marginTop={0}/>
      </div>
    );
  }
}

export default Home;
