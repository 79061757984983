const defaultContents = [
  {
    id: '0',
    thumbnail: 'https://s3-ap-northeast-1.amazonaws.com/musio-web/store/product/musio.jpg',
    name: 'えいごで日本むかしばなし',
    price: '7,000',
    tags: ['5-12歳'],
    manufacturer: 'Gakken'
  },
  {
    id: '1',
    thumbnail: 'https://s3-ap-northeast-1.amazonaws.com/musio-web/store/product/sophy.jpg',
    name: 'Musio English Basic_Set',
    price: '6,000',
    tags: ['5-12歳'],
    manufacturer: 'Gakken'
  },
  {
    id: '2',
    thumbnail: 'https://s3-ap-northeast-1.amazonaws.com/musio-web/store/product/kitchenboardbook.jpg',
    name: 'Musio X set',
    price: '128,000',
    tags: ['5-12歳'],
    manufacturer: 'Gakken'
  },
  {
    id: '3',
    thumbnail: 'https://s3-ap-northeast-1.amazonaws.com/musio-web/store/product/tshirt.png',
    name: 'Flash card (Alphabet & Animal)',
    price: '3,000',
    tags: ['5-12歳'],
    manufacturer: 'Gakken'
  },
  {
    id: '4',
    thumbnail: 'https://s3-ap-northeast-1.amazonaws.com/musio-web/store/product/musioenglish_cover.jpg',
    name: 'Flash card (Alphabet & Animal)',
    price: '3,000',
    tags: ['5-12歳'],
    manufacturer: 'Gakken'
  },
  {
    id: '5',
    thumbnail: 'https://s3-ap-northeast-1.amazonaws.com/musio-web/store/product/flashcard_Instrument.jpg',
    name: 'Flash card (Number)',
    price: '3,000',
    tags: ['5-12歳'],
    manufacturer: 'Gakken'
  }
];

export default defaultContents
