import React, {Component} from 'react'
import './css/DetailContent.css'
import {Grid, Row, Col} from 'react-bootstrap'
import PropTypes from 'prop-types'

import campaign from './img/musio_campaign.png'



function isButton() {
  const pathName = window.location.pathname;
  const ADD_BUTTON = [
    '/product/c7843561-b68b-5d73-b1f5-cf042c49852d',
    '/product/f000a314-b45c-5d57-85a2-530675357e09',
    '/product/e6c6afd0-9f51-59a5-921a-bacba92681d6',
    '/product/fcc6228d-47f3-59e7-8658-200005ce3bfc',
    '/product/a45ba9a3-a81c-5788-9521-3951acb1d898',
    '/product/2faa8825-764e-5368-90ae-bc52f7ceb3ee',
    '/product/dd5fa5d2-0230-5459-bacb-d3307560730c',
  ]
  if (ADD_BUTTON.includes(pathName)) {
    return true;
  }
  else {
    return false;
  }
}

class DetailContent extends Component {
  state = {
    selectedCategory: 1,
  };

  onCategoryClick(i) {
    this.setState({
      ...this.state,
      selectedCategory: i,
    });
  }
  
  render() {
    let content;
    if (this.state.selectedCategory === 1 ) {
      content = (
        <Grid className="detail">
          <Row>
            <Col xs={12} xsHidden={true} smHidden={true}>
              {
                this.props.description_image && this.props.description_image.large.map((image, i) => {
                  return (
                    <div className="description-img" key={"description_img" + i}>
                      <img src={image} alt="description"/>
                    </div>
                  )
                })
              }
            </Col>
  
            <Col xs={12} xsHidden={true} mdHidden={true} lgHidden={true}>
              {
                this.props.description_image && this.props.description_image.medium.map((image, i) => {
                  return (
                    <div className="description-img" key={"description_img" + i}>
                      <img src={image} alt="description"/>
                    </div>
                  )
                })
              }
            </Col>
  
            <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
              {
                this.props.description_image && this.props.description_image.small.map((image, i) => {
                  return (
                    <div className="description-img" key={"description_img" + i}>
                      <img src={image} alt="description"/>
                    </div>
                  )
                })
              }
            </Col>
  
            {this.props.description}
          </Row>
        </Grid>
      );
    }

    if (this.state.selectedCategory === 2 ) {
      content = (
        <Grid className="detail">
          <Row>
            <Col xs={12} xsHidden={true} smHidden={true}>
              {
                this.props.description_image && this.props.description_image.large_tab2.map((image, i) => {
                  return (
                    <div className="description-img" key={"description_img" + i}>
                      <img src={image} alt="description"/>
                    </div>
                  )
                })
              }
            </Col>
  
            <Col xs={12} xsHidden={true} mdHidden={true} lgHidden={true}>
              {
                this.props.description_image && this.props.description_image.medium_tab2.map((image, i) => {
                  return (
                    <div className="description-img" key={"description_img" + i}>
                      <img src={image} alt="description"/>
                    </div>
                  )
                })
              }
            </Col>
  
            <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
              {
                this.props.description_image && this.props.description_image.small_tab2.map((image, i) => {
                  return (
                    <div className="description-img" key={"description_img" + i}>
                      <img src={image} alt="description"/>
                    </div>
                  )
                })
              }
            </Col>
            {this.props.description}
          </Row>
        </Grid>    
      );
    }

    return (
      <div>
        {/* {window.location.pathname.includes('b47daab6-4fc1-565b-8818-eac897958f6d') ? 
        <img className='campaign' src = {campaign}/> : ""} */}

        <div className = {
          "product" + 
          (this.props.description_image.large_tab2 == undefined ? "-none" : "-tab")}
        >
          <Grid>
            <Row>
              <div className="category-wrapper">
                <Col 
                  xs={6}
                  className={
                    "category" +
                    (this.state.selectedCategory === 1 ? "-select" : "none")
                  }
                  onClick= {() => this.onCategoryClick(1)}
                >
                英語学習に
                </Col>
                <Col
                  xs={6}
                  className={
                    "category" +
                    (this.state.selectedCategory === 2 ? "-select" : "none")
                  }
                  onClick={() => this.onCategoryClick(2)}
                >
                  脳・心のケアに
                </Col>
              </div>
            </Row>
          </Grid>
        </div>
        <Row>{content}</Row>
        <Row>
          {isButton() == true ? <button 
          className='check'
          onClick={() => {window.location.href='https://store.themusio.com/product/b47daab6-4fc1-565b-8818-eac897958f6d'}}
          >
            Musioについて</button> : undefined}
        </Row>
      </div>
    )
  }
}

DetailContent.propTypes = {
  description_image: PropTypes.shape(
    {
      large: PropTypes.array,
      medium: PropTypes.array,
      small: PropTypes.array
    }
  ),
  description: PropTypes.string
};

export default DetailContent
