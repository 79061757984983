import React, {Component} from 'react'
import {Row, Col, Grid} from 'react-bootstrap'
import CodingEduBanner from './img/banner_coding.png'
//import MusioSetBanner from './img/banner_set.png'
import {Link} from 'react-router-dom'
import './css/MusioAndSophy.css'

import {trackEvent} from "../../utils/tracking"

class MusioAndSophy extends Component {
  render() {
    const nhkBanner = 'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/images/home/store-banner.jpg'
    return (
      <Grid>
        <Row className="mf_musio_sophy">
          <Link to={"/product/b47daab6-4fc1-565b-8818-eac897958f6d"} onClick={() => {trackEvent("HOME", "Click from Musio&Sophy: b47daab6-4fc1-565b-8818-eac897958f6d", "")}}>
            <Col id="mf_musio_wrapper" className="mf_musio" xs={6}>
              <div className="mf_musio_inside">
                <div className="mf_content">
                  <div className="mf_title">Musio S</div>
                  <div className="mf_price">
                    {/* <span class = "sale">
                      <s>¥57,310</s>
                    </span>
                    <span>¥45,760（税込）</span> */}
                    ¥57,310（税込）
                  </div>
                </div>
              </div>
            </Col>
          </Link>

          <Link to={"/product/d80cf06b-dfae-5280-814e-23d7e2971a98"} onClick={() => {trackEvent("HOME", "Click from Musio&Sophy: d80cf06b-dfae-5280-814e-23d7e2971a98", "")}}>
            <Col id="mf_sophy_wrapper" className="mf_musio" xs={6}>
              <div className="mf_sophy_inside">
                <div className="mf_content">
                  <div className="mf_title">Sophy</div>
                  <div className="mf_price">
                    ¥21,560（税込）
                  </div>
                </div>
              </div>
            </Col>
          </Link>
        </Row>
        <Row>
          <Link to={"/product/747ada1d-f83c-592c-858e-3da1a4c42b39"} onClick={() => {trackEvent("HOME", "Click from Musio&Sophy: 747ada1d-f83c-592c-858e-3da1a4c42b39", "")}} style={{textDecoration: 'none'}}>
              <Col id="mf_musio_set_wrapper" className="mf_musio fix_width" style={{float:"none", margin: '0 auto' }} xs={6}>
                <div className="mf_musio_set_inside">
                  <div className="mf_content">
                    <div className="mf_title">Musio S + Sophy</div>
                    <div className="mf_price">
                      {/* <span class = "sale">
                        <s>¥73,678</s>
                      </span>
                      <span>¥51,579（税込）</span> */}
                      ¥73,678（税込）
                    </div>
                  </div>
                </div>
              </Col>
            </Link>
        </Row>
        {/*
        <Row>
          <Col xs={12} style={{marginTop: 60}}>
            <Link to={"/product/9ce94cfe-b66b-5e7b-92df-bd154e02186a"}>
              <img src={MusioSetBanner} alt="codingedu"/>
            </Link>
          </Col>
        </Row>
        */
        }

        <Row>
          <Col xs={12} style={{marginTop: 60}}>
            <Link to={"/contents"}>
              <img src={nhkBanner} alt="nhkBanner"/>
            </Link>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={12} style={{marginTop: 60}}>
            <Link to={"/product/fe4b7a4a-df33-5831-a621-f49dfa814f8e"} onClick={() => {trackEvent("HOME", "Click from Musio&Sophy: fe4b7a4a-df33-5831-a621-f49dfa814f8e", "")}}>
              <img src={CodingEduBanner} alt="codingedu"/>
            </Link>
          </Col>
        </Row> */}
      </Grid>
    );
  }
}

export default MusioAndSophy;
