import React from 'react';
import {injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';
import './css/PurchaseMain.css'
import axios from 'axios'
import {STORE_API_URL, STORE_REST_CARD} from '../../utils/url'
import {Link} from 'react-router-dom';

import visa from './img/card1.png';
import mastercard from './img/card2.png';
import amex from './img/card3.png';
import discover from './img/card4.png';
import diners from './img/card5.png';
import jcb from './img/card6.png';
import {LOG} from "../../utils/Log";

import {trackEvent} from "../../utils/tracking"

const createOptions = (fontSize) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#333',
        letterSpacing: '0.025em',
        fontFamily: 'Noto Sans JP',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      },
      complete: {
        color: "#00ba4a"
      }
    }
  };
};

class StripeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      brand: "",
      error_msg: "",
      loading: false,

      card_info: null,
    }
  }

  componentWillMount() {
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    axios.get(STORE_API_URL + STORE_REST_CARD, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        LOG(response);
        this.setState({
          card_info: response.data.data,
          brand: response.data.data["card_brand"],
          loading: false,
        })
        trackEvent("PURCHASE", "Success: Fetch card information", "")
      })
      .catch((error) => {
        LOG(error);
        this.setState({
          loading: false,
        })
        trackEvent("PURCHASE", "Failed: Fetch card information", "Error: " + error)
      })
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({
      loading: true,
    });
    if (this.state.card_info != null) {
      this.props.onConfirmCard();
      this.setState({
        loading: false,
      });
      trackEvent("PURCHASE", "Success: Select card", "")
      return;
    }
    if (this.state.name === "") {
      this.setState({
        error_msg: "Please, Input your name",
        loading: false,
      });
      trackEvent("PURCHASE", "Failed: Select card", "Name is null")
      return false;
    }
    this.props.stripe.createToken({name: this.state.name}).then((payload) => {
      if (!payload.error) {
        LOG('Received Stripe token:', payload.token);
        axios.post(STORE_API_URL + STORE_REST_CARD, {
          token: payload.token.id,
        }, {
          headers: {"Authorization": "Bearer " + this.props.jwt}
        })
          .then((response) => {
            this.props.onConfirmCard();
            this.setState({
              card_info: response.data.data,
              brand: response.data.data["card_brand"],
              error_msg: "",
              loading: false,
            })
            trackEvent("PURCHASE", "Success: Receive Stripe token", "")
          })
          .catch((error) => {
            LOG(error);
            this.setState({
              error_msg: "",
              loading: false,
            })
            trackEvent("PURCHASE", "Failed: Receive Stripe token", "Error: " + error)
          })
      } else {
        const error = payload.error.message;
        this.setState({
          error_msg: error,
          loading: false,
        });
        trackEvent(
          "PURCHASE",
          "Failed: Receive Stripe token",
          "Error: " + error
        );
      }
    })
  };
  cardChange = value => {
    this.setState({
      brand: value.brand
    });
  };

  render() {
    return (
      <form className="card_form" onSubmit={this.handleSubmit}>
        {this.state.loading &&
        <div className="loading_container">
          <span className="vertical_space"/>
          <div className="loader"/>
        </div>
        }
        <div className={`stripe_card_div ${this.props.isCardConfirm && "active"}`}>
          <div>
            <span className="card_info_title">カード所有者名</span><br/>
            {this.state.card_info == null ?
              <input type="text" className="card_name"
                     value={this.state.name}
                     onChange={(e) => this.setState({name: e.target.value})}
                     placeholder="Name">
              </input> :
              <span className="card_text">{this.state.card_info["card_name"]}</span>
            }
          </div>
          <div>
            <span className="card_info_title">カード番号</span><br/>
            {this.state.card_info == null ?
              <CardNumberElement
                className="card_info_content"
                onChange={this.cardChange}
                {...createOptions("18px")}
              /> :
              <span className="card_text">**** - **** - **** - {this.state.card_info["card_number"]}</span>
            }
          </div>
          <div>
            <span className="card_info_title">カード有効期限 (ex: MM/YY)</span><br/>
            {this.state.card_info == null ?
              <CardExpiryElement
                className="card_info_content"
                {...createOptions("18px")}
              /> :
              <span className="card_text">{this.state.card_info["card_exp"]}</span>
            }
          </div>
          <img src={visa} className={`card_brand ${this.state.brand.toLowerCase() === "visa" && "active"}`} alt={""}/>
          <img src={mastercard} className={`card_brand ${this.state.brand.toLowerCase() === "mastercard" && "active"}`}
               alt={""}/>
          <img src={amex} className={`card_brand ${this.state.brand.toLowerCase() === "amex" && "active"}`} alt={""}/>
          <img src={discover} className={`card_brand ${this.state.brand.toLowerCase() === "discover" && "active"}`}
               alt={""}/>
          <img src={diners} className={`card_brand ${this.state.brand.toLowerCase() === "diners" && "active"}`}
               alt={""}/>
          <img src={jcb} className={`card_brand ${this.state.brand.toLowerCase() === "jcb" && "active"}`} alt={""}/>
          <div>
            <span className="card_info_title">CVC</span><br/>
            {this.state.card_info == null ?
              <CardCVCElement className="card_info_content"
                              {...createOptions("18px")}
              /> :
              <span className="card_text">***</span>
            }
          </div>
          <span className="error_msg">{this.state.error_msg}</span>
        </div>

        {this.state.card_info != null &&
        <Link to="/mypageCard" className="edit_card_btn">
          <button>編集</button>
        </Link>
        }

        <div className="tip">
          VISA/MASTER/Dinersカードのセキュリティーコードは、裏面署名欄の末尾３桁の数字です。<br/>
          American Expressカードのセキュリティーコードは表面の4桁の数字です。
        </div>
        {this.props.isCardConfirm ?
          <button className="complete_btn" type="button" disabled>確認完了</button> :
          <button>確認</button>
        }
      </form>
    );
  }
}

export default injectStripe(StripeForm);
