let initialState = {
  free_contents: {
    0: {
      data: []
    },
    1: {
      data: []
    },
    2: {
      data: []
    },
    3: {
      data: []
    },
    4: {
      data: []
    }
  }
};

const FreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FREE_PENDING':
      return {
        ...state,
        pending: true,
        error: false,
      };
    case 'GET_FREE_SUCCESS':
      return {
        ...state,
        pending: false,
        error: false,
        free_contents:
          {
            ...state.free_contents,
            [action.tag_index]: {
              data: action.data,
              next_token: action.next_token
            }
          },
      };
    case 'APPEND_FREE_SUCCESS':
      return {
        ...state,
        pending: false,
        error: false,
        free_contents:
          {
            ...state.free_contents,
            [action.tag_index]: {
              data: [
                ...state.free_contents[action.tag_index].data,
                ...action.data
              ],
              next_token: action.next_token
            }
          },
      };
    case 'GET_FREE_ERROR':
      return {
        ...state,
        pending: false,
        error: true
      };
    default:
      return state
  }
};

export default FreeReducer
