import React, {Component} from 'react'
import ReactLoading from 'react-loading'

class Loading extends Component {
  render() {
    return (
      <div style={{
        position: 'fixed', backgroundColor: '#FFFFFFAA',
        width: '100%', height: '100%', top: '0', zIndex: '1000'
      }}>
        <ReactLoading type={'spin'}
                      style={{
                        position: 'absolute', left: '45%', top: '45%',
                        height: '10%', width: '10%', fill: '#333333'
                      }}/>
      </div>
    );
  }
}

export default Loading;
