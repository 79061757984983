import React from "react";
import { Row, Col, Grid } from "react-bootstrap";
import "./css/Mypage.css";
import MypageSidebar from "./MypageSidebar";
import {StripeProvider, Elements} from 'react-stripe-elements';
import {connect} from 'react-redux';
import HubspotForm from "./Hubspot";

class MyPageQnA extends React.Component {
    constructor(props){
        super(props);
    }


      componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleClickBlackBox = () => {
    document.querySelector("#sidebar-left").style.left = "";
    document.querySelector("#black_box").style.backgroundColor = "";
    document.querySelector("#black_box").style.zIndex = "";
    document.querySelector(".side-toggle").dataset.clicked = "false";
  };
  
  render(){
    return(
      <div id="mypage_div">
      <Grid>
        <Row>
          <MypageSidebar jwt={this.props.jwt}/>
          <Col xs={12} sm={9} lg={10} className="purchase_container" id="mypage_content_div">
            <div style={{fontSize:20}}>解約される方もこちらのフォームから<br/>
ご連絡ください。
</div>
            <HubspotForm/>

            </Col>
          </Row>
          </Grid>
          </div>
      
    )
  }
}

export default (MyPageQnA)