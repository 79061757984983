import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  AUTH_LOGIN,
  AUTH_SIGN_UP,
  THE_MUSIO_URL,
  USERMODEL_URL
} from "../../utils/url";
import logo from "./img/musio_logo_text.png";

class NavbarItem extends Component {
  render() {
    const { to, match } = this.props;
    let isActive = "";

    if (!match.params.active && to === "/") {
      // index URL
      isActive = "active";
    } else if (match.params.active && "/" + match.params.active === to) {
      isActive = "active";
    }

    return (
      <li className={`nav-item ${isActive}`}>
        <NavLink to={to} className={this.props.setClass}>
          {this.props.children}
        </NavLink>
      </li>
    );
  }
}

class MypageNavbar extends Component {
  handleClickSidebar = (event) => {
    if (event.currentTarget.dataset.clicked === "false") {
      document.querySelector("#sidebar-left").style.left = " 0";
      document.querySelector("#black_box").style.backgroundColor =
        "rgba(0,0,0,0.5)";
      document.querySelector("#black_box").style.zIndex = "2";
      event.currentTarget.dataset.clicked = "true";
    } else {
      document.querySelector("#sidebar-left").style.left = "";
      document.querySelector("#black_box").style.backgroundColor = "";
      document.querySelector("#black_box").style.zIndex = "";
      event.currentTarget.dataset.clicked = "false";
    }
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      window.location =
        USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
    }
  }

  render() {
    return (
      <div>
        <div className="hidden-xs Navbar_account">
          <div className="container">
            <ul className="nav navbar-nav navbar-right">
              <li className="nav-item">
                <a href={THE_MUSIO_URL + "/logout"}>LOGOUT</a>
              </li>
              <li className="nav-item">
                <a href={THE_MUSIO_URL + "/mypage/info"}>MYPAGE</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container Navbar_mypage">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar-collapse"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <button
              type="button"
              data-clicked="false"
              className="navbar-toggle navbar-left side-toggle glyphicon glyphicon-chevron-down pull-left"
              onClick={this.handleClickSidebar}
            ></button>
            <a href={THE_MUSIO_URL}>
              <img
                alt="goto musio/home"
                src={logo}
                className="brand-img img-responsive"
              />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="navbar-collapse">
            <ul className="nav navbar-nav navbar-right">
              <li className="nav-item">
                <a href={THE_MUSIO_URL}>HOME</a>
              </li>
              <li className="nav-item">
                <a href={THE_MUSIO_URL + "/musio/story"}>STORY</a>
              </li>
              <li className="nav-item">
                <a href={THE_MUSIO_URL + "/musio/edu"}>EDUCATION</a>
              </li>
              <li className="nav-item">
                <a href={THE_MUSIO_URL + "/musio/tech"}>TECH</a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="dropdown-toggle toggle-2"
                  data-toggle="dropdown"
                  href="#"
                  data-target="#"
                  role="button"
                  aria-expanded="false"
                  target="_blank"
                >
                  BLOG
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-left toggle-menu-2"
                  role="menu"
                >
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://blog.themusio.com/category/blog-jp/musio-ja/"
                    >
                      Musio
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://blog.themusio.com/category/a-i/"
                    >
                      A.I
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://blog.themusio.com/category/blog-jp/education-jp/"
                    >
                      EDUCATION
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://blog.themusio.com/category/press/"
                    >
                      PRESS
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://blog.themusio.com/category/video/"
                    >
                      VIDEO
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href={THE_MUSIO_URL + "/support"}>SUPPORT</a>
              </li>
              <li className="nav-item">
                <a href={THE_MUSIO_URL + "/aboutus"}>ABOUT US</a>
              </li>
              <NavbarItem
                to="/"
                match={this.props.match}
                setClass="go_store_btn"
              >
                STORE
              </NavbarItem>
              <li className="nav-item Nav_mobile_account">
                {/*<a href="{% url 'login'%}?next={{request.path}}" className="visible-xs text-center">LOGIN</a>*/}
                <a
                  href={
                    USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href
                  }
                  className="visible-xs text-center"
                >
                  LOGIN
                </a>
              </li>
              <li className="nav-item Nav_mobile_account">
                {/*<a href="{% url 'signup' %}" className="visible-xs text-center">JOIN</a>*/}
                <a
                  href={USERMODEL_URL + AUTH_SIGN_UP}
                  className="visible-xs text-center"
                >
                  JOIN
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="hidden-xs mini-nav">
          <div className="container place_nav">
            <ul className="nav navbar-nav navbar-left">
              <li className="nav-item place-nav-item">
                <a href={THE_MUSIO_URL}>Home</a>
              </li>
              <li className="nav-item place-nav-item">
                <a href={THE_MUSIO_URL + "/mypage/info"}> > Mypage</a>
              </li>
              <li className="nav-item place-nav-item">
                {window.location.pathname === "/mypageAddress" && (
                  <a href="#" className="current">
                    {" "}
                    > Address Book
                  </a>
                )}
                {window.location.pathname === "/mypageCard" && (
                  <a href="#" className="current">
                    {" "}
                    > Card Info
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { isAuthenticated: state.AuthReducer.isAuthenticated };
};

export default connect(mapStateToProps)(MypageNavbar);
