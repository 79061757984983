import axios from "axios";
import { LOG } from "../utils/Log";
import { AUTH_LOGOUT, AUTH_SIGN_IN, USERMODEL_URL } from "../utils/url";

const GET_AUTH_PENDING = "GET_AUTH_PENDING";
const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS";
const GET_AUTH_ERROR = "GET_AUTH_ERROR";
const GET_AUTH_LOGOUT = "GET_AUTH_LOGOUT";
const GET_AUTH_NO_SESSION = "GET_AUTH_NO_SESSION";

export function get_auth(sessionToken) {
  // LOG("auth @ get_auth , sessionToken : " + sessionToken);
  return (dispatch) => {
    dispatch({
      type: GET_AUTH_PENDING,
    });

    const bodyFormData = new FormData();
    bodyFormData.append("session_token", sessionToken);
    bodyFormData.append(
      "client_key",
      "o07rEE8LYmsgGTBkvUOQfnlb9AfKB5Beqis9mODj9Fs7fdFN3H0bME1iJn9VShUJ4riAOXwbyf8q9IltnnrZi1dGr9oS2kV39WXD7Ty7beZwkm4ug23k0uNR5LXLljES"
    );
    //LOG("bodyFormData : " + bodyFormData);
    axios
      .post(USERMODEL_URL + AUTH_SIGN_IN, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // LOG("auth @ get_auth , response : " + JSON.stringify(response.data));
        dispatch({
          type: GET_AUTH_SUCCESS,
          jwt: response.data.token,
        });
      })
      .catch((error) => {
        // LOG("auth @ get_auth , error : " + error);
        dispatch({
          type: GET_AUTH_ERROR,
        });
      });
  };
}

export function login_fail() {
  // LOG("auth @ login_fail");
  return (dispatch) =>
    dispatch({
      type: GET_AUTH_NO_SESSION,
    });
}

export function logout() {
  // LOG("auth @ logout");
  return (dispatch) => {
    window.location.href =
      USERMODEL_URL + AUTH_LOGOUT + "?next=" + window.location.href;
    dispatch({ type: GET_AUTH_LOGOUT });
  };
}
