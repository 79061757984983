import React from 'react'
//import { Link } from 'react-router-dom';
import {Row, Col, Grid, Button} from 'react-bootstrap'
import './css/AdminOrder.css'
import axios from 'axios'
import {STORE_ADMIN_SALE_PRODUCTS, STORE_API_URL} from '../../utils/url'

//import Dropzone from 'react-dropzone'
import DatePicker from "react-datepicker";
import moment from 'moment'

import {connect} from 'react-redux'
import {LOG} from "../../utils/Log";

class AdminOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_admin: false,
      loading: false,
      error: false,

      order_info: [],
      start_date: moment().subtract(1, 'months'),
      end_date: moment()
    }
  }

  onStartDateChange = (date) => {
    if (!this.checkDate(date, 0)) {
      this.setState({
        ...this.state,
        end_date: date,
        start_date: date
      })
    } else {
      this.setState({
        ...this.state,
        start_date: date
      })
    }
  };

  onEndDateChange = (date) => {
    if (!this.checkDate(date, 1)) {
      this.setState({
        ...this.state,
        end_date: this.state.start_date
      })
    } else {
      this.setState({
        ...this.state,
        end_date: date
      })
    }
  };

  checkDate = (date, mode) => {
    let start_date = this.state.start_date;
    let end_date = this.state.end_date;

    if (mode === 0) start_date = date;
    else end_date = date;
    return start_date <= end_date;
  };


  componentWillMount() {
    this.setState({
      loading: true,
    });
    let product_id = this.props.match.params.product_id;
    axios.get(STORE_API_URL + STORE_ADMIN_SALE_PRODUCTS + "/" + product_id, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        this.setState({
          order_info: response.data.data,
          is_admin: true,
          loading: false,
          error: false
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        })
      })
  }

  componentDidMount() {
  }

  clickSearch = () => {
    this.setState({
      loading: true,
    });

    let product_id = this.props.match.params.product_id;

    axios.get(STORE_API_URL + STORE_ADMIN_SALE_PRODUCTS + "/"+ product_id, {
      params: {
        start_date: this.state.start_date.format("YYYY-MM-DD"),
        end_date: this.state.end_date.format("YYYY-MM-DD"),
      },
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        this.setState({
          order_info: response.data.data,
          is_admin: true,
          loading: false,
          error: false
        })
      })
      .catch((error) => {
        LOG(error);
        this.setState({
          loading: false,
          error: true,
        })
      })
  };

  render() {
    if (this.state.is_admin === false && this.state.loading === false)
      return (<div><h1> unauthorized </h1></div>);

    if (this.state.error)
      return (<div><h1> Error </h1></div>);

    if (this.state.loading) {
      return (
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>Loading...</span>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Grid className="admin_grid">
          <Row>
            <Col xs={12} className="admin_container">
              <div className="text-left">
                <div className="time-picker-wrapper">
                  <span>Start Date: </span>
                  <DatePicker
                    dateFormat="YYYY-MM-DD"
                    selected={this.state.start_date}
                    onChange={this.onStartDateChange}
                  />
                </div>
                <div className="time-picker-wrapper">
                  <span>End Date: </span>
                  <DatePicker
                    dateFormat="YYYY-MM-DD"
                    selected={this.state.end_date}
                    onChange={this.onEndDateChange}
                  />
                </div>
                <div className="time-search">
                  <Button onClick={this.clickSearch}>Search</Button>
                </div>
              </div>
              <div className="text-left amount">
                amount:{this.state.order_info.amount}
              </div>
              <div className="scroll_div">


                {this.state.order_info.orders.map((item, index) => (
                  <div className="admin_order_box" key={"box" + index}>
                    <img src={this.state.order_info.product.thumbnail} alt={""}/>
                    <div className="item_info_box">
                      <span className="item_name">{this.state.order_info.product.name}</span>
                      <span>count: {item.count}</span>
                      <span>price: {item.price}</span>
                    </div>
                    <div className="item_name_box">
                      <span className="item_date">{item.create_date}</span>
                      <span className="item_email">{item.user_email}</span>
                    </div>
                    {item.address !== null &&
                    <div className="item_address_box">
                      Name: <span
                      className="address_name">{item.address.last_name} {item.address.first_name}</span><br/>
                      <hr/>
                      Address: <span className="address_item">{item.address.address_state}</span>
                      <span className="address_item">{item.address.address_city}</span>
                      <span className="address_item">{item.address.address_line}</span>
                      <span className="address_item">{item.address.address_line2}</span>
                      <hr/>
                      Zip: <span className="address_item">{item.address.address_zip}</span>
                      <hr/>
                      Phone: <span className="address_item">{item.address.phone}</span>
                    </div>
                    }
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrder);
