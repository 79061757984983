import React from 'react'
import {Link} from 'react-router-dom';
import {Row, Col, Grid} from 'react-bootstrap'
import './css/AdminProducts.css'
import axios from 'axios'
import {STORE_ADMIN_PRODUCTS, STORE_ADMIN_SALE, STORE_API_URL} from '../../utils/url'
import {connect} from 'react-redux'

//import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
//import Dropzone from 'react-dropzone'

class AdminSale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_admin: false,
      loading: false,
      category: "contents",
      product_items: [],
      selected_items: []
    }
  }

  componentWillMount() {
    this.setState({
      loading: true,
    });
    axios.get(STORE_API_URL + STORE_ADMIN_PRODUCTS, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        let length = response.data.data.length;
        let selected_items = new Array(length).fill(0);
        this.setState({
          product_items: response.data.data,
          selected_items: selected_items,
          is_admin: true,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  }

  componentDidMount() {
  }

  productSelect = (index) => {
    let selected_items = this.state.selected_items;
    selected_items[index] = !selected_items[index];
    this.setState({
      ...this.state,
      selected_items: selected_items
    })
  };

  clickCategory = (cate) => {
    this.setState({
      loading: true,
      category: cate,
    });
    axios.get(STORE_API_URL + STORE_ADMIN_PRODUCTS, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
      params: {
        category: cate,
      }
    })
      .then((response) => {
        let length = response.data.data.length;
        let selected_items = new Array(length).fill(false);

        this.setState({
          product_items: response.data.data,
          selected_items: selected_items,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  };

  saleConfirm = () => {
    if (this.sale_percent.value === "") {
      alert('Please Input your number');
      return
    }
    let sale_percent = parseInt(this.sale_percent.value, 0);

    if (isNaN(sale_percent)) {
      alert('Please Input Number');
      return
    }

    let sale_list = [];

    let product_items = this.state.product_items;
    let selected_items = this.state.selected_items;

    for (let i = 0; i < selected_items.length; i++) {
      if (selected_items[i]) {
        sale_list.push(product_items[i].id);
        selected_items[i] = false;
        if (sale_percent === 0) {
          product_items[i].sale_price = undefined
        } else product_items[i].sale_price = parseInt(product_items[i].price * ((100 - sale_percent) / 100.0))

      }
    }
    this.sale_percent.value = "";
    this.setState({
      ...this.state,
      loading: true
    });

    axios.post(STORE_API_URL + STORE_ADMIN_SALE, {
      sale_percent: sale_percent,
      items: sale_list
    }, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then(() => {
        this.setState({
          product_items: product_items,
          selected_items: selected_items,
          loading: false,
        })
      })
      .catch((error) => {
        alert(error);
        this.setState({
          loading: false,
        })
      })
  };

  render() {
    if (this.state.is_admin === false && this.state.loading === false)
      return (<div><h1> unauthorized </h1></div>);

    return (
      <div>
        {this.state.loading &&
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>Loading...</span>
          </div>
        </div>
        }
        <Grid className="admin_grid">
          <Row>
            <Col xs={12} className="category_bar">
              <Link to="/adminSaleProducts">
                <button className="create_btn">Show Sale Products</button>
              </Link><br/>
              <button onClick={() => this.clickCategory("contents")}
                      className={`${this.state.category === "contents" ? "active" : " "}`}>contents
              </button>
              <button onClick={() => this.clickCategory("goods")}
                      className={`${this.state.category === "goods" ? "active" : " "}`}>goods
              </button>
              <button onClick={() => this.clickCategory("musio&friends")}
                      className={`${this.state.category === "musio&friends" ? "active" : " "}`}>musio&sophy
              </button>
              <div>
                <input type="text" placeholder="Sale Percent(if you input 0 sale is remove)"
                       ref={i => {
                         this.sale_percent = i
                       }}
                       style={{width: '50%'}}/>
                <button onClick={() => this.saleConfirm()}>Confirm</button>
              </div>
            </Col>
            <Col xs={12} className="admin_container">
              <div className="click_item">
                {
                  this.state.product_items.map((item, index) => {
                    return (
                      <Col xs={3} className="product_col" key={"item" + index}
                           onClick={() => this.productSelect(index)}>

                        <div className="admin_product_item"
                             style={this.state.selected_items[index] ?
                               {borderColor: 'red', borderWidth: '3px'} : {}}>
                          <img draggable="false" src={item.thumbnail} alt={""}/>
                          <span className="name">{item.name}</span>
                          <span className="price" style={item.sale_price ? {color: 'red'} : {}}>
                            ¥ {item.sale_price ? `${item.sale_price} Sale!` : item.price}
                          </span>
                        </div>
                      </Col>
                    )
                  })
                }
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSale);
