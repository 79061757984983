import React from 'react';
//import { Link } from 'react-router-dom';
import {Row, Col, Grid} from 'react-bootstrap'
import Modal from 'react-modal';
import SwipeableViews from 'react-swipeable-views';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './css/Mypage.css'
import axios from 'axios'
import {STORE_API_URL, STORE_REST_ADDRESS, STORE_REST_EDGE} from '../../utils/url'
import MypageSidebar from './MypageSidebar'
import left from './img/arrow1.svg';
import right from './img/arrow2.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {connect} from 'react-redux'
import {LOG} from "../../utils/Log";

class MypageOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edge_list: [],
      members_list: [],

      show_modal: false,
      show_change_modal: false,
      slideIndex: 0,
      date: "",
      time: "",
      selectedAddressData: "",
      address_list: [],
      loading: false,
      member_edge_info: {
        name: "Loading"
      }
    }
  }

  componentWillMount() {
    axios.get(STORE_API_URL + STORE_REST_EDGE, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        // LOG(response);
        this.setState({
          edge_list: response.data.data["edges"],
          members_list: response.data.data["members"],
          loading: false,
        })
      })
      .catch((error) => {
        // LOG(error);
        this.setState({
          loading: false,
        })
      })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loading: true,
    });
    Modal.setAppElement('#mypage_div');
  }

  handleClickBlackBox = () => {
    document.querySelector("#sidebar-left").style.left = "";
    document.querySelector("#black_box").style.backgroundColor = "";
    document.querySelector("#black_box").style.zIndex = "";
    document.querySelector(".side-toggle").dataset.clicked = "false";
  };
  handleIndexChange = (event, index) => {
    this.setState({
      slideIndex: index,
    });
  };

  handleSubmit = () => {
    if (this.state.date === "" || this.state.time === "" || this.state.selectedAddressData === "") {
      alert("全ての必須箇所を入力してください。");
      return false;
    }
    let data = {
      "date": this.state.date,
      "time": this.state.time,
      "address": this.state.selectedAddressData,
      "id": this.state.edge_id
    };
    this.setState({
      loading: true,
    });
    axios.delete(STORE_API_URL + STORE_REST_EDGE, {
      data: data,
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        // LOG(response);
        axios.get(STORE_API_URL + STORE_REST_EDGE, {
          headers: {"Authorization": "Bearer " + this.props.jwt},
        })
          .then((response) => {
            // LOG(response);
            this.handleCloseModal();
            this.setState({
              edge_list: response.data.data["edges"],
              members_list: response.data.data["members"],
              loading: false,
            })
          })
          .catch((error) => {
            // LOG(error);
          })
      })
      .catch(() => {
        alert("Error!");
        this.setState({
          loading: false,
        })
      })
  };
  handleCloseModal = () => {
    this.setState({
      show_modal: false,
      show_change_modal: false,
      slideIndex: 0,
      startDate: undefined,
      date: "",
      time: "",
      selectedAddressData: "",
      edge_id: "",
    })
  };

  handleOpenEdgeChange = (id) => {
    this.setState({
      loading: true,
    });
    axios.get(STORE_API_URL + STORE_REST_EDGE + "/" + id, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        // LOG(response);
        this.setState({
          member_edge_info: response.data.data,
          show_change_modal: true,
          loading: false,
        })
      })
      .catch((error) => {
        // LOG(error);
        this.setState({
          loading: false,
        })
      });
  };

  handleOpenForm = (id) => {
    axios.get(STORE_API_URL + STORE_REST_ADDRESS, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        // LOG(response);
        this.setState({
          address_list: response.data.data,
        })
      })
      .catch((error) => {
        // LOG(error);
      })
    this.setState({
      show_modal: true,
      edge_id: id
    });

  };
  handleDateChange = (date) => {
    this.setState({
      startDate: date,
      date: date.format("YYYY-MM-DD")
    });
  };
  handleTimeChange = (event) => {
    this.setState({
      time: event.currentTarget.value
    });
  };
  handleSelectAddress = (item = {}) => {
    this.setState({
      selectedAddressData: item
    })
  };
  renderAddressList = () => {
    return (
      <div>
        {this.state.address_list.map((item, index) => (
          <div className="my_address_div" key={"add" + index}>
            <p className="address_info_p">
              <span className="address_name">{item["last_name"]}</span>&nbsp;
              <span className="address_name">{item["first_name"]}</span>
              <br/>
              <span>{item["address_zip"]}</span>
              <br/>
              <span>{item["address_state"]}</span>&nbsp;
              <span>{item["address_city"]}</span>
              <br/>
              <span>{item["address_line"]}</span>
              {item["address_line2"] &&
              <span>
                  <br className="hidden-xs"/>
                  <span className="visible-xs-inline"> / </span>
                  <span>{item["address_line2"]}</span>
                </span>
              }
            </p>
            <hr/>
            <p className="control_p">
              <button onClick={(event) => {
                this.handleSelectAddress(item);
                this.handleIndexChange(event, 2)
              }}>Select
              </button>
            </p>
          </div>
        ))}
      </div>
    )
  };
  renderForm = () => {
    return (
      <SwipeableViews
        disabled={true}
        index={this.state.slideIndex}
        onChangeIndex={this.handleChangeIndex}
      >
        <div>
          <img className="left_btn" src={left} onClick={this.handleCloseModal} alt={""}/>
          <img className="right_btn" src={right} onClick={(event) => this.handleIndexChange(event, 1)} alt={""}/>
          <span className="text-center edge_modal_title title">回収日選択</span>
          <br/>
          <span className="text-center edge_modal_title subtitle">下記の情報を記入してください</span>
          <hr/>
          <form className="new_address_form" onSubmit={this.handleSubmit}>
            <div className="select_date_div">
              <span>日にち選択</span>
              <div>
                <DatePicker
                  disabledKeyboardNavigation
                  className="cancel_calendar"
                  selected={this.state.startDate}
                  onChange={this.handleDateChange}
                  dateFormat="YYYY-MM-DD"
                  minDate={moment().add(1, "month").endOf("month")}
                  isClearable={true}
                  maxDate={moment().add(1, "month").endOf("month").add(15, "days")}
                  placeholderText="YYYY-MM-DD"
                />
              </div>
            </div>
            <div className="select_date_div">
              <span>時間選択</span>
              <select className="cancel_calendar" required value={this.state.time} onChange={this.handleTimeChange}>
                <option value="" disabled>---</option>
                <option>8~12時</option>
                <option>12～16時</option>
                <option>16～18時</option>
                <option>18～21時</option>
              </select>
            </div>
          </form>
        </div>
        <div className="payment_address_div edge_address_div">
          <img className="left_btn" src={left} onClick={(event) => this.handleIndexChange(event, 0)} alt={""}/>
          <img className="right_btn2" src={right} onClick={(event) => this.handleIndexChange(event, 2)} alt={""}/>
          <span className="text-center edge_modal_title title">ご訪問住所を選択</span>
          <br/>
          <span className="text-center edge_modal_title subtitle">下記の情報を記入してください</span>
          <hr/>
          <div className="address_list_wrapper">
            {this.renderAddressList()}
          </div>
        </div>
        <div>
          <img className="left_btn" src={left} onClick={(event) => this.handleIndexChange(event, 1)} alt={""}/>
          <span className="text-center edge_modal_title title">回収情報の確認</span>
          <br/>
          <span className="text-center edge_modal_title subtitle">下記の情報を記入してください</span>
          <hr/>
          <table className="table" id="edge_address_table">
            <thead>
            <tr id="edge_address_table_title">
              <th className="text-center" id="day">回収日</th>
              <th className="text-center" id="time">時間</th>
              <th className="text-center" id="address">住所</th>
            </tr>
            </thead>
            <tbody>
            <tr className="edge_table_content">
              <td className="text-left">
                <p>
                    <span>
                    {this.state.date !== undefined && this.state.date !== "" ?
                      this.state.date :
                      "Please, Select Date"
                    }
                    </span>
                </p>
              </td>
              <td className="text-center">
                <p>
                    <span>
                      {this.state.time !== undefined && this.state.time !== "" ?
                        this.state.time :
                        "Please, Select Time"
                      }
                    </span>
                </p>
              </td>
              <td className="text-center">
                {this.state.selectedAddressData !== undefined && this.state.selectedAddressData !== "" ?
                  this.state.selectedAddressData["address_state"] + " " +
                  this.state.selectedAddressData["address_city"] + " " +
                  this.state.selectedAddressData["address_line"] :
                  "Please, Select Address"
                }
              </td>
            </tr>
            </tbody>
          </table>
          <div className="form_control_wrapper">
            <button type="submit" onClick={this.handleSubmit}>Submit</button>
            <button className="cancel_btn" type="button" onClick={this.handleCloseModal}>Cancel</button>
          </div>
        </div>
      </SwipeableViews>
    )
  };

  render() {
    // if( true )
    //   return(
    //     <div id="mypage_div">
    //       <Grid>
    //         <Row>
    //           <MypageSidebar/>
    //           <Col xs={12} sm={9} lg={10} className="purchase_container" id="mypage_content_div">
    //             <div id="black_box" className="visible-xs" onClick={this.handleClickBlackBox}>
    //             </div>
    //             <div>
    //               <span className="title">EDGE 管理</span>
    //               <br/>
    //               <span className="desc">
    //                 準備中
    //               </span>
    //               <hr />
    //             </div>
    //           </Col>
    //         </Row>
    //       </Grid>
    //     </div>
    //   )
    return (
      <div id="mypage_div">
        {this.state.loading &&
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>Loading...</span>
          </div>
        </div>
        }
        <Grid>
          <Row>
            <Modal
              isOpen={this.state.show_modal}
              onRequestClose={this.handleCloseModal}
              contentLabel="onRequestClose Example"
              className="modal_content edge_modal_content"
              overlayClassName="modal_overlay"
            >
              {this.renderForm()}
            </Modal>
            <Modal
              isOpen={this.state.show_change_modal}
              onRequestClose={this.handleCloseModal}
              contentLabel="onRequestClose Example"
              className="modal_content edge_change_modal_content"
              overlayClassName="modal_overlay"
            >
              <span className="change_edge_title"> 使用しているEdgeコンテンツ </span>
              <hr/>
              <span className="current_edge_title">{this.state.member_edge_info["name"]}</span>
              <button> Business/Hawaii 切り替え</button>
            </Modal>
            <MypageSidebar jwt={this.props.jwt}/>
            <Col xs={12} sm={9} lg={10} className="purchase_container" id="mypage_content_div">
              <div id="black_box" className="visible-xs" onClick={this.handleClickBlackBox}>
              </div>
              <div>
                <span className="title">EDGE 管理</span>
                <br/>
                <span className="desc">
                    EDGE商品を管理することができます。
                  </span>
                <hr/>
                <span className="section_name">EDGEの解約申請</span>
                <br/><br/>
                <span className="section_sub_name">
                  EDGEの解約をご希望の場合は、大変お手数ですが、<br/>
                  ご使用中のアカウントIDをご記載の上、<b>info-jp*akaintelligence.com</b> (*を@に変更)へ <br/>
                  メールにてご連絡お願いいたします。
                </span>
                {/*<span className="section_sub_name">EDGEサービスの解約を申請できます。</span>
                <span className="edge_cancel_title">使用中のサービス</span>
                <table className="table" id="order_table" style={{marginTop: 0, marginBottom: "150px"}}>
                  <thead>
                  <tr id="order_list_title">
                    <th className="text-center" id="product">使用中のサービス</th>
                    <th className="text-center" id="date">サービス開始日</th>
                    <th className="text-center" id="quantity">有効期限</th>
                    <th className="text-center" id="total">解約申請</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.edge_list.map((item, index) => (
                    <tr className="edge_list_content" key={"edge_list" + index}>
                      <td>
                        <span>{item["product_name"]}</span>
                        {item["option"].map((opt, index) => (
                          <span className="edge_list_option" key={"options" + index}>
                                {opt["name"]}
                              </span>
                        ))}
                      </td>
                      <td className="text-center">
                        <span>{item["create_date"]}</span>
                      </td>
                      <td className="text-center">
                        -
                      </td>
                      <td className="text-center">
                        {item["cancel_data"] === undefined ?
                          <button className="musio-btn" onClick={() => this.handleOpenForm(item["id"])}>解約申込</button> :
                          <button className="musio-btn reservation_btn" disabled>申込完了</button>
                        }
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>*/}
                <hr/>
                {/* <span class="section_name">EDGEコンテンツ変更</span>
                  <span class="section_sub_name">EDGEのコンテンツを変更できます。</span>
                  <span className="edge_change_title">メンバーの選択</span>
                  <div style={{display: "block", width: "100%", textAlign: "left"}}>
                    {this.state.members_list.map((item,index)=>(
                      <button className="member_btn" key={"member"+index}
                        onClick={()=>this.handleOpenEdgeChange(item["member_id"])}
                      >
                        {item["name"]}
                      </button>
                    ))}
                  </div> */}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(MypageOrder);
