import React from 'react'
import {Link} from 'react-router-dom';
import {Row, Col, Grid} from 'react-bootstrap'
import './css/AdminProducts.css'
import axios from 'axios'
import {STORE_ADMIN_SALE_PRODUCTS, STORE_API_URL} from '../../utils/url'
//import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
//import Dropzone from 'react-dropzone'
import {connect} from 'react-redux'
import {LOG} from "../../utils/Log";

class AdminSaleProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_admin: false,
      loading: false,
      category: "contents",
      product_items: []
    }
  }

  componentWillMount() {
    LOG("AdminSaleProducts @ componentWillMount");
    this.setState({
      loading: true,
    });
    axios.get(STORE_API_URL + STORE_ADMIN_SALE_PRODUCTS, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        this.setState({
          product_items: response.data.data,
          is_admin: true,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  }

  componentDidMount() {
    LOG("AdminSaleProducts @ componentDidMount");
  }

  clickCategory = (cate) => {
    LOG("AdminSaleProducts @ clickCategory , cate : " + cate);

    this.setState({
      loading: true,
      category: cate,
    });
    axios.get(STORE_API_URL + STORE_ADMIN_SALE_PRODUCTS, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
      params: {
        category: cate,
      }
    })
      .then((response) => {
        this.setState({
          product_items: response.data.data,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  };

  render() {
    LOG("AdminSaleProducts @ render");

    LOG("is_admin : " + this.state.is_admin);
    LOG("loading : " + this.state.loading);

    if (this.state.is_admin === false && this.state.loading === false)
      return (<div><h1> unauthorized </h1></div>);

    return (
      <div>
        {this.state.loading &&
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>Loading...</span>
          </div>
        </div>
        }
        <Grid className="admin_grid">
          <Row>
            <Col xs={12} className="category_bar">
              <button onClick={() => this.clickCategory("contents")}
                      className={`${this.state.category === "contents" ? "active" : " "}`}>contents
              </button>
              <button onClick={() => this.clickCategory("goods")}
                      className={`${this.state.category === "goods" ? "active" : " "}`}>goods
              </button>
              <button onClick={() => this.clickCategory("musio&friends")}
                      className={`${this.state.category === "musio&friends" ? "active" : " "}`}>musio&sophy
              </button>
            </Col>
            <Col xs={12} className="admin_container">
              <div className="click_item">
                {
                  this.state.product_items.length === 0 &&
                  <span>No Item</span>
                }
                {
                  this.state.product_items.map((item, index) => {
                    return (

                      <Col xs={3} className="product_col" key={"item" + index}>
                        <div className="admin_product_item">
                          <Link to={"/adminSaleProducts/" + item.id}>
                            <img draggable="false" src={item.thumbnail} alt={""}/>
                            <span className="name">{item.name}</span>
                            <span className="price" style={{color: 'red'}}>
                              ¥ {item.sale_price} Sale!
                            </span>
                          </Link>
                        </div>
                      </Col>
                    )
                  })
                }
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSaleProducts);
