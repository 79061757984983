import axios from 'axios';
import {STORE_REST_PRODUCTS, STORE_API_URL} from '../utils/url';
import {LOG} from "../utils/Log";

const APPEND_FREE_SUCCESS = 'APPEND_FREE_SUCCESS';
const GET_FREE_PENDING = 'GET_FREE_PENDING';
const GET_FREE_SUCCESS = 'GET_FREE_SUCCESS';
const GET_FREE_ERROR = 'GET_FREE_ERROR';

export function get_free(tag_index, is_new) {
  // LOG("frees @ get_free , tag_index : " + tag_index + " , is_new : " + is_new);

  return (dispatch, getState) => {
    dispatch({type: GET_FREE_PENDING});
    //axios.get(url+"/rest/products?catergory=${category}&tag=${tag}")
    let request_url = STORE_API_URL + STORE_REST_PRODUCTS;

    let tag;
    if (tag_index === 1) tag = "乳児";
    if (tag_index === 2) tag = "小学生";
    if (tag_index === 3) tag = "中高生";
    if (tag_index === 4) tag = "大人";

    let next_token = getState().FreeReducer.free_contents[tag_index].next_token;

    axios.get(request_url, {
      params: {
        category: 'free',
        start_token: next_token,
        tag: tag
      }
    }).then(
        (response) => {
          let type = APPEND_FREE_SUCCESS;
          if (is_new) type = GET_FREE_SUCCESS;
          dispatch({
            type: type,
            data: response.data.data,
            tag_index: tag_index,
            next_token: response.data.next_token
          })
        }
      ).catch(
        (error) => {
          // LOG(error);
          dispatch({type: GET_FREE_ERROR})
        }
      )
  }
}
