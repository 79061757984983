import React from 'react'
import {Row, Col, Grid} from 'react-bootstrap'
import './css/ContentsMain.css'

import ContentTitle from '../home/img/home_contents_title.png'

import ContentsList from '../public_components/ContentsList'
import ContentCategory from '../public_components/ContentCategory'

import defaultContents from '../home/defaultContents'

import {connect} from 'react-redux'
import {get_contents} from '../../actions/contents'
//import Footer from '../../footer/Footer'

import Loading from '../../utils/Loading';

class ChargeContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: defaultContents,
      tag: 0

    };
    this.onCategoryClick = this.onCategoryClick.bind(this);
    this.onMoreClick = this.onMoreClick.bind(this);
  }

  componentWillMount() {
    if (this.props.contents[this.state.tag].data.length === 0) {
      this.props.getContents(this.state.tag, true)
    }
  }

  componentDidMount() {
  }

  onCategoryClick(i) {
    this.setState({
      ...this.state,
      tag: i
    });
    if (this.props.contents[i].data.length === 0)
      this.props.getContents(i, false)
  }

  onMoreClick() {
    this.props.getContents(this.state.tag, false)
  }


  render() {


    return (
      <div>
        <Grid>
          <div className="contents-banner">
            <Col className="text-center">
              <img alt="banner_img" className="img-responsive" src={ContentTitle}/>
            </Col>
          </div>
          <ContentCategory onCategoryClick={this.onCategoryClick}/>
          <Row className="product_number">
            <Col xs={12}>
              <span className="highlight">{this.props.contents[this.state.tag].data.length}</span>
              <span>個の商品</span>
            </Col>
          </Row>
          <ContentsList contents={this.props.contents[this.state.tag].data}/>
          <Row>
            {
              this.props.contents[this.state.tag].next_token &&
              <Col className="more_button" xsOffset={4} xs={4}>
                <div className="text-center" onClick={this.onMoreClick}>
                  More
                </div>
              </Col>
            }
          </Row>
        </Grid>
        {
          this.props.pending &&
          <Loading/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.ContentsReducer.contents,
    pending: state.ContentsReducer.pending
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getContents: (tag, is_new) => dispatch(get_contents(tag, is_new))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargeContents);
