import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../footer/Footer";
import { trackEvent } from "../../utils/tracking";
import "./css/Service.css";
import { FriendsPlanContent } from "./FriendsPlanContent";
import academy from "./img/academy.png";
import edge from "./img/edge.png";
import musio_edge from "./img/img_W_edge.png";
import kids from "./img/img_W_kids.png";

class Service extends React.Component {
  state = {
    selectedCategory: 1,
  };

  componentDidMount() {
    if (this.props.location.hash === "#friendsPlan") {
      this.setState({ ...this.state, selectedCategory: 1 });
    }
  }

  onCategoryClick(i) {
    this.setState({
      ...this.state,
      selectedCategory: i,
    });
  }

  onEdgeSelect = (i) => {
    trackEvent("SERVICE", "Select EDGE: " + i, "");
  };

  render() {
    let content;
    // if (this.state.selectedCategory === 0) {
    //   content = (
    //     <div>
    //       <div>
    //         <Col xs={12} className="banner">
    //           <img src={edge} alt="banner" />
    //         </Col>
    //       </div>

    //       <div>
    //         <Link to="/edge/adult">
    //           <Col xs={6} className="edge-image">
    //             <img src={musio_edge} alt="musio_edge" />
    //           </Col>
    //         </Link>

    //         <Link to="/edge/kids">
    //           <Col xs={6} className="edge-image">
    //             <img src={kids} alt="musio_edge" />
    //           </Col>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // }
    if (this.state.selectedCategory === 1) {
      content = (
        <FriendsPlanContent
          jwt={this.props.jwt}
          isAuthenticated={this.props.isAuthenticated}
        />
      );
    }
    if (this.state.selectedCategory === 2) {
      content = (
        <div>
          <div>
            <Col xs={12} className="banner">
              <img src={academy} alt="banner" />
            </Col>
          </div>

          <div>
            <Col xs={12}>
              <div className="text">
                Musio Academy Planは、
                教育現場で使用する際にMusioでの学習コンテンツ
                の編集や生徒の学習履歴を閲覧まど英語教育における便利なサポートを提供するサービスです。
              </div>
            </Col>
          </div>

          <div style={{ textAlign: "center" }}>
            <a
              href="https://academy.themusio.com"
              onClick={() => trackEvent("PRODUCT", "Navigate to: Academy", "")}
            >
              <div className="academy-more">more</div>
            </a>
          </div>
        </div>
      );
    }
    return (
      <div className="service">
        <Grid>
          <Row>
            <div className="category-wrapper">
              {/* <Col
                xs={4}
                className={
                  "category " +
                  (this.state.selectedCategory === 0 ? "select" : "")
                }
                onClick={() => this.onCategoryClick(0)}
              >
                EDGE
              </Col> */}
              <Col
                xs={6}
                className={
                  "category " +
                  (this.state.selectedCategory === 1 ? "select" : "")
                }
                onClick={() => this.onCategoryClick(1)}
              >
                Friends Plan
              </Col>
              <Col
                xs={6}
                className={
                  "category " +
                  (this.state.selectedCategory === 2 ? "select" : "")
                }
                onClick={() => this.onCategoryClick(2)}
              >
                Academy plan
              </Col>
            </div>
          </Row>
          <Row>{content}</Row>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Service);
