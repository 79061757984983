import React from 'react'
//import PropTypes from 'prop-types'

import MobileHeader from './MobileHeader.js'
import DesktopHeader from './DesktopHeader.js'

import {Col} from 'react-bootstrap'
//import { withCookies } from 'react-cookie';
//import { connect } from 'react-redux'
//import { get_auth } from '../actions/auth'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastScroll: 0,
      height: 102,
      is_open: false
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    let lastScroll = this.state.lastScroll;

    if (lastScroll !== 0) {
      let deltaY = (window.scrollY - lastScroll) / 2;

      let is_open;
      is_open = deltaY > 0;

      /*
      let header_height = (this.state.height - deltaY)
      if(header_height < 0){
        header_height = 0
        is_open = false;
      }
      else if(header_height > 102){
        header_height = 102
        is_open = true;
      }
      */
      if (this.state.is_open !== is_open) {
        this.setState(
          {
            ...this.state,
            is_open: is_open
          }
        )
      }
    }
    //this.state.lastScroll = window.scrollY
    this.state.setState({
      lastScroll: window.scrollY
    });
  };

  render() {

    return (
      <div>
        <Col xsHidden={true} smHidden={true}>
          <DesktopHeader category={this.props.category} isOpen={this.state.is_open}
            sub_category={this.props.sub_category}/>
        </Col>
        <Col lgHidden={true} mdHidden={true}>
          <MobileHeader category={this.props.category} isOpen={this.state.is_open}/>
        </Col>
      </div>
    );
  }
}


export default Header
