import React, { useEffect } from "react";

function HubspotForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.id = "hbspt-form";

    const portalId = "8884732";
    const formId = "b732e833-0afc-4c83-a8d4-f5f8d68a7f31";

    script.onload = () => {
      window.hbspt.forms.create({
        portalId,
        formId,
        target: "#hbspt-form",
        region: "na1",
      });
    };

    document.body.appendChild(script);

    return () => {
      const elem = document.getElementById("hbspt-form");
      if (elem) elem.remove();
    };
  }, []);

  return <div id="hbspt-form" />;
}

export default HubspotForm;
