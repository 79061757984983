import React, {Component} from 'react'
import {Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import './css/Goods.css'

import {trackEvent} from "../../utils/tracking"

const TAX_RATE=1.1

function round10(num){
  return Math.round(num / 1) * 1
}

class Goods extends Component {
  getPrice = () => {
    if (typeof (this.props.price) === 'number') {
      return `¥${round10(this.props.price*TAX_RATE).toLocaleString()}`
    } else {
      return this.props.price
    }
  };

  render() {
    let content = (
      <Row className="goods">
        <Col className="main_image">
          <img className="img-responsive" src={this.props.thumbnail} alt={this.props.name + " image"}/>
        </Col>
        <Col xs={12} className="text-center name">
          {this.props.name}
        </Col>
        <Col xs={12} className="text-center price">
          {this.props.sale_price &&
          <span className="sale_price">
              <s>
                {`¥${this.props.price.toLocaleString()}`}
              </s>
            </span>
          }
          {this.props.sale_price ?
            <span>¥{round10(this.props.sale_price*TAX_RATE).toLocaleString()}</span>
            :
            <span>{this.getPrice()}</span>
          }
        </Col>

      </Row>
    );

    if (this.props.link) {
      if (this.props.is_download) {
        return (
          <a href={this.props.link} download={this.props.link} onClick={() => {trackEvent("PRODUCT", "Click product: " + this.props.link, "")}}>
            {content}
          </a>
        )
      } else {
        return (
          <a href={this.props.link} onClick={() => {trackEvent("PRODUCT", "Click product: " + this.props.link, "")}}>
            {content}
          </a>
        )
      }
    } else {
      return (
        <Link to={"/product/" + this.props.id} onClick={() => {trackEvent("PRODUCT", "Click product: " + this.props.id, "")}}>
          {content}
        </Link>
      );
    }
  }
}

export default Goods;
