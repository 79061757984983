import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {THE_MUSIO_URL} from "../../utils/url";
import { LOG } from "../../utils/Log";
import { STORE_REST_ORDER, STORE_API_URL, AUTH_LOGIN,USERMODEL_URL } from "../../utils/url";
import axios from 'axios'
import './css/MyPageSidebar.css'


let isMusioJapan = false;



class MypageSidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  
    toggleMenu()  {
      this.setState(prevState => ({
        isMenuOpen: !prevState.isMenuOpen
      }));
    };

  componentDidMount(props) {
    if(this.props.jwt != undefined) {
      axios.get(STORE_API_URL + STORE_REST_ORDER, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
      })
      .then((response) => {
        for(let n of response.data.data.contents){
          if(n.product_id === "f7d73106-0f46-525f-b2a3-4f5e7b061293"){
            isMusioJapan= true;
          }
        }
        this.setState({
          orders_contents: response.data.data["contents"],
          orders_etc: response.data.data["etc"],
        });
      })
      .catch((error) => {
        LOG(error);
      });
    }
  }


  render() {
    //const currentPath = window.location.pathname;
    let isMobile = window.innerWidth <= 768;
    return (
      <div className={isMobile ? 'mobile__body' : ''}>
      {isMobile ?  
      <div className={this.state.isMenuOpen ? 'background trans' : 'background'} onClick={this.toggleMenu}>
        <div className="menu-toggle" >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <nav className={this.state.isMenuOpen ? 'active__modal' : ''}>
          <div id="sidebar-left" className="col-xs-9 col-sm-3 col-lg-2" style={this.state.isMenuOpen ? {backgroundColor:'transparent'} : {}}>
            {isMusioJapan ? 
            <ul className="sidemenus">
              <li className='menu__list'>
                <a href={THE_MUSIO_URL +"/mypage/info"} className="sidebar_category_sub" style={{fontSize: 16}}>会員情報確認</a>
                <a href={THE_MUSIO_URL + "/mypage/changepw"} className="sidebar_category_sub" style={{fontSize: 16}}>パスワード変更</a>
                <NavLink to="/mypageAddress" className="sidebar_category_sub" style={{fontSize: 16}}>住所リスト</NavLink>
                <NavLink to="/mypageCard" className="sidebar_category_sub" style={{fontSize: 16}}>決済カード</NavLink>
                <a href={THE_MUSIO_URL + "/mypage/activation"} className="sidebar_category_sub" style={{fontSize: 16}}>Activation Code</a>
                <NavLink to="/mypageQnA" className="sidebar_category_sub" style={{fontSize: 16}}>お問合せ</NavLink>
              </li>
            </ul> 
          : 
            <ul className="sidemenus">
            <li>
              <span className="sidebar_category">アカウント設定</span><br/>
              <a href={THE_MUSIO_URL +"/mypage/info"} className="sidebar_category_sub">会員情報確認</a><br/>
              <a href={THE_MUSIO_URL + "/mypage/changepw"} className="sidebar_category_sub">パスワード変更</a>
            </li>
            <li>
              <span className="sidebar_category">注文情報</span><br/>
              <NavLink to="/mypageOrder" className="sidebar_category_sub">注文履歴照会</NavLink><br/>
              <NavLink to="/mypageAddress" className="sidebar_category_sub">住所リスト</NavLink><br/>
              <NavLink to="/mypageCard" className="sidebar_category_sub">決済カード</NavLink>
            </li>
            <li>
              <span className="sidebar_category">My Musio</span><br/>
              <a href={THE_MUSIO_URL + "/mypage/plan"} className="sidebar_category_sub">Friends Plan 照会</a><br/>
              <a href={THE_MUSIO_URL + "/mypage/changeplan"} className="sidebar_category_sub">Friend Plan変更</a><br/>
              <a href={THE_MUSIO_URL + "/mypage/activation"} className="sidebar_category_sub">Activation Code</a>
            </li>
            <li>
              <span className="sidebar_category">Musioのユーザー</span><br/>
              <a href={THE_MUSIO_URL + "/mypage/members"} className="sidebar_category_sub">My Musioのユーザー</a><br/>
              <a href={THE_MUSIO_URL + "/mypage/chat"} className="sidebar_category_sub">ユーザーの会話履歴</a>
            </li>
      </ul>}
  </div>

      </nav></div> : 
      <div id="sidebar-left" className="col-xs-9 col-sm-3 col-lg-2">
      {isMusioJapan ? <ul className="sidemenus">
      <li style={{display:'flex', flexDirection:'column', height:'250px', justifyContent:'space-evenly'}}>
        <a href={THE_MUSIO_URL +"/mypage/info"} className="sidebar_category_sub" style={{fontSize: 16}}>会員情報確認</a>
        <a href={THE_MUSIO_URL + "/mypage/changepw"} className="sidebar_category_sub" style={{fontSize: 16}}>パスワード変更</a>
        <NavLink to="/mypageAddress" className="sidebar_category_sub" style={{fontSize: 16}}>住所リスト</NavLink>
        <NavLink to="/mypageCard" className="sidebar_category_sub" style={{fontSize: 16}}>決済カード</NavLink>
        <a href={THE_MUSIO_URL + "/mypage/activation"} className="sidebar_category_sub" style={{fontSize: 16}}>Activation Code</a>
        <NavLink to="/mypageQnA" className="sidebar_category_sub" style={{fontSize: 16}}>お問合せ</NavLink>

      </li>
      </ul> 
      
      : 
      <ul className="sidemenus">
      <li>
        <span className="sidebar_category">アカウント設定</span><br/>
        <a href={THE_MUSIO_URL +"/mypage/info"} className="sidebar_category_sub">会員情報確認</a><br/>
        <a href={THE_MUSIO_URL + "/mypage/changepw"} className="sidebar_category_sub">パスワード変更</a>
      </li>
      <li>
        <span className="sidebar_category">注文情報</span><br/>
        <NavLink to="/mypageOrder" className="sidebar_category_sub">注文履歴照会</NavLink><br/>
        <NavLink to="/mypageAddress" className="sidebar_category_sub">住所リスト</NavLink><br/>
        <NavLink to="/mypageCard" className="sidebar_category_sub">決済カード</NavLink>
      </li>
      <li>
        <span className="sidebar_category">My Musio</span><br/>
        <a href={THE_MUSIO_URL + "/mypage/plan"} className="sidebar_category_sub">Friends Plan 照会</a><br/>
        <a href={THE_MUSIO_URL + "/mypage/changeplan"} className="sidebar_category_sub">Friend Plan変更</a><br/>
        <a href={THE_MUSIO_URL + "/mypage/activation"} className="sidebar_category_sub">Activation Code</a>
      </li>
      <li>
        <span className="sidebar_category">Musioのユーザー</span><br/>
        <a href={THE_MUSIO_URL + "/mypage/members"} className="sidebar_category_sub">My Musioのユーザー</a><br/>
        <a href={THE_MUSIO_URL + "/mypage/chat"} className="sidebar_category_sub">ユーザーの会話履歴</a>
      </li>
      {/* <li>
        <span className="sidebar_category">Musio EDGE</span><br/>
        <NavLink to="/mypageEdge" className="sidebar_category_sub">EDGEの解約申請</NavLink><br/>
        <a href="https://edge.themusio.com/" className="sidebar_category_sub">EDGE会員向けページ</a><br/>
      </li> */}
      </ul>}
  </div>}
      </div>
      
      
    );  
  }
}


export default MypageSidebar;
