import React from 'react'
//import { Link } from 'react-router-dom';
import {Row, Col, Grid, Glyphicon} from 'react-bootstrap'
import './css/Mypage.css'
import axios from 'axios'
import {STORE_API_URL, STORE_REST_ADDRESS, STORE_REST_ADDRESS_SELECT} from '../../utils/url'
import Modal from 'react-modal';
import MypageSidebar from './MypageSidebar'
import Footer from '../../footer/Footer'
import {connect} from 'react-redux'
import check_img from './img/checked.png'
import {LOG} from "../../utils/Log";

class MypageAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address_modal_open: false,
      address_list: [],

      address_id: null,
      last_name: "",
      first_name: "",
      furigana_sei: "",
      furigana_mei: "",
      address_zip: "",
      address_state: "",
      address_city: "",
      address_line: "",
      address_line2: "",
      phone: "",

      selected_address: null,
      isCardConfirm: false,
    }
  }

  componentDidMount() {
    if(this.props.jwt != undefined) {
      axios.get(STORE_API_URL + STORE_REST_ADDRESS, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
      })
      .then((response) => {
        LOG(response);
        this.setState({
          address_list: response.data.data,
          selected_address: response.data.selected_address,
        })
      })
      .catch((error) => {
        LOG(error);
      })
    }
    window.scrollTo(0, 0);
    Modal.setAppElement('#mypage_div');
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(
      (nextProps.jwt != undefined && nextProps.jwt != this.props.jwt) ||
      (nextState != this.state)
    ) {
      return true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.jwt != undefined && prevProps != this.props) {
      axios.get(STORE_API_URL + STORE_REST_ADDRESS, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
      })
      .then((response) => {
        LOG(response);
        this.setState({
          address_list: response.data.data,
          selected_address: response.data.selected_address,
        })
      })
      .catch((error) => {
        LOG(error);
      })
    }
  }

  handleClickBlackBox = () => {
    document.querySelector("#sidebar-left").style.left = "";
    document.querySelector("#black_box").style.backgroundColor = "";
    document.querySelector("#black_box").style.zIndex = "";
    document.querySelector(".side-toggle").dataset.clicked = "false";
  };
  onClickAddAddress = (index = null) => {
    this.setState({
      address_modal_open: true,
      address_id: index !== null ? this.state.address_list[index]["id"] : null,
      last_name: index !== null ? this.state.address_list[index]["last_name"] : "",
      first_name: index !== null ? this.state.address_list[index]["first_name"] : "",
      furigana_sei: index !== null ? this.state.address_list[index]["furigana_sei"] : "",
      furigana_mei: index !== null ? this.state.address_list[index]["furigana_mei"] : "",
      address_zip: index !== null ? this.state.address_list[index]["address_zip"] : "",
      address_state: index !== null ? this.state.address_list[index]["address_state"] : "",
      address_city: index !== null ? this.state.address_list[index]["address_city"] : "",
      address_line: index !== null ? this.state.address_list[index]["address_line"] : "",
      address_line2: index !== null && this.state.address_list[index]["address_line2"] !== null ? this.state.address_list[index]["address_line2"] : "",
      phone: index !== null ? this.state.address_list[index]["phone"] : "",
    })
  };
  onDeleteAddress = (index) => {
    this.forceUpdate();
    axios.delete(STORE_API_URL + STORE_REST_ADDRESS, {
      data: {
        id: this.state.address_list[index]["id"],
      },
      headers: {"Authorization": "Bearer " + this.props.jwt}
    })
      .then((response) => {
        LOG(response);
      })
      .catch((error) => {
        LOG(error);
      });
    this.state.address_list.splice(index, 1);
  };
  onClickSelectAddress = (index) => {
    axios.put(STORE_API_URL + STORE_REST_ADDRESS_SELECT, {
      id: this.state.address_list[index]["id"]
    }, {
      headers: {"Authorization": "Bearer " + this.props.jwt}
    })
      .then((response) => {
        LOG(response);
      })
      .catch((error) => {
        LOG(error);
      });
    this.setState({
      selected_address: this.state.address_list[index]["id"]
    })
  };
  handleChange = (event) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    })
  };
  handleAddressSubmit = (event) => {
    event.preventDefault();
    if (this.state.last_name === "" || this.state.first_name === "" || this.state.furigana_sei === "" ||
      this.state.furigana_mei === "" || this.state.address_zip === "" || this.state.address_state === "" ||
      this.state.address_city === "" || this.state.address_line === "" || this.state.phone === "") {
      alert("全ての必須箇所を入力してください。");
      return;
    }
    let address_item = {
      last_name: this.state.last_name,
      first_name: this.state.first_name,
      furigana_sei: this.state.furigana_sei,
      furigana_mei: this.state.furigana_mei,
      address_zip: this.state.address_zip,
      address_state: this.state.address_state,
      address_city: this.state.address_city,
      address_line: this.state.address_line,
      address_line2: this.state.address_line2,
      phone: this.state.phone,
    };
    if (this.state.address_id == null) {
      axios.post(STORE_API_URL + STORE_REST_ADDRESS, address_item, {
        headers: {"Authorization": "Bearer " + this.props.jwt}
      })
        .then((response) => {
          this.closeModal();
          this.setState({
            address_list: [response.data.data, ...this.state.address_list],
          })
        })
        .catch((error) => {
          LOG(error);
        })
    } else if (this.state.address_id !== null) {
      axios.put(STORE_API_URL + STORE_REST_ADDRESS, {
        id: this.state.address_id,
        ...address_item
      }, {
        headers: {"Authorization": "Bearer " + this.props.jwt}
      })
        .then((response) => {
          let temp_address_list = this.state.address_list;
          let selected_index = temp_address_list.findIndex(x => x.id === this.state.address_id);
          temp_address_list[selected_index] = response.data.data;
          this.setState({ // change
            address_list: temp_address_list
          });
          this.closeModal();
          this.forceUpdate();
        })
        .catch((error) => {
          LOG(error);
        })
    }
  };
  closeModal = () => {
    this.setState({
      address_modal_open: false,
    })
  };

  render() {
    return (
      <div id="mypage_div">
        <Modal
          isOpen={this.state.address_modal_open}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          className="address_modal"
          overlayClassName="modal_overlay"
        >
          <div>
            <span className="modal_title">新しい配送先住所を追加</span>
            <form className="new_address_form" onSubmit={this.handleAddressSubmit}>
              <input required name="last_name" className="name_input pull-left" type="text" placeholder="姓"
                     value={this.state.last_name} onChange={this.handleChange}/>
              <input required name="first_name" className="name_input pull-right" type="text" placeholder="名"
                     value={this.state.first_name} onChange={this.handleChange}/>
              <input required name="furigana_sei" className="name_input pull-left" type="text" placeholder="フリガナ セイ"
                     value={this.state.furigana_sei} onChange={this.handleChange}/>
              <input required name="furigana_mei" className="name_input pull-right" type="text" placeholder="フリガナ メイ"
                     value={this.state.furigana_mei} onChange={this.handleChange}/>
              <input required name="address_zip" type="text" placeholder="郵便番号(ex: 123-4567)"
                     value={this.state.address_zip} onChange={this.handleChange}/>
              <select className="state_input" required name="address_state" value={this.state.address_state}
                      onChange={this.handleChange}>
                <option value="" disabled>---</option>
                <option>北海道</option>
                <option>青森県</option>
                <option>岩手県</option>
                <option>宮城県</option>
                <option>秋田県</option>
                <option>山形県</option>
                <option>福島県</option>
                <option>茨城県</option>
                <option>栃木県</option>
                <option>群馬県</option>
                <option>埼玉県</option>
                <option>千葉県</option>
                <option>東京都</option>
                <option>神奈川県</option>
                <option>新潟県</option>
                <option>富山県</option>
                <option>石川県</option>
                <option>福井県</option>
                <option>山梨県</option>
                <option>長野県</option>
                <option>岐阜県</option>
                <option>静岡県</option>
                <option>愛知県</option>
                <option>三重県</option>
                <option>滋賀県</option>
                <option>京都府</option>
                <option>大阪府</option>
                <option>兵庫県</option>
                <option>奈良県</option>
                <option>和歌山県</option>
                <option>鳥取県</option>
                <option>島根県</option>
                <option>岡山県</option>
                <option>広島県</option>
                <option>山口県</option>
                <option>徳島県</option>
                <option>香川県</option>
                <option>愛媛県</option>
                <option>高知県</option>
                <option>福岡県</option>
                <option>佐賀県</option>
                <option>長崎県</option>
                <option>熊本県</option>
                <option>大分県</option>
                <option>宮崎県</option>
                <option>鹿児島県</option>
                <option>沖縄県</option>
              </select>
              <input required className="pull-right city_input" type="text" placeholder="市区郡町村" name="address_city"
                     value={this.state.address_city} onChange={this.handleChange}/>
              <input required type="text" placeholder="番地 (ex: 1-23-456)" name="address_line"
                     value={this.state.address_line} onChange={this.handleChange}/>
              <input type="text" placeholder="ビル名 （オプション)" name="address_line2" value={this.state.address_line2}
                     onChange={this.handleChange}/>
              <input required type="text" placeholder="電話番号 (ex: 1234-56-7890)" name="phone" value={this.state.phone}
                     onChange={this.handleChange}/>
              <div className="form_control_wrapper">
                <button type="submit" style={{backgroundColor: "#ec6c00"}}>続ける</button>
                <button className="cancel_btn" type="button" onClick={this.closeModal}>キャンセル</button>
              </div>
            </form>
          </div>
        </Modal>
        <Grid>
          <Row>
            <MypageSidebar
            jwt={this.props.jwt}
            />
            <Col xs={12} sm={9} lg={10} className="purchase_container" id="mypage_content_div">
              <div id="black_box" className="visible-xs" onClick={this.handleClickBlackBox}>
              </div>
              <div>
                <span className="title">配送先住所</span>
                <br/>
                <span className="desc">
                    下記の情報を記入してください
                  </span>
                <hr/>
                <strong>既存の配送先住所を選択してください</strong>
                <div className="address_scroll">
                  <button className="add_address_btn" onClick={() => this.onClickAddAddress()}>
                    <Glyphicon glyph="glyphicon glyphicon-plus-sign"/>
                    <span className="add_span">新しい住所を追加</span>
                  </button>
                  <Row style={{margin: "0px"}}>
                    {this.state.address_list.map((item, index) => (
                        <Col xs={12} sm={6} md={4} className="address_wrapper" key={"address_card" + index}>
                          <div className={`address_card ${item['id'] === this.state.selected_address && 'active'}`}>
                            {item["id"] === this.state.selected_address &&
                            <img className="active_check" src={check_img} alt={""}/>
                            }
                            <span className="name">{item["first_name"]} {item["last_name"]}</span>
                            <span>{item["address_zip"]}</span>
                            <span>{item["address_state"]} / {item["address_city"]}</span>
                            <span>{item["address_line"]} {item["address_line2"] != null ? " / " + item['address_line2'] : ""}</span>
                            <hr/>
                            <div className="button_wrapper">
                              <button onClick={() => this.onDeleteAddress(index)}>削除</button>
                              <button onClick={() => this.onClickAddAddress(index)}>編集</button>
                              <button className="save_btn" onClick={() => this.onClickSelectAddress(index)}>選択</button>
                            </div>
                          </div>
                        </Col>
                      )
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(MypageAddress);
