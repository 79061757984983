import React, {Component} from 'react'
import {Row, Col, Grid} from 'react-bootstrap'
import './css/PurchaseMain.css'
import {Link} from 'react-router-dom';
import Header from '../../header/Header'
import check_img from './img/checked.png'

//import axios from 'axios'
//import url from '../../actions/url'

export default class SuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header category={"store"} sub_category={"Contents"}/>
        <Grid>
          <Row>
            <Col xs={12} style={{paddingTop: "300px", textAlign: "center",}}>
              <img src={check_img} style={{
                width: "100px",
              }} alt={""}/>
              <br/>
              <strong style={{
                display: "inline-block",
                fontSize: "24px",
                marginTop: "30px",
                marginBottom: "30px",
              }}>
                ありがとうございました! 購入を完了しました。
              </strong>
              <br/>
              <Link to="/">
                <button className="success_btn">
                  {'<  元のページに戻る'}
                </button>
              </Link>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
