import React from 'react'
import './css/PurchaseMain.css'
import axios from 'axios'
import {STORE_API_URL, STORE_REST_CART, STORE_REST_CHARGE} from '../../utils/url'
import {connect} from 'react-redux'
import {LOG} from "../../utils/Log";

import {trackEvent} from "../../utils/tracking"

function round10(num){
  return Math.round(num / 1) * 1
}
function round10f(num){
  return Math.floor(num / 1) * 1
}
const TAX_RATE=1.1
class PriceInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_price: 0,
      subscription_price: 0,
      tax: 0,
      loading: false,
    }
  }

  componentWillMount() {
    axios.get(STORE_API_URL + STORE_REST_CART, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        console.log("price info")
        this.setState({
          total_price: response.data.total_price,
          subscription_price: response.data.subscription_price,
          tax: response.data.tax
        })
        trackEvent("PURCHASE", "Success: Fetch price information", "")
      })
      .catch((error) => {
        LOG(error);
        trackEvent("PURCHASE", "Failed: Fetch information information", "Error: " + error)
      })
  }

  componentDidMount() {
  }

  onClickCharge = () => {
    if (this.props.selected_address == null) {
      alert("アドレスを選択してください");
      trackEvent("PURCHASE", "Failed: Purchase", "Address is not selected")
      return false;
    }
    if (this.props.isCardConfirm === false) {
      alert("カードの確認ボタンを押してください。");
      trackEvent("PURCHASE", "Failed: Purchase", "Card is not confirmed")
      return false;
    }
    this.setState({
      loading: true,
    });
    axios.post(STORE_API_URL + STORE_REST_CHARGE, {
      address_id: this.props.selected_address
    }, {
      headers: {"Authorization": "Bearer " + this.props.jwt}
    })
      .then((response) => {
        LOG(response);
        this.setState({
          loading: false,
        });
        trackEvent("PURCHASE", "Success: Purchase", "")
        // alert('Purchase Success')
        this.props.history.push('purchasesuccess')
      })
      .catch((error) => {
        LOG(error);
        this.setState({
          loading: false,
        })
        alert('Purchase Fail')
        trackEvent("PURCHASE", "Failed: Purchase", "Error: " + error)
      })

  };

  render() {
    return (
      <div>
        {this.state.loading &&
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>決済中...</span>
          </div>
        </div>
        }
        <div className="total_info">
          <strong>合計金額</strong>
          <hr/>
          <div>
            <strong className="pull-right">
              ¥ {round10(this.state.total_price *TAX_RATE).toLocaleString()}
              <strong
                style={{color: "#1a376f"}}>{this.state.subscription_price !== 0 && " + ¥ " + round10f(this.state.subscription_price*TAX_RATE).toLocaleString() + "/月"}</strong>
            </strong>
          </div>
        </div>
        <button className="finally_btn" onClick={this.onClickCharge}>
          <strong>注文をする</strong>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceInfo);
