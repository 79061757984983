import React, { Component, Fragment } from "react";
import "./css/DetailContent.css";
import { Grid, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Player from "react-lazy-youtube";

class MamariDetailContent extends Component {
  render() {
    const videoIds = {
      0: "1kauK0BCuG4",
      // 3: "mXqk5NLLHkk",
      // 4: "vJPeRIRgSXY?t=5",
      // 5: "jSsjP01yn30",
    };
    return (
      <Grid className="detail">
        <Row>
          <Col xs={12}>
            {this.props.description_image &&
              this.props.description_image.large.map((image, i) => {
                if (i in videoIds) {
                  return (
                    <Fragment key={"fragment" + i}>
                      <div className="description-img">
                        <img src={image} alt="description" />
                      </div>

                      <Player
                        id={videoIds[i]}
                        styles={tvWrapper}
                        imageSize="maxresdefault"
                      />
                    </Fragment>
                  );
                }
                return (
                  <div className="description-img" key={"description_img" + i}>
                    <img src={image} alt="description" />
                  </div>
                );
              })}
          </Col>
        </Row>
      </Grid>
    );
  }
}

MamariDetailContent.propTypes = {
  description_image: PropTypes.shape({
    large: PropTypes.array,
    medium: PropTypes.array,
    small: PropTypes.array,
  }),
  description: PropTypes.string,
};

const tvWrapper = {
  maxWidth: "600px",
  maxHeight: "338px",
  width: "90vw",
  height: "50.85vw",
  margin: "0 auto",
};

export default MamariDetailContent;
