import React from 'react'
import {Row, Col, Grid} from 'react-bootstrap'
import './css/Mypage.css'
import MypageSidebar from './MypageSidebar'
import MypageCardStripe from './MypageCardStripe'
import {StripeProvider, Elements} from 'react-stripe-elements';
import {connect} from 'react-redux'
import {STRIPE_KEY} from "../../utils/url";

//import { Link } from 'react-router-dom';
//import axios from 'axios'
//import url from '../../actions/url'
//import Modal from 'react-modal';
//import check_img from './checked.png'

class MypageCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address_modal_open: false,
      address_list: [],

      address_id: null,
      last_name: "",
      first_name: "",
      furigana_sei: "",
      furigana_mei: "",
      address_zip: "",
      address_state: "",
      address_city: "",
      address_line: "",
      address_line2: "",
      phone: "",

      selected_address: null,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClickBlackBox = () => {
    document.querySelector("#sidebar-left").style.left = "";
    document.querySelector("#black_box").style.backgroundColor = "";
    document.querySelector("#black_box").style.zIndex = "";
    document.querySelector(".side-toggle").dataset.clicked = "false";
  };

  render() {
    return (
      <div id="mypage_div">
        <Grid>
          <Row>
            <MypageSidebar jwt={this.props.jwt}/>
            <Col xs={12} sm={9} lg={10} className="purchase_container" id="mypage_content_div">
              <div id="black_box" className="visible-xs" onClick={this.handleClickBlackBox}>
              </div>
              <div>
                <span className="title">支払いの詳細</span>
                <br/>
                <span className="desc">
                    下記の情報を記入してください
                  </span>
                <hr/>
                <strong>現在の支払い方法の確認</strong>
                <StripeProvider apiKey={STRIPE_KEY}>
                  <Elements>
                    <MypageCardStripe
                      jwt={this.props.jwt}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(MypageCard);
