import React from "react";
import "./css/Detail.css";
import DetailCommentList from "./DetailCommentList";
import EdgeDetailInfo from "./EdgeDetailInfo";
import { connect } from "react-redux";
import Footer from "../../footer/Footer";
import EdgeDetailContent from "./EdgeDetailContent";
import EdgeKidsDetailContent from "./EdgeKidsDetailContent";
import AccessTerm from "./AccessTerm";
import KidsAccessTerm from "./KidsAccessTerm";
import Loading from "../../utils/Loading";
import axios from "axios";
import { STORE_API_URL, STORE_REST_PRODUCTS } from "../../utils/url";
import { LOG } from "../../utils/Log";

class EdgeDetail extends React.Component {
  constructor(props) {
    super(props);

    let product_id = this.props.match.params.type;
    if (this.props.match.params.type === "adult") {
      product_id = "183efde4-818b-5966-98e3-d0e47bf8a4cf"; // Edge
    } else if (this.props.match.params.type === "kids") {
      product_id = "faaf2f8d-cb96-5da0-bbf3-8128fd89b750"; // Edge Kids
    }

    this.state = {
      product: undefined,
      accessToggle: false,
      product_id: product_id,
      loading: true,
    };
  }

  handleOpenModal = (buy = false) => {
    this.setState({
      ...this.state,
      accessToggle: true,
      buy: buy,
    });

    LOG(this.detail_info);
  };

  handleCloseModal = () => {
    this.setState({
      ...this.state,
      accessToggle: false,
    });
  };

  handleCheckModal = () => {
    this.setState({
      ...this.state,
      accessToggle: false,
    });

    this.detail_info.onAddCart(this.state.buy);
  };

  componentWillMount() {
    LOG("componentWillMount");

    this.setState({
      loading: true,
    });
    let request_url =
      STORE_API_URL + STORE_REST_PRODUCTS + "/" + this.state.product_id;
    LOG("request_url : " + request_url);

    axios
      .get(request_url, {
        headers: { Authorization: "Bearer " + this.props.jwt },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          product: response.data.data,
          loading: false,
        });
        //trackEvent("PRODUCT", "Success: Load product detail " + this.state.product_id, "")
      })
      .catch((error) => {
        LOG(error);
        //trackEvent("PRODUCT", "Failed: Load product detail " + this.state.product_id, "Error: " + error)
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div>
        {/* <EdgeDetailInfo
          product={this.state.product}
          jwt={this.props.jwt}
          history={this.props.history}
          isAuthenticated={this.props.isAuthenticated}
          handleOpenModal={this.handleOpenModal}
          ref={(i) => {
            this.detail_info = i;
          }}
        /> */}

        {/* {this.props.match.params.type === "adult" && <EdgeDetailContent />}
        {this.props.match.params.type === "kids" && <EdgeKidsDetailContent />}

        <DetailCommentList
          product={this.state.product}
          isAuthenticated={this.props.isAuthenticated}
          jwt={this.props.jwt}
        /> */}
        <div>test</div>
        <div className="stop-info">こちらのサービスは販売を終了いたしました。新しいサービスで戻って参りますのでもうしばらくお待ちくださいませ。</div>
        <Footer marginTop={0} />

        {/* {this.props.match.params.type === "adult" && (
          <AccessTerm
            accessToggle={this.state.accessToggle}
            handleCloseModal={this.handleCloseModal}
            handleCheckModal={this.handleCheckModal}
          />
        )}

        {this.props.match.params.type === "kids" && (
          <KidsAccessTerm
            accessToggle={this.state.accessToggle}
            handleCloseModal={this.handleCloseModal}
            handleCheckModal={this.handleCheckModal}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps)(EdgeDetail);
