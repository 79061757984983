import React from "react";
import axios from "axios";
import {
  AUTH_LOGIN,
  STORE_API_URL,
  STORE_REST_CART,
  USERMODEL_URL,
} from "../../utils/url";
import "./css/DetailInfo.css";
import {
  Row,
  Col,
  Grid,
  DropdownButton,
  MenuItem,
  Glyphicon,
  Carousel,
} from "react-bootstrap";
import { LOG } from "../../utils/Log";
import { trackEvent } from "../../utils/tracking";
//import { connect } from 'react-redux'
//import PropTypes from 'prop-types'

function round10(num) {
  return Math.round(num / 1) * 1;
}
const TAX_RATE = 1.1;

const MUSIO_SET = [
  "ad8075f1-6b60-5891-aaca-7961f352fd30",
  "a169fc5e-986a-5816-8fa6-0e91b13e7428",
  "b47daab6-4fc1-565b-8818-eac897958f6d",
  "24fbe686-1759-5459-aaee-c0363f9a9151",
  "25ec2281-2fe8-5c59-a75f-b020a90c48e8",
  "92e54780-efb8-57d6-b8da-e7dd2b78859d",
  "9ce94cfe-b66b-5e7b-92df-bd154e02186a",
  "dd5fa5d2-0230-5459-bacb-d3307560730c",
  "ba4dc917-1905-5b94-b44b-8d30abc4edfa",
  "6978c3bf-653c-5701-89be-4f1eeb809a60",
  "285c06c8-0972-5c1a-8323-f40c46dcd041",
  "747ada1d-f83c-592c-858e-3da1a4c42b39",
];

class DetailInfo extends React.Component {
  constructor(props) {
    super(props);

    LOG("DetailInfo @ constructor");
    this.state = {
      selected_options: [],
      selected_picture: 0,
    };

    this.onOptionSelect = this.onOptionSelect.bind(this);
    this.onOptionUpdateAmount = this.onOptionUpdateAmount.bind(this);
    this.onOptionDelete = this.onOptionDelete.bind(this);
    this.onPictureSelect = this.onPictureSelect.bind(this);
  }

  onOptionSelect(eventKey) {
    LOG("DetailInfo @ onOptionSelect , eventKey : " + eventKey);

    let selected_options = this.state.selected_options;
    let now_option = this.props.product.option.items.find((e) => {
      return e.id === eventKey;
    });

    let is_resist = selected_options.find((e) => {
      return e.id === eventKey;
    });

    if (is_resist !== undefined) {
      return;
    }

    if (
      this.props.product.option.type === "single" &&
      selected_options.length > 0
    ) {
      this.onOptionDelete(selected_options[0].id);
    }

    now_option.amount = 1;
    selected_options.push(now_option);
    this.setState({
      ...this.state,
      selected_options: selected_options,
    });
  }

  onOptionUpdateAmount(eventKey, operator) {
    LOG(
      "DetailInfo @ onOptionUpdateAmount , eventKey : " +
        eventKey +
        " , operator : " +
        operator
    );

    if (MUSIO_SET.includes(this.props.product.id)) {
      return;
    }

    let selected_options = this.state.selected_options;
    let now_option_index = selected_options.findIndex((e) => {
      return e.id === eventKey;
    });

    if (now_option_index === -1) {
      return;
    }

    if (operator === "-") {
      if (selected_options[now_option_index].amount > 1) {
        selected_options[now_option_index].amount -= 1;
      }
    } else if (operator === "+") {
      selected_options[now_option_index].amount += 1;
    }

    this.setState({
      ...this.state,
      selected_options: selected_options,
    });
  }

  onOptionDelete(eventKey) {
    LOG("DetailInfo @ onOptionDelete , eventKey : " + eventKey);

    let selected_options = this.state.selected_options;
    let now_option_index = selected_options.findIndex((e) => {
      return e.id === eventKey;
    });
    selected_options.splice(now_option_index, 1);

    this.setState({
      ...this.state,
      selected_options: selected_options,
    });
  }

  onPictureSelect(key) {
    LOG("DetailInfo @ onPictureSelect , key : " + key);

    this.setState({
      ...this.state,
      selected_picture: key,
    });
  }

  onAddCart = (buy = false) => {
    LOG("DetailInfo @ onAddCart , buy : " + buy);

    if (!this.props.isAuthenticated) {
      alert("Please Login");
      trackEvent("PRODUCT", "Error: Add to cart", "User is not logged in");
      window.location =
        USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
      return;
    }
    if (this.props.product.option && this.state.selected_options.length <= 0) {
      alert("Please Select Options");
      trackEvent("PRODUCT", "Error: Add to cart", "Options are not selected");
      return;
    }

    let option = [];
    this.state.selected_options.map((item) => {
      let option_obj = {};
      option_obj["id"] = item["id"];
      option_obj["count"] = item["amount"];
      option_obj["name"] = item["name"];
      option_obj["price"] = item["price"];
      return option.push(option_obj);
    });

    /*
    let product_ids
    if(this.props.product.freebie != null && this.props.product.freebie != undefined){
      product_ids = [this.props.product.id, ...this.props.product.freebie]
    }
    else{
      product_ids = [this.props.product.id]
    }
    */

    LOG("jwt : " + this.props.jwt);

    axios
      .post(
        STORE_API_URL + STORE_REST_CART,
        {
          product_id: this.props.product.id,
          option: option,
        },
        {
          headers: {
            // "Authorization": "Bearer " + this.props.jwt
            Authorization:
              "Bearer " +
              this.props.jwt,
          },
        }
      )
      .then(() => {
        LOG("DetailInfo @ post onAddCart, buy : " + buy);

        if (buy) {
          trackEvent("PRODUCT", "Buy: " + this.props.product.id, "");
          this.props.history.push("/cart");
        } else {
          trackEvent("PRODUCT", "Add to cart: " + this.props.product.id, "");
          alert("Success, add in Cart");
        }
      })
      .catch((error) => {
        LOG(error);
        trackEvent(
          "PRODUCT",
          "Error: Add to cart " + this.props.product.id,
          "Error: " + error
        );
      });
  };

  render() {
    LOG("DetailInfo @ render");

    return (
      <Grid className="detail">
        <Row>
          <Col className="images" md={2} xsHidden={true} smHidden={true}>
            {this.props.product.images.map((picture, i) => {
              return (
                <img
                  key={"picture" + i}
                  alt="product_image"
                  onClick={() => this.onPictureSelect(i)}
                  className={
                    this.state.selected_picture === i ? "select" : "default"
                  }
                  src={picture}
                />
              );
            })}
          </Col>
          <Col className="main_image" md={5} xsHidden={true} smHidden={true}>
            <img
              alt="product_select_image"
              src={this.props.product.images[this.state.selected_picture]}
            />
          </Col>
          <Col
            className="image_carousel"
            xs={12}
            mdHidden={true}
            lgHidden={true}
          >
            <Carousel interval={1000000}>
              {this.props.product.images.map((picture, i) => {
                return (
                  <Carousel.Item key={"Carousel" + i}>
                    <img alt="product_image" src={picture} />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Col>
          {LOG(
            "this.props.product.badges : " +
              JSON.stringify(this.props.product.badges)
          )}
          <Col key={this.props.product.badges} className="info" md={5} xs={12}>
            {this.props.product.badges.map((badge, i) => {
              return (
                <div className="best" key={"badge" + i}>
                  {badge}
                </div>
              );
            })}

            <div className="title">{this.props.product.name}</div>
            <div className="tag_wrapper">
              {this.props.product.tags.map((tag) => {
                return <div className="tag">{tag}</div>;
              })}
            </div>
            <div className="price">
              {this.props.product.sale_price && (
                <span className="product_sale_price">
                  <s>
                    ¥
                    {round10(
                      this.props.product.price * TAX_RATE
                    ).toLocaleString()}
                  </s>
                </span>
              )}
              {this.props.product.sale_price ? (
                <span>
                  ¥
                  {round10(
                    this.props.product.sale_price * TAX_RATE
                  ).toLocaleString()}
                </span>
              ) : (
                <span>
                  ¥
                  {round10(
                    this.props.product.price * TAX_RATE
                  ).toLocaleString()}
                </span>
              )}
              {this.props.product.name == "Musio S" ? (
                <span> (税込み)</span>
              ) : (
                <span></span>
              )}
            </div>
            {this.props.product.option ? (
              <DropdownButton
                title="商品のオプションを選択してください。"
                id="dropdown-size-medium"
                onSelect={this.onOptionSelect}
              >
                {this.props.product.option.items.map((option, i) => {
                  return (
                    <MenuItem key={i} eventKey={option.id}>
                      {`${option.name} / +${round10(
                        option.price * TAX_RATE
                      ).toLocaleString()}¥`}
                    </MenuItem>
                  );
                })}
              </DropdownButton>
            ) : (
              <div />
            )}
            {this.state.selected_options.map((option) => {
              return (
                <div className="option_wrapper">
                  <div className="option">
                    {`${option.name} / +${round10(
                      option.price * TAX_RATE
                    ).toLocaleString()}¥`}
                  </div>
                  <div className="amount">
                    <div
                      className="minus"
                      onClick={() => this.onOptionUpdateAmount(option.id, "-")}
                      style={
                        option.amount === 1
                          ? {
                              backgroundColor: "#DDDDDD",
                              border: "solid 1px #DDDDDD",
                            }
                          : {}
                      }
                    >
                      <Glyphicon glyph="glyphicon glyphicon-minus" />
                    </div>
                    <div className="number">
                      <span>{option.amount}</span>
                    </div>
                    <div
                      className="plus"
                      onClick={() => this.onOptionUpdateAmount(option.id, "+")}
                    >
                      <Glyphicon glyph="glyphicon glyphicon-plus" />
                    </div>
                  </div>
                  <div
                    className="remove"
                    onClick={() => this.onOptionDelete(option.id)}
                  >
                    <Glyphicon glyph="glyphicon glyphicon-remove" />
                  </div>
                </div>
              );
            })}

            <div className="etc">
              <div className="blog">Blog</div>
              <div
                className="shop"
                onClick={() => {
                  if (this.props.product.state === "default") this.onAddCart();
                }}
                style={
                  this.props.product.state === "soldout" ||
                  this.props.product.state === "prepare"
                    ? { cursor: "not-allowed" }
                    : {}
                }
              >
                <Glyphicon glyph="glyphicon glyphicon-shopping-cart" />
              </div>
              <div
                className="buy"
                style={
                  this.props.product.state === "soldout" ||
                  this.props.product.state === "prepare"
                    ? { cursor: "not-allowed" }
                    : {}
                }
                onClick={() => {
                  if (this.props.product.state === "default")
                    this.onAddCart(true);
                }}
              >
                {this.props.product.state === "soldout" && (
                  <span>Sold Out</span>
                )}
                {this.props.product.state === "default" && <span>Buy</span>}
                {this.props.product.state === "prepare" && (
                  <span>Coming Soon</span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default DetailInfo;
