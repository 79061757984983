const HeaderInfo = {

  store: [
    {
      link: '/home',
      name: "Home"
    },
    {
      link: '/contents',
      name: "Contents"
    },
    {
      link: '/service',
      name: "Service"
    },
    {
      link: '/goods',
      name: "Goods"
    }
  ],

  storejapan: [
    {
      link: '/musios/home',
      name: "Home"
    },
  ],

  activation: [
    {
      link: '/sbactivation',
      name: "Activation"
    },
  ]
};


/*

story: [
  {
    link: '#',
    name: "SHome"
  },
  {
    link: '#',
    name: "SMusio&Friends"
  },
  {
    link: '#',
    name: "SContents"
  },
  {
    link: '#',
    name: "SService"
  },
  {
    link: '#',
    name: "SGoods"
  }
],

feature: [
  {
    link: '#',
    name: "FHome"
  },
  {
    link: '#',
    name: "FMusio&Friends"
  },
  {
    link: '#',
    name: "FContents"
  },
  {
    link: '#',
    name: "FService"
  },
  {
    link: '#',
    name: "FGoods"
  }
],

blog: [
  {
    link: '#',
    name: "BHome"
  },
  {
    link: '#',
    name: "BMusio&Friends"
  },
  {
    link: '#',
    name: "BContents"
  },
  {
    link: '#',
    name: "BService"
  },
  {
    link: '#',
    name: "BGoods"
  }
],

support: [
  {
    link: '#',
    name: "SHome"
  },
  {
    link: '#',
    name: "SMusio&Friends"
  },
  {
    link: '#',
    name: "SContents"
  },
  {
    link: '#',
    name: "SService"
  },
  {
    link: '#',
    name: "SGoods"
  }
],

*/
export default HeaderInfo
