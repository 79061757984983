import "./css/DesktopHeader.css";
//import PropTypes from 'prop-types'
import React from "react";

import CartIcon from "./img/home_gnb_btn_cart.png";
import EdgeIcon from "./img/home_gnb_btn_edge.png";
import MyPageIcon from "./img/home_gnb_btn_my.png";
import Logo from "./img/logo.png";

import { Row, Col, Grid } from "react-bootstrap";

import HeaderInfo from "./HeaderInfo.js";
import { Link, NavLink } from "react-router-dom";

import { connect } from "react-redux";
import { logout } from "../actions/auth";

import { withCookies } from "react-cookie";
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SIGN_UP,
  THE_MUSIO_URL,
  USERMODEL_URL,
  STORE_API_URL,
  STORE_REST_CART,
} from "../utils/url";
import axios from 'axios'
import {LOG} from "../utils/Log";

import { trackEvent } from "../utils/tracking";

class DesktopHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: HeaderInfo["store"],
      itemsJapan: HeaderInfo["storejapan"],
      productIdExists: false,
      targetInclude: false,
      isOpen: false,
    };
    this.logoutClick = this.logoutClick.bind(this);
    this.dropdownButton = React.createRef();
  }

  headerDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  openDropdown = () => {
    this.setState({ isOpen: true});
  }

  closeDropdown = () => {
    this.setState({ isOpen: false});
  }

  logoutClick() {
    trackEvent("HEADER", "Logout", "");
    this.props.dispatch_logout();
    //window.location.reload();
  }

  componentDidUpdate() {
    if (this.props.jwt != undefined) {
      axios.get(STORE_API_URL + STORE_REST_CART, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
      })
      .then((response) => {
        for (let count = 0; count < response.data.data.length; count++) {
          if (response.data.data[count].product_id === "f7d73106-0f46-525f-b2a3-4f5e7b061293") {
            this.setState({ productIdExists: true });
            break;
          }
          else
            this.setState({ productIdExists: false});
            break;
        }
      })
      .catch((error) => {
        LOG(error);
      })
    }
  }

  render() {
    const { isOpen, productIdExists } = this.state;
    
    return (
      <div className="header">
        <div className="first_row">
          <Grid style={{ position: "relative" }}>
            <Row>
              <Col className="logo">
              {(productIdExists === true && window.location.pathname.includes('cart'))
                  || window.location.pathname.startsWith('/musios')  
                  || window.location.pathname.includes('f7d73106-0f46-525f-b2a3-4f5e7b061293')
              ? 

              <div>
                <a href={"/musios"} onClick={() => {trackEvent("HEADER", "Navigate to: musios", "")}}>
                  <img alt="logo" id="logo" src={Logo}/>
                </a>
            </div> :
                <a  href={THE_MUSIO_URL} onClick={() => {trackEvent("HEADER", "Navigate to: themusio.com", "")}}>
                  <img alt="logo" id="logo" src={Logo}/>
                </a> 
                }
                
              </Col>
              <Col className="icons">
                {/* <a
                  href="https://edge.themusio.com/"
                  onClick={() => {
                    trackEvent("HEADER", "Navigate to: Edge", "");
                  }}
                >
                  <img alt="edge" src={EdgeIcon} />
                </a> */}
                <div className="mypage">
                  <img alt="mypage" src={MyPageIcon} />
                  {this.props.isAuthenticated && (
                    <div className="drawer">
                      <a
                        href={
                          THE_MUSIO_URL +
                          AUTH_LOGOUT +
                          "?next=" +
                          window.location.href
                        }
                      >
                        <div
                          className="list"
                          onClick={this.logoutClick}
                          style={{ cursor: "pointer" }}
                        >
                          Logout
                        </div>
                      </a>
                      <a href={THE_MUSIO_URL + "/mypage/info"}>
                        <div className="list">My page</div>
                      </a>
                    </div>
                  )}
                  {!this.props.isAuthenticated && (
                    <div className="drawer">
                      <a
                        href={
                          USERMODEL_URL +
                          AUTH_LOGIN +
                          "?next=" +
                          window.location.href
                        }
                        onClick={() => {
                          trackEvent("HEADER", "Login", "");
                        }}
                      >
                        <div className="list">Login</div>
                      </a>
                      <a
                        href={
                          USERMODEL_URL +
                          AUTH_SIGN_UP +
                          "?next=" +
                          window.location.href
                        }
                        onClick={() => {
                          trackEvent("HEADER", "Sign Up", "");
                        }}
                      >
                        <div>Sign Up</div>
                      </a>
                    </div>
                    
                  )}
                  
                </div>
                {window.location.pathname.startsWith('/musios') ? 
                (<Link
                  to="/musios/cart"
                  onClick={() => {
                    trackEvent("HEADER", "Navigate to: Cart", "");
                  }}
                >
                  <img alt="cart" src={CartIcon} />
                </Link>)
                :
                (<Link
                  to="/cart"
                  onClick={() => {
                    trackEvent("HEADER", "Navigate to: Cart", "");
                  }}
                >
                  <img alt="cart" src={CartIcon} />
                </Link>)
                }
              </Col>
              <Col className="menu_wrapper">
                {(productIdExists === true && window.location.pathname.includes('cart'))
                  || window.location.pathname.startsWith('/musios')  
                  || window.location.pathname.includes('f7d73106-0f46-525f-b2a3-4f5e7b061293')
                  ? this.state.itemsJapan.map((item, index) => {
                  let active = "";
                  if (item.name === "Home" && window.location.pathname === "/")
                    active = "active";
                  return (
                    <NavLink
                      to={item.link}
                      key={"header" + index}
                      className={active}
                      onClick={() => {
                        trackEvent("HEADER", "Navigate to: " + item.name, "");
                      }}
                    >
                      <div className="menu">{item.name}</div>
                    </NavLink>
                  );
                })
              : this.state.items.map((item, index) => {
                let active = "";
                if (item.name === "Home" && window.location.pathname === "/")
                  active = "active";
                return (
                  <NavLink
                    to={item.link}
                    key={"header" + index}
                    className={active}
                    onClick={() => {
                      trackEvent("HEADER", "Navigate to: " + item.name, "");
                    }}
                  >
                    <div className="menu">{item.name}</div>
                  </NavLink>
                );
              })}
          
              </Col>
            </Row>
          </Grid>
        </div>
        <Col className="dropdown__banner">
          <div className="menu-section landing" onMouseLeave={this.closeDropdown}>
            <div 
              onClick={this.toggleDropdown}
              onMouseOver={this.openDropdown}
              onFocus={this.openDropdown}
              ref={(button) => {
                this.dropdownButton = button;
              }}
            >
              {window.location.pathname.startsWith('/musios') 
                ? <span className="menu-text">▼ 日本語版Musio</span> : <span className="menu-text">▼ 英語版Musioをお持ちの方</span>}
            </div>
            <div className="move-landing-page" style={{ display: isOpen ? "block" : "none" }}>
              <ul 
                className="landing-page" 
              >
                <li><Link to = "/">▼ 英語版Musioをお持ちの方</Link></li>
                <li><Link to = "/musios">▼ 日本語版Musio</Link></li>
              </ul>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch_logout: () => dispatch(logout()),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(DesktopHeader)
);
