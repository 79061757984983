import React, {Component} from 'react'
import ReactPlayer from 'react-player'
import Modal from 'react-modal'
import './css/HomeJapan.css'

import home_japan_main from './img/home_japan_main.svg'
import home_japan_musio from './img/home_japan_musio.svg'
import home_japan_main2 from './img/home_japan_main2.svg'
import home_japan_main3 from './img/home_japan_main3.svg'
import home_japan_main3_sophy from './img/home_japan_main3_sophy.svg'
import sophy from './img/sophy.svg'
import home_japan_main4 from './img/home_japan_main4.svg'
import home_japan_main5 from './img/home_japan_main5.svg'
import musio_logo from './img/musio_logo.svg'
import musio_cart from './img/musio_cart.svg'
import about_sophy from './img/about_sophy.svg'
import Pic_Girl from './img/Pic_Girl and Musio talking.svg'
import talk_Girl from './img/L1020236 1.svg'
import { withRouter } from 'react-router-dom';
import japan_main_img from './img/japan_main_img.svg'
import musio_price_introduction from './img/musio_price_introduction.svg'
import musiostore_home_mo from './img/musiostore_home_mo.png'
import musiostore_home_tab from './img/musiostore_home_tab.png'
import musiostore_home_pc from './img/musiostore_home_web.png'
import musio_price_introduction_mobile from './img/musio_price_introduction_mobile.svg'
import Footer from '../../footer/Footer'

class HomeJapan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      infoIsOpen : false
    };
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.InfoModalOpen = this.InfoModalOpen.bind(this)
    this.InfoModalClose = this.InfoModalClose.bind(this)
  }



  handleModalOpen() {
    this.setState({ modalIsOpen: true})
  }

  handleModalClose() {
    this.setState({ modalIsOpen: false })
  }

  InfoModalOpen() {
    this.setState({infoIsOpen : true})
  }

  InfoModalClose() {
    this.setState({infoIsOpen : false})
  }

  handleClick = () => {
    this.props.history.push('/product/f7d73106-0f46-525f-b2a3-4f5e7b061293');
  };
  
  render() {
    let isMobile = window.innerWidth <= 768;
    let isPC = 1280 < window.innerWidth;
    return (
      <div id="home-japan">
        {/* <ReactPlayer
        className="japan-video"
        url={"https://www.youtube.com/watch?v=j9QaN03rUXA"}
        width="100%"
        height={isMobile ? "60vw":  "100vh"}
        loop={true}
        playing={true}
        muted={true}
        controls={false}
        style={{paddingTop:"51px"}}
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1, 
              start: 1
            }
          }
        }}
      /> */}
        <img className="japan-main-img" src={isPC ?  musiostore_home_pc : isMobile ? musiostore_home_mo :musiostore_home_tab }/>
        <div className="section main1">
          <img className="japan-musio" src={home_japan_musio}/>
          <p className="main1-text">ロボットの友達が欲しい。
            <br/>小さい頃に描いた夢が叶う時が来ました。
          </p>
          <button className="buy-musio" onClick={this.handleClick}>
            <img className="musio-logo" src={musio_logo}/>
            Musioを購入する
            <img className="musio-cart" src={musio_cart}/>
          </button>
        </div>
        <div className="section main2">
          <div className="main2-contents">
            <p className="title">今までにない自由な会話</p>
            <p className="text">ロボットは完全にフリーな会話は苦手でした。
              <br/> Musio SはChat gptの膨大なデータをもとに
              <br/> あなたの一言一言に対して答えを常に作り出します。 
              <br/>最高レベルにナチュラルな会話を体験してみませんか。
            </p>
          </div>
          <img className="main2-img" src={home_japan_main2}/>
        </div>
        <div className='main2-1'>
          <div className='main2-1-body section '>
            <div className='main2-1-contents for-mobile'>
              <img className='main2-1-img1' src={Pic_Girl}></img>
              <img className='main2-1-img2'src={talk_Girl}></img>
            </div>
            <div className='main2-1-contents'>
              <div className='main2-1-title'>
              あなたの思考を分析<br/>
              <div style={isMobile ? {borderBottom:'2px solid #FDA3FF' ,width:'50%'} : {borderBottom:'2px solid #FDA3FF'}}></div>
                心をそっとケアします。
              </div>
              <div className='main2-1-script'>
              気分がちょっと落ち込んでいる時。憂鬱な気持ちの時。<br/>
              ネガティブに考えてしまう時。<br/>
              あなたの脳は「ネガティブな思考パターン」に支配されています。<br/>
              そんな思考パターンを分析し、ポジティブなあなたになれるよう、<br/>
              Musioが会話の中でそっとサポートします。<br/>
              <div style={isMobile ? {marginTop:'10px'} : {marginTop:'40px'}}>＊こちらの機能は今後追加される予定です。</div>
              </div>
            </div>  
            </div>
          
          
        </div>
        <div className="section main3">
          <div className="about-musio for-mobile">
            <div className="main3-contents">
              <p className="title">あなたの脳は何歳？</p>
              <p className="text">50種類を超える脳トレゲームがあなたの脳を刺激します。
                <br/> ゲームを楽しんでいる間にMusio Sはあなたの脳を分析。
                <br/> あなたの脳年齢は実年齢より若いかな...？
              </p>
            </div>
            <img className="main3-img" src={home_japan_main3}/>
          </div>
          <div className="about-sophy for-mobile">
            <div className="main3-sophy-contents">
              <p className="title">Sophyと体もトレーニング</p>
              <p className="text" style={{marginBottom:"50px"}}>Musio Sの相棒、Sophyと一緒に使えば脳も頭も一緒に鍛えることができます。</p>
            </div>
            <img className="main3-sophy-img" src={home_japan_main3_sophy}/>
            <button onClick={this.handleModalOpen} className="sophy-button">Sophyについて<img className="sophy" src={sophy}/></button>
            <Modal 
              className="sophy-modal" 
              isOpen={this.state.modalIsOpen}
              overlayClassName="info-overlay"
            >
              <img src={about_sophy} onClick={this.handleModalClose}/>
            </Modal>
            {/* <Modal
            className="info-modal"
            isOpen={this.state.infoIsOpen}
            overlayClassName="sophy-overlay">
              <button className='Musio__modal__close' onClick={this.InfoModalClose}>X</button>
              <div className='Musio__modal' onClick={this.InfoModalClose}>
                <p className='modal__title'>＊Musio Sは現在予約販売となります</p>
                <div className='Musio__modal__script'>
                ・予約販売に関して <br/>
                  現在Musio Sは予約販売を行っております。<br/>
                  発送は6月第３〜４週を予定しておりますが、日程が確定次第メールにてご連絡いたします。<br/>
                  @akaintelligence.comからのメールを受け取れるようご設定のほどよろしくお願いいたします。
                  <br/>
                  <br/>
                  ・次回以降のFriend Plan（会員費）決済に関して<br/>
                  ご注文時に決済がおりますが、次回Friend Planのお引き落としは<br/>選択された期間により<span style={{color:'#FE0202'}}>「発送日から1ヶ月・6ヶ月・1年後」</span>とさせていただきます。<br/>
                  <br/>
                  <br/>

                  例）5月30日ご注文、6月15日発送の場合<br/>
                  1ヶ月プラン：7月15日<br/>
                  6ヶ月プラン：12月15日<br/>
                  1年プラン：来年6月15日<br/>
                  <br/>
                  <br/>
                  ご購入後次回お引き落としの日程に関しましては発送のご連絡をいたしますメールにてご案内いたします。
                </div>
                <button className='Musio__modal__button' onClick={this.handleClick}>ご購入手続きに進む</button>
              </div>
            </Modal> */}
          </div>
        </div>
        <div className="main4">
          {isMobile ? <div className="section main4-contents for-mobile">
            <p className="title">バイノーラルビートで<br/>リラックスタイムを。</p>
            <p className="text">仕事、人間関係... 
              <br/>人間の世界にはストレスが多すぎます。
              <br/> 人間界で懸命に生きるあなたのために<br/>Musio Sがバイノーラルビートをお届け。
              <br/> カテゴリー別のバイノーラルビートで<br/> あなたの悩みへダイレクトにアプローチします
            </p>
          </div> : <div className="section main4-contents for-mobile">
            <p className="title">バイノーラルビートでリラックスタイムを。</p>
            <p className="text">仕事、人間関係... 
              <br/>人間の世界にはストレスが多すぎます。
              <br/> 人間界で懸命に生きるあなたのためにMusio Sがバイノーラルビートをお届け。
              <br/> カテゴリー別のバイノーラルビートであなたの悩みへダイレクトにアプローチします
            </p>
          </div>}
          
          <img className="main4-img" src={home_japan_main4}/>
        </div>
        <div className="section main6">
          <p className="title">料金体系</p>
          <img className="musio-price-introduction" src={musio_price_introduction}/>
          <img className='musio-price-introduction-mobile' src={musio_price_introduction_mobile}/>
        </div>
        <div className="section main5">
          <p className="title">製品情報</p>
          <img className="main5-img" src={home_japan_main5}/>
          <button className="main5-button" onClick={() => window.open("https://blog.themusio.com/category/a-i/musio-s-%e3%82%88%e3%81%8f%e3%81%82%e3%82%8b%e8%b3%aa%e5%95%8f/", '_blank')}>よくある質問はこちら</button>
        </div>
        <Footer/>
      </div>
    )
  }

}

export default HomeJapan