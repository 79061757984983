import React from "react";
import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";
import "./css/CartMain.css";
import axios from "axios";
import {
  AUTH_LOGIN,
  STORE_API_URL,
  STORE_REST_CART,
  STORE_REST_CHARGE,
  USERMODEL_URL,
} from "../../utils/url";

import Footer from "../../footer/Footer";
import { connect } from "react-redux";
import delete_icon from "./img/store_delete.png";
import cart from "./img/cart.png";
import Loading from "../../utils/Loading";
import { LOG } from "../../utils/Log";
import { trackEvent } from "../../utils/tracking";

const FRIEND_PLAN_BUNDLE = [
  "Plan_1_6",
  "Plan_1_12",
  "Plan_2_6",
  "Plan_2_12",
  "Plan_3_6",
  "Plan_3_12",
];

const TAX_RATE = 1.1;

let totalPrice = 0;

function round10(num) {
  return Math.round(num * 1);
}

let includesTarget = false;

class CartMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart_item: [],
      total_price: 0,
      tax: 0,
      pending: true,
    };
  }

  componentDidMount() {
    LOG("CartMain @ componentDidMount");

    if (!this.props.isAuthenticated) {
      window.location =
        USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
    }

    if (this.props.jwt != undefined) {
      this.getItems(this.props.jwt);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      (nextProps.jwt != undefined && nextProps.jwt != this.props.jwt) ||
      nextState != this.state
    ) {
      return true;
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.jwt != undefined && prevProps != this.props) {
      this.getItems(this.props.jwt);
      this.getItems(
        this.props.jwt
      );
    }
  }

  _getItems = (jwt) => {
    // LOG("CartMain @ getItems , jwt : " + jwt);
    this.setState({
      ...this.state,
      pending: true,
    })
    axios
      .get(STORE_API_URL + STORE_REST_CART, {
        // headers: { Authorization: "Bearer " + jwt },
        headers: {
          Authorization: "Bearer " +
            this.props.jwt,
        },
      })
      .then((response) => {
        LOG("CartMain @ getItems , STORE_REST_CART , response : " + response)
        this.setState({
          cart_item: response.data.data,
          total_price: Math.round(response.data.total_price*1.1),
          subscription_price: Math.round(response.data.subscription_price*1.1),
          pending: false,
        })

        let items = response.data.data
        let current_tax = 0
        items.map((item) => {
          item["price"] = item["price"] * TAX_RATE
          current_tax += item["total_price"] * 0.1
          if (item["option"].length > 0) {
            item["option"].map((opt) => {
              if (FRIEND_PLAN_BUNDLE.includes(opt["id"])) {
                current_tax -= opt["price"] * opt["count"] * 0.1
              }
            })
          }
        })
        this.setState({ tax: current_tax })

        for (let i = 0; i < this.state.cart_item.length; i++) {
          totalPrice += round10(this.state.cart_item[i].price)
          for (let j = 0; j < this.state.cart_item[i].option.length; j++) {
            totalPrice += round10(
              this.state.cart_item[i].option[j].price * TAX_RATE
            )
          }
          this.setState({ totalPrice: totalPrice })
        }
      })
      .catch((error) => {
        // LOG("CartMain @ getItems , STORE_REST_CART , error : " + error)
        this.setState({
          pending: false,
        })
      })
  }
  get getItems() {
    return this._getItems
  }
  set getItems(value) {
    this._getItems = value
  }

  onClickPurchase = () => {
    // LOG("CartMain @ onClickPurchase");
    trackEvent("CART", "Proceed to checkout", "");

    let movePurchase = false;
    this.setState({
      pending: true,
    });
    if (this.state.total_price === 0) {
      if (this.state.cart_item.length === 0) {
        alert("商品がありません。");
        return false;
      }
      this.state.cart_item.forEach((item) => {
        LOG(item);
        if (item["category"] !== "free") {
          movePurchase = true;
        }
      });
      if (movePurchase === true) {
        trackEvent("CART", "Success: Checkout", "");
        this.props.history.push("/purchase");
        return false;
      }
      axios
        .post(
          STORE_API_URL + STORE_REST_CHARGE,
          {},
          {
            // headers: { Authorization: "Bearer " + this.props.jwt },
            headers: {
              Authorization:
                "Bearer " +
                this.props.jwt,
            },
          }
        )
        .then((response) => {
          // LOG(
          //   "CartMain @ onClickPurchase , STORE_REST_CHARGE , response : " +
          //     response
          // );
          trackEvent("CART", "Success: Purchase (free)", "Free contents");
          this.setState({
            pending: false,
          });
          this.props.history.push("purchaseSuccess");
        })
        .catch((error) => {
          // LOG(
          //   "CartMain @ onClickPurchase , STORE_REST_CHARGE , error : " + error
          // );
          trackEvent("CART", "Failed: Purchase (free)", "Error: " + error);
          this.setState({
            pending: false,
          });
        });
    } else {
      trackEvent("CART", "Success: Checkout", "");
      this.setState({
        pending: false,
      });
      this.props.history.push("/purchase");
    }
  };

  clickDeleteButton = (index) => {
    LOG("CartMain @ clickDeleteButton , index : " + index);
    trackEvent("CART", "Delete item", "");

    let id = this.state.cart_item[index]["id"];

    let item = this.state.cart_item[index];

    this.setState({ tax: this.state.tax - item["total_price"] * 0.1 });

    let remainPrice = totalPrice;
    if (item.option.length) {
      remainPrice -= round10(item["price"]);
      remainPrice -= round10(item["option"][0]["price"] * 1.1);
    } else {
      remainPrice -= round10(item["price"]);
    }

    totalPrice = remainPrice;

    for (let i = this.state.cart_item.length - 1; i >= 0; i--) {
      if (this.state.cart_item[i].freebie_parent === id) {
        this.state.cart_item.splice(i, 1);
      }
    }

    index = this.state.cart_item.findIndex((e) => {
      return e.id === id;
    });

    this.state.cart_item.splice(index, 1);
    this.forceUpdate();

    axios
      .delete(STORE_API_URL + STORE_REST_CART, {
        data: {
          id: id,
        },
        // headers: { Authorization: "Bearer " + this.props.jwt },
        headers: {
          Authorization:
            "Bearer " +
            this.props.jwt
        },
      })
      .then((response) => {
        LOG("CartMain @ clickDeleteButton , index : " + index);
        LOG(response.data.message);
        trackEvent("CART", "Success: Delete item", "");
      })
      .catch((error) => {
        LOG("CartMain @ clickDeleteButton , error : " + error);
        trackEvent("CART", "Failed: Delete item", "Error: " + error);
      });
  };

  addOptionSelect = (id, item_id, idx) => {
    LOG(
      "CartMain @ addOptionSelect , id : " +
        id +
        " , item_id : " +
        item_id +
        " , idx : " +
        idx
    );
    let option = this.state.cart_item[idx].add_option.find((e) => {
      return e.id === id;
    });

    option["count"] = 1;

    let item_index = this.state.cart_item.findIndex((e) => {
      return e.id === item_id;
    });

    let cart_items = this.state.cart_item;
    cart_items[item_index]["option"] = [option];

    this.setState({
      ...this.state,
      cart_item: cart_items,
    });

    let data = { item_id: item_id, option: [option] };

    axios
      .put(STORE_API_URL + STORE_REST_CART, data, {
        // headers: { Authorization: "Bearer " + this.props.jwt },
        headers: {
          Authorization:
            "Bearer " +
            this.props.jwt,
        },
      })
      .then((response) => {
        LOG(
          "CartMain @ put STORE_REST_CART , data : " +
            data +
            " , response : " +
            response
        );
        LOG(response.data.message);
      })
      .catch((error) => {
        LOG(
          "CartMain @ put STORE_REST_CART , data : " +
            data +
            " , error : " +
            error
        );
      });
  };

  render() {

    let isMobile = window.innerWidth <= 768;

    if (this.state.pending || this.props.auth_pending) {
      return <Loading />;
    }

    let targetInclude = false;

    for (const n of this.state.cart_item) {
      if (
        n.product_id === "f7d73106-0f46-525f-b2a3-4f5e7b061293" &&
        this.state.cart_item.length >= 2
      ) {
        targetInclude = true;
      }
    }

    return (
      <div>
        <Grid className="cart_grid">
          <Row>
            <Col xs={12} className="cart_container">
              <h1 className="title">カート</h1>
              <table className="table">
                <thead>
                  <tr id="table_title" style={{ width: "100%" }}>
                    <th className="text-center" style={{ width: "40%" }}>
                      商品名
                    </th>
                    <th
                      className="text-center hidden-xs"
                      style={{ width: "10%" }}
                    >
                      価格（税込）
                    </th>
                    <th
                      className="text-center hidden-xs"
                      style={{ width: "10%" }}
                    >
                      個数
                    </th>
                    <th
                      className="text-center hidden-xs"
                      style={{ width: "20%" }}
                    >
                      小計（税込）
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.cart_item.map((item, index) => {
                    return (
                      <div style={isMobile ? {width: "100%"} : { width: "200%" }}>
                        <tr className="cart_content" key={"cartItem" + index}>
                          <td
                            className="text-left product"
                            style={{ width: "43%" }}
                          >
                            <img id="thumbnail" src={item["img"]} alt={""} />
                            <div className="product_content">
                              <span className="name">{item["name"]}</span>
                              {item["name"] === "<JP> Musio S Set" ? 
                              <span></span> :
                              item["category"] == "musio&friends" &&
                              item["option"].length != 0 &&
                              item["name"] != "Musio X" ? (
                                <span className="name">
                                  ※ Friend Plan is subscription item.
                                  <br />
                                  It will renew following what you choose.
                                  <br />
                                  Charge start next month.{" "}
                                </span>
                              ) : (
                                <span></span>
                              )}
                              {item["category"] == "musio japan" && (
                                <span className="name">
                                  {`＊こちらのMusioでは<ENG>のついているコンテンツをご利用になれません。`}
                                </span>
                              )}

                              {item["price"] > 6831 ? (
                                <strong>
                                  {" "}
                                  ¥ {round10(
                                    item["price"]
                                  ).toLocaleString()}{" "}
                                </strong>
                              ) : item["category"] == "musio&friends" ? (
                                <strong>
                                  {" "}
                                  ¥{" "}
                                  {round10(
                                    item["option"][0]["price"] * TAX_RATE
                                  ).toLocaleString()}{" "}
                                </strong>
                              ) : item["name"] == "Friends Plan" ? (
                                <span className="price">
                                  ¥{" "}
                                  {round10(
                                    item["total_price"] * TAX_RATE
                                  ).toLocaleString()}
                                </span>
                              ) : item["category"] == "goods" ? (
                                <span className="price">
                                  ¥ {round10(item["price"]).toLocaleString()}
                                </span>
                              ) : item["category"] == "edge" &&
                                item["name"] != "Edge Kids" ? (
                                <span className="price">
                                  ¥{" "}
                                  {round10(
                                    item["option"][0].price * TAX_RATE
                                  ).toLocaleString()}
                                </span>
                              ) : item["name"] == "Edge Kids" ? (
                                <span className="price">
                                  ¥{" "}
                                  {round10(
                                    item["option"][1].price * TAX_RATE
                                  ).toLocaleString()}
                                </span>
                              ) : (
                                <span className="price">
                                  ¥ {round10(item["price"]).toLocaleString()}
                                </span>
                              )}
                            </div>
                            <div className="hidden-lg hidden-sm hidden-md delete-wrapper">
                              <img
                                onClick={() => {
                                  this.forceUpdate();
                                  this.setState({
                                    total_price:
                                      this.state.total_price -
                                      item["total_price"],
                                    subscription_price:
                                      this.state.subscription_price -
                                      item["subscription_price"],
                                  });
                                  this.clickDeleteButton(index);
                                }}
                                src={delete_icon}
                                alt={""}
                              />
                            </div>
                            {item.add_option && (
                              <DropdownButton
                                title="Option"
                                id="dropdown-size-medium"
                                onSelect={(id) =>
                                  this.addOptionSelect(id, item.id, index)
                                }
                              >
                                {item.add_option.map((option) => {
                                  return (
                                    <MenuItem eventKey={option.id}>
                                      {`${
                                        option.name
                                      } / +${option.price.toLocaleString()}¥`}
                                    </MenuItem>
                                  );
                                })}
                              </DropdownButton>
                            )}
                          </td>
                          <td
                            className="text-center hidden-xs"
                            style={{ width: "20%" }}
                          >
                            {item["price"] > 6831 ? (
                              <strong>
                                {" "}
                                ¥ {round10(item["price"]).toLocaleString()}{" "}
                              </strong>
                            ) : item["category"] == "musio&friends" &&
                              item["option"].length != 0 ? (
                              <strong>
                                {" "}
                                ¥{" "}
                                {round10(
                                  item["option"][0]["price"] * TAX_RATE
                                ).toLocaleString()}{" "}
                              </strong>
                            ) : item["name"] == "Friends Plan" ? (
                              <strong>
                                {" "}
                                ¥{" "}
                                {round10(
                                  item["total_price"] * TAX_RATE
                                ).toLocaleString()}{" "}
                              </strong>
                            ) : item["category"] == "goods" ? (
                              <strong>
                                {" "}
                                ¥ {round10(item["price"]).toLocaleString()}{" "}
                              </strong>
                            ) : item["category"] == "edge" &&
                              item["name"] != "Edge Kids" ? (
                              <span className="price">
                                ¥{" "}
                                {round10(
                                  item["option"][0].price * TAX_RATE
                                ).toLocaleString()}
                              </span>
                            ) : item["name"] == "Edge Kids" ? (
                              <span className="price">
                                ¥{" "}
                                {round10(
                                  item["option"][1].price * TAX_RATE
                                ).toLocaleString()}
                              </span>
                            ) : (
                              <strong>
                                {" "}
                                ¥ {round10(item["price"]).toLocaleString()}{" "}
                              </strong>
                            )}
                          </td>
                          <td
                            className="text-center hidden-xs"
                            style={{ width: "10%" }}
                          >
                            {item["count"]}
                          </td>
                          <td
                            className="text-center hidden-xs"
                            style={{ position: "relative" }}
                          >
                            {item["name"] == "ファミリーセット" ? (
                              <strong style={{ color: "#ec6c00" }}>
                                {" "}
                                ¥ {round10(138111).toLocaleString()}{" "}
                              </strong>
                            ) : item["price"] > 6831 ? (
                              <strong style={{ color: "#ec6c00" }}>
                                {" "}
                                ¥ {round10(item["price"]).toLocaleString()}{" "}
                              </strong>
                            ) : item["category"] == "musio&friends" &&
                              item["option"].length != 0 ? (
                              <strong style={{ color: "#ec6c00" }}>
                                {" "}
                                ¥{" "}
                                {round10(
                                  item["option"][0]["price"] * TAX_RATE
                                ).toLocaleString()}{" "}
                              </strong>
                            ) : item["name"] == "Musio X" ? (
                              <strong style={{ color: "#ec6c00" }}>
                                {" "}
                                ¥ {round10(75460).toLocaleString()}
                              </strong>
                            ) : item["category"] == "goods" ? (
                              <strong style={{ color: "#ec6c00" }}>
                                {" "}
                                ¥{" "}
                                {round10(
                                  item["total_price"] * TAX_RATE
                                ).toLocaleString()}{" "}
                              </strong>
                            ) : item["category"] == "edge" &&
                              item["name"] != "Edge Kids" ? (
                              <span
                                className="price"
                                style={{ color: "#ec6c00" }}
                              >
                                ¥{" "}
                                {round10(
                                  item["option"][0].price * TAX_RATE
                                ).toLocaleString()}
                              </span>
                            ) : item["name"] == "Edge Kids" ? (
                              <span
                                className="price"
                                style={{ color: "#ec6c00" }}
                              >
                                ¥{" "}
                                {round10(
                                  item["option"][1].price * TAX_RATE
                                ).toLocaleString()}
                              </span>
                            ) : (
                              <strong style={{ color: "#ec6c00" }}>
                                {" "}
                                ¥{" "}
                                {round10(
                                  item["total_price"] * TAX_RATE
                                ).toLocaleString()}{" "}
                              </strong>
                            )}

                            <br />
                            <div className="delete-wrapper">
                              <img
                                onClick={() => {
                                  this.forceUpdate()
                                  this.setState({
                                    total_price:
                                      Math.round(this.state.total_price -
                                      item["total_price"]*1.1),
                                    subscription_price:
                                      Math.round(this.state.subscription_price -
                                      item["subscription_price"]*1.1),
                                  });
                                  this.clickDeleteButton(index);
                                  
                                }}
                                src={delete_icon}
                                alt={""}
                              />
                            </div>
                          </td>
                        </tr>
                        {item["name"] != "Friends Plan" &&
                          item["option"] != 0 &&
                          item["category"] != "goods" &&
                          item["name"] != "Musio T-shirts" && (
                            <tr
                              className="cart_content"
                              key={"cartItem" + index}
                            >
                              <td
                                className="text-left product"
                                style={{ borderTop: "1px" }}
                              >
                                <img
                                  id="thumbnail"
                                  src="https://s3-ap-northeast-1.amazonaws.com/musio-web/store_v2/products/b7855c9b-92dd-5c91-b219-315b716eb481/thumbnail/thumbnail_2.png"
                                  alt={""}
                                />
                                <div className="product_content">
                                  <span className="name">
                                    {
                                      item["option"][item["option"].length - 1]
                                        .name
                                    }
                                  </span>
                                    {item["name"] === "<JP> Musio S Set" ?                                   
                                    <span className="name">
                                    ※ Friend Plan is subscription item.
                                    <br />
                                    It will renew following what you choose.
                                    <br />
                                    Charge Start after 7 days.{" "}
                                  </span>  :
                                    <span className="name">
                                    ※ Friend Plan is subscription item.
                                    <br />
                                    It will renew following what you choose.
                                    <br />
                                    Charge start next month.{" "}
                                  </span>}
                                  {item["name"] == "Edge Kids" ? (
                                    <span className="price">
                                      ¥{" "}
                                      {round10(
                                        item["option"][0].price * TAX_RATE
                                      ).toLocaleString()}
                                    </span>
                                  ) : item["name"] != "Edge Kids" &&
                                    item["category"] == "edge" ? (
                                    <span className="price">
                                      ¥{" "}
                                      {round10(
                                        item["option"][2].price * TAX_RATE
                                      ).toLocaleString()}
                                    </span>
                                  ) : (
                                    <span className="price">
                                      ¥{" "}
                                      {round10(
                                        item["option"][0].price * TAX_RATE
                                      ).toLocaleString()}
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td
                                className="text-center hidden-xs"
                                style={{ width: "20%", borderTop: "1px" }}
                              >
                                {item["name"] == "Edge Kids" ? (
                                  <span className="price">
                                    ¥{" "}
                                    {round10(
                                      item["option"][0].price * TAX_RATE
                                    ).toLocaleString()}
                                  </span>
                                ) : item["name"] != "Edge Kids" &&
                                  item["category"] == "edge" ? (
                                  <span className="price">
                                    ¥{" "}
                                    {round10(
                                      item["option"][2].price * TAX_RATE
                                    ).toLocaleString()}
                                  </span>
                                ) : (
                                  <span className="price">
                                    ¥{" "}
                                    {round10(
                                      item["option"][0].price * TAX_RATE
                                    ).toLocaleString()}
                                  </span>
                                )}
                              </td>
                              <td
                                className="text-center hidden-xs"
                                style={{ width: "10%", borderTop: "1px" }}
                              >
                                {item["count"]}
                              </td>
                              <td
                                className="text-center hidden-xs"
                                style={{
                                  position: "relative",
                                  borderTop: "1px",
                                }}
                              >
                                {item["name"] == "Edge Kids" ? (
                                  <span
                                    className="price"
                                    style={{ color: "#ec6c00" }}
                                  >
                                    ¥{" "}
                                    {round10(
                                      item["option"][0].price * TAX_RATE
                                    ).toLocaleString()}
                                  </span>
                                ) : item["name"] != "Edge Kids" &&
                                  item["category"] == "edge" ? (
                                  <span
                                    className="price"
                                    style={{ color: "#ec6c00" }}
                                  >
                                    ¥{" "}
                                    {round10(
                                      item["option"][2].price * TAX_RATE
                                    ).toLocaleString()}
                                  </span>
                                ) : (
                                  <span
                                    className="price"
                                    style={{ color: "#ec6c00" }}
                                  >
                                    ¥{" "}
                                    {round10(
                                      item["option"][0].price * TAX_RATE
                                    ).toLocaleString()}
                                  </span>
                                )}
                                <br />
                              </td>
                            </tr>
                          )}
                      </div>
                    );
                  })}
                </tbody>
              </table>
              {targetInclude && (
                <div className="warn_Message">
                  {`<JP>がついているMusioでは<ENG>がついているコンテンツをご利用になれません。ご注意ください。`}
                </div>
              )}
              <div className="result">
                <ul style={{ padding: 0 }}>
                  <li style={{ fontWeight: 800, backgroundColor: "#ddd" }}>
                    <span>合計</span>
                    <span>¥ {(this.state.total_price + this.state.subscription_price).toLocaleString()}</span>
                  </li>
                </ul>

                <button onClick={this.onClickPurchase}>
                  ご購入手続きへ進む
                </button>

                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#ff1d25" }}>
                    ※ We are currently selling only in Japan. The legal
                    procedures necessary for sales such as Radio Equipment
                    Conformity Certification were also conducted only in Japan,
                    so please do not use our products outside of Japan.
                    <br />
                  </span>
                  ※
                  決済ボタンをクリックしますと、お客様の決済情報を入力するページに移動します。
                  <br />
                  ※
                  分割払いの場合、当社指定の信販会社から審査のために別途ご連絡します。
                  <br />
                </span>
              </div>
            </Col>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
    auth_pending: state.AuthReducer.pending,
  };
};

export default connect(mapStateToProps)(CartMain);
