import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Grid, Row} from 'react-bootstrap';
import './css/AdminProducts.css';
import axios from 'axios';
import {STORE_ADMIN_PRODUCTS, STORE_API_URL} from '../../utils/url';
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import {connect} from 'react-redux';
import {LOG} from "../../utils/Log";

const SortableItem = SortableElement(({value}) =>
  <div className="admin_product_item">
    <Link to={"/adminProductEdit/" + value.id}>
      <img draggable="false" src={value.thumbnail} alt={""}/>
      <span className="name">{value.name}</span>
      <span className="price">¥ {value.sale_price ? value.sale_price : value.price}</span>
    </Link>
  </div>
);

const SortableList = SortableContainer(({items}) => {
  return (
    <Row>
      {items.map((value, index) => (
        <Col xs={3} className="product_col" key={"item" + index}>
          <SortableItem key={`item-${index}`} index={index} value={value}/>
        </Col>
      ))}
    </Row>
  );
});

class AdminProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_admin: false,
      loading: false,
      category: "contents",
      product_items: [],
    }
  }

  componentWillMount() {
    LOG("componentWillMount");
    this.setState({
      loading: true,
    });

    axios.get(STORE_API_URL + STORE_ADMIN_PRODUCTS, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        this.setState({
          product_items: response.data.data,
          is_admin: true,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  }

  componentDidMount() {
    LOG("componentDidMount");
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    let list = arrayMove(this.state.product_items, oldIndex, newIndex);
    if (newIndex !== oldIndex) {
      this.setState({
        loading: true,
      });
      let sort_index;
      if (newIndex !== 0 && newIndex !== list.length - 1) {
        let prevSortIndex = list[newIndex - 1]["sort_index"];
        let nextSortIndex = list[newIndex + 1]["sort_index"];
        sort_index = (prevSortIndex + nextSortIndex) / 2;
        list[newIndex]["sort_index"] = sort_index
      }
      if (newIndex === 0) {
        let nextSortIndex = list[newIndex + 1]["sort_index"];
        sort_index = nextSortIndex / 2;
        list[newIndex]["sort_index"] = sort_index

      }
      if (newIndex === list.length - 1) {
        let prevSortIndex = list[newIndex - 1]["sort_index"];
        sort_index = prevSortIndex + 1024;
        list[newIndex]["sort_index"] = sort_index
      }
      axios.put(STORE_API_URL + STORE_ADMIN_PRODUCTS, {
        product_id: list[newIndex]["id"],
        sort_index: sort_index,
      }, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
      })
        .then(() => {
          LOG("Success");
          this.setState({
            loading: false,
          })
        })
        .catch(() => {
          LOG("Error");
          this.setState({
            loading: false,
          })
        });
      this.setState({
        product_items: list,
      });
    }
  };
  clickCategory = (cate) => {
    this.setState({
      loading: true,
      category: cate,
    });
    axios.get(STORE_API_URL + STORE_ADMIN_PRODUCTS, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
      params: {
        category: cate,
      }
    })
      .then((response) => {
        this.setState({
          product_items: response.data.data,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  };

  render() {
    LOG("this.state.is_admin : " + this.state.is_admin);
    LOG("this.state.loading : " + this.state.loading);
    if (this.state.is_admin === false && this.state.loading === false)
      return (<div><h1> unauthorized </h1></div>);

    return (
      <div>
        {this.state.loading &&
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>Loading...</span>
          </div>
        </div>
        }
        <Grid className="admin_grid">
          <Row>
            <Col xs={12} className="category_bar">
              <Link to="/adminProductPost">
                <button className="create_btn">Create Product</button>
              </Link><br/>
              <button onClick={() => this.clickCategory("contents")}
                      className={`${this.state.category === "contents" ? "active" : " "}`}>contents
              </button>
              <button onClick={() => this.clickCategory("free")}
                      className={`${this.state.category === "free" ? "active" : " "}`}>free
              </button>
              <button onClick={() => this.clickCategory("goods")}
                      className={`${this.state.category === "goods" ? "active" : " "}`}>goods
              </button>
              <button onClick={() => this.clickCategory("edge")}
                      className={`${this.state.category === "edge" ? "active" : " "}`}>edge
              </button>
              <button onClick={() => this.clickCategory("musio&friends")}
                      className={`${this.state.category === "musio&friends" ? "active" : " "}`}>musio&sophy
              </button>
            </Col>
            <Col xs={12} className="admin_container">
              <SortableList
                axis={"xy"}
                pressDelay={400}
                helperClass="click_item"
                items={this.state.product_items}
                onSortEnd={this.onSortEnd}/>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProducts);
