import React, {Component} from 'react'

class AdminLoginMsg extends Component {
  render() {
    return (
      <h3 className="text-center">
        Please login.<br/>
        로그인 해주세요. <br/>
        ログインしてください。
      </h3>
    );
  }
}

export default AdminLoginMsg;
