import React, {Component} from 'react'
import {Row, Col, Grid} from 'react-bootstrap'

import EdgeTitle from './img/home_edge_title.png'

class Edge extends Component {

  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <div className="banner_title">
              EDGE
            </div>

            <div className="banner_img">
              <img src={EdgeTitle} alt="edge_title"/>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Edge;
