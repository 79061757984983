import React from 'react'
import {connect} from 'react-redux'
import './css/GoodsMain.css'

import GoodsTitle from '../home/img/home_goods_title.png'

import GoodsList from '../public_components/GoodsList'
import {Row, Col, Grid} from 'react-bootstrap'
import {get_goods} from '../../actions/goods'
import Footer from '../../footer/Footer'

import Loading from '../../utils/Loading';

class GoodsMain extends React.Component {
  constructor(props) {
    super(props);

    this.onMoreClick = this.onMoreClick.bind(this)
  }

  componentWillMount() {
    if (this.props.goods.length === 0)
      this.props.getGoods(true);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onMoreClick() {
    this.props.getGoods(false)
  }

  render() {
    return (
      <div>
        <div className="goods-banner">
          <Grid>
            <Col className="text-center">
              <img alt="banner_img" className="img-responsive" src={GoodsTitle}/>
            </Col>
          </Grid>
        </div>
        <Grid>
          <Row className="product_number">
            <Col xs={12}>
              <span className="highlight">{this.props.goods.length}</span>
              <span>個の商品</span>
            </Col>
          </Row>
          <Row>
            <GoodsList goods={this.props.goods}/>
          </Row>

          <Row>
            {this.props.next_token &&
            <Col className="more_button" xsOffset={4} xs={4}>
              <div className="text-center" onClick={this.onMoreClick}>More</div>
            </Col>
            }
          </Row>
        </Grid>
        {this.props.pending && <Loading/>}
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pending: state.GoodsReducer.pending,
    goods: state.GoodsReducer.goods,
    next_token: state.GoodsReducer.next_token
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getGoods: (is_new) => dispatch(get_goods(is_new))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsMain);
