let initialState = {
  goods: [],
}

const GoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_GOODS_PENDING':
      return {
        ...state,
        pending: true,
        error: false,
      };
    case 'APPEND_GOODS_SUCCESS':
      return {
        ...state,
        pending: false,
        error: false,
        goods: [
          ...state.goods,
          ...action.data
        ],
        next_token: action.next_token
      };
    case 'GET_GOODS_SUCCESS':
      return {
        ...state,
        pending: false,
        error: false,
        goods: action.data,
        next_token: action.next_token
      };
    case 'GET_GOODS_ERROR':
      return {
        ...state,
        pending: false,
        error: true
      };
    default:
      return state
  }
};

export default GoodsReducer
