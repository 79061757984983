import React, {Component} from 'react';
import './css/MainCarousel.css'

//import sale_web from './img/sale_web.png'
//import sale_tablet from './img/sale_tablet.png'
//import sale_mobile from './img/sale_mobile.png'

//import sale_web_christmas from './img/sale_web_christmas.png'
//import sale_mobile_christmas from './img/sale_mobile_christmas.png'

import musio_web from './img/1_web.png'
import musio_tablet from './img/1_tablet.png'
import musio_mobile from './img/1_mobile.png'

import contents_web from './img/2_web.png'
import contents_tablet from './img/2_tablet.png'
import contents_mobile from './img/2_mobile.png'

import store_web from './img/3_web.png'
import store_tablet from './img/3_tablet.png'
import store_mobile from './img/3_mobile.png'

//import title_musio from './img/title-musio.png'
import {Col, Grid, Carousel, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'

import {trackEvent} from "../../utils/tracking"

class MainCarousel extends Component {
  render() {
    return (
      <Carousel className="main_carousel_wrapper" interval={5000}>
        {/* <Carousel.Item>
          <div className="main_carousel fourth">
            <Grid>
              <Col xsHidden={true} smHidden={true} className="img-wrapper">
                <img className="main_musio" src={store_web} alt="main_musio"/>
              </Col>

              <Col xsHidden={true} lgHidden={true} mdHidden={true} className="img-wrapper">
                <img className="main_musio" src={store_tablet} alt="main_musio"/>
              </Col>

              <Col lgHidden={true} smHidden={true} mdHidden={true} className="img-wrapper">
                <img className="main_musio" src={store_mobile} alt="main_musio"/>
              </Col>
            </Grid>
          </div>
        </Carousel.Item> */}
        <Carousel.Item>
          <div className="main_carousel first">
            <Grid>
              <Col xsHidden={true} smHidden={true} className="img-wrapper">
                <img className="main_musio" src={musio_web} alt="main_musio"/>
              </Col>

              <Col xsHidden={true} lgHidden={true} mdHidden={true} className="img-wrapper">
                <img className="main_musio" src={musio_tablet} alt="main_musio"/>
              </Col>

              <Col lgHidden={true} smHidden={true} mdHidden={true} className="img-wrapper">
                <img className="main_musio" src={musio_mobile} alt="main_musio"/>
              </Col>
              <Col sm={7} className="title">
                <div className="highlight">Musio</div>
                <div>あなたのお友達ロボット</div>
                <Link to="/product/b47daab6-4fc1-565b-8818-eac897958f6d" onClick={() => {trackEvent("HOME", "Click from Carousel: b47daab6-4fc1-565b-8818-eac897958f6d", "")}}>
                  <Button className="button1">More</Button>
                </Link>
              </Col>
            </Grid>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="main_carousel second">
            <Grid>
              <Col xsHidden={true} smHidden={true} className="img-wrapper">
                <img className="main_musio" src={contents_web} alt="main_musio"/>
              </Col>

              <Col xsHidden={true} lgHidden={true} mdHidden={true} className="img-wrapper">
                <img className="main_musio" src={contents_tablet} alt="main_musio"/>
              </Col>

              <Col lgHidden={true} smHidden={true} mdHidden={true} className="img-wrapper">
                <img className="main_musio" src={contents_mobile} alt="main_musio"/>
              </Col>
              <Col sm={7} className="title">
                <div className="highlight-2">
                  EDUモードに<br/>
                  新教材が登場！
                </div>
                <Link to="/contents" onClick={() => {trackEvent("HOME", "Click from Carousel: contents", "")}}>
                  <Button className="button2">新教材を見る</Button>
                </Link>
              </Col>
            </Grid>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="main_carousel third">
            <Grid>
              <Col sm={7} className="title">
                <div className="highlight">Academy Plan</div>
                <div>法人様向け学習サービス</div>
                <a href="https://academy.themusio.com/" onClick={() => {trackEvent("HOME", "Click from Carousel: academy", "")}}>
                  <Button className="button3">More</Button>
                </a>
              </Col>
            </Grid>
          </div>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default MainCarousel;
