import axios from 'axios'
import {STORE_REST_PRODUCTS, STORE_API_URL} from '../utils/url'
import {LOG} from "../utils/Log";

const APPEND_GOODS_SUCCESS = 'APPEND_GOODS_SUCCESS';

const GET_GOODS_PENDING = 'GET_GOODS_PENDING';
const GET_GOODS_SUCCESS = 'GET_GOODS_SUCCESS';
const GET_GOODS_ERROR = 'GET_GOODS_ERROR';

export function get_goods(is_new) {
  LOG("goods @ get_goods , is_new : " + is_new);

  return (dispatch, getState) => {
    dispatch({type: GET_GOODS_PENDING});
    //axios.get(url+"/rest/products?catergory=${category}&tag=${tag}")
    let request_url = STORE_API_URL + STORE_REST_PRODUCTS;
    let next_token = getState().GoodsReducer.next_token;

    axios.get(request_url, {
      params: {
        category: 'goods',
        start_token: next_token
      }
    }).then(
        (response) => {
          let type = APPEND_GOODS_SUCCESS;
          if (is_new) type = GET_GOODS_SUCCESS;
          dispatch({
            type: type,
            data: response.data.data,
            next_token: response.data.next_token
          })
        }
      ).catch(
        (error) => {
          LOG(error);
          dispatch({type: GET_GOODS_ERROR})
        }
      )
  }
}
