import React from 'react';
import {injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';
import '../purchase/css/PurchaseMain.css'
import axios from 'axios'
import {STORE_API_URL, STORE_REST_CARD} from '../../utils/url'
import {LOG} from "../../utils/Log";
import visa from '../purchase/img/card1.png';
import mastercard from '../purchase/img/card2.png';
import amex from '../purchase/img/card3.png';
import discover from '../purchase/img/card4.png';
import diners from '../purchase/img/card5.png';
import jcb from '../purchase/img/card6.png';

const createOptions = (fontSize) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#333',
        letterSpacing: '0.025em',
        fontFamily: 'Noto Sans JP',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      },
      complete: {
        color: "#00ba4a"
      }
    }
  };
};

class MypageCardStripe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      brand: "",
      error_msg: "",
      loading: true,

      card_info: null,
      edit_mode: false,
      before_card_info: null,
    }
  }

  /*componentWillMount() {
    this.setState({
      loading: true,
    });
    axios.get(STORE_API_URL + STORE_REST_CARD, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        console.log(response);
        this.setState({
          card_info: response.data.data,
          brand: response.data.data["card_brand"],
          loading: false,
        })
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        })
      })
  }*/

  componentDidMount() {
    LOG("CartMain @ componentDidMount");

    if(this.props.jwt != undefined) {
      axios.get(STORE_API_URL + STORE_REST_CARD, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
      })
      .then((response) => {
        console.log('did mount', response);
        this.setState({
          card_info: response.data.data,
          brand: response.data.data["card_brand"],
          loading: false,
        })
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        })
      })
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if(
  //     (nextProps.jwt != undefined && nextProps.jwt != this.props.jwt) ||
  //     (nextState.loading != this.state.loading)
  //   ) {
  //     return true
  //   }
  //   return false
  // }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.jwt != undefined && prevProps.jwt != this.props.jwt) {
      axios.get(STORE_API_URL + STORE_REST_CARD, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
      })
      .then((response) => {
        console.log('did update', response);
        this.setState({
          card_info: response.data.data,
          brand: response.data.data["card_brand"],
          loading: false,
        })
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        })
      })
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({
      loading: true,
    });
    if (this.state.card_info != null) {
      this.setState({
        loading: false,
      });
      return;
    }
    if (this.state.name === "") {
      this.setState({
        error_msg: "Please, Input your name",
        loading: false,
      });
      return false;
    }
    this.props.stripe.createToken({name: this.state.name}).then((payload) => {
      if (!payload.error) {
        console.log('Received Stripe token:', payload.token);
        if (this.state.edit_mode) {
          axios.put(STORE_API_URL + STORE_REST_CARD, {
            token: payload.token.id,
          }, {
            headers: {"Authorization": "Bearer " + this.props.jwt}
          })
            .then((response) => {
              this.setState({
                card_info: response.data.data,
                brand: response.data.data["card_brand"],
                error_msg: "",
                edit_mode: false,
                loading: false,
              })
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                error_msg: "",
                loading: false,
              })
            })
        } else {
          axios.post(STORE_API_URL + STORE_REST_CARD, {
            token: payload.token.id,
          }, {
            headers: {"Authorization": "Bearer " + this.props.jwt}
          })
            .then((response) => {
              this.setState({
                card_info: response.data.data,
                brand: response.data.data["card_brand"],
                error_msg: "",
                loading: false,
              })
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                error_msg: "",
                loading: false,
              })
            })
        }
      } else {
        this.setState({
          error_msg: payload.error.message,
          loading: false,
        })
      }
    })
  };
  cardChange = value => {
    this.setState({
      brand: value.brand
    });
  };
  clickEditBtn = (e) => {
    e.preventDefault()
    this.setState({
      before_card_info: this.state.card_info,
      card_info: null,
      brand: "",
      edit_mode: true,
    })
    console.log(this.state)
  };
  clickCancelBtn = () => {
    this.setState({
      card_info: this.state.before_card_info,
      brand: this.state.before_card_info["card_brand"],
      before_card_info: null,
      edit_mode: false,
    })
  };

  render() {
    console.log('rendering with..', this.state);
    return (
      <form className="card_form" onSubmit={this.handleSubmit}>
        {this.state.loading &&
        <div className="loading_container">
          <span className="vertical_space"/>
          <div className="loader"/>
        </div>
        }
        <div className={`stripe_card_div `}>
          <div>
            <span className="card_info_title">カード所有者名</span><br/>
            {this.state.card_info == null ?
              <input type="text" className="card_name"
                     value={this.state.name}
                     onChange={(e) => this.setState({name: e.target.value})}
                     placeholder="Name">
              </input> :
              <span className="card_text">{this.state.card_info["card_name"]}</span>
            }
          </div>
          <div>
            <span className="card_info_title">カード番号</span><br/>
            {this.state.card_info == null ?
              <CardNumberElement
                className="card_info_content"
                onChange={this.cardChange}
                {...createOptions("18px")}
              /> :
              <span className="card_text">**** - **** - **** - {this.state.card_info["card_number"]}</span>
            }
          </div>
          <div>
            <span className="card_info_title">カード有効期限 (ex: MM/YY)</span><br/>
            {this.state.card_info == null ?
              <CardExpiryElement
                className="card_info_content"
                {...createOptions("18px")}
              /> :
              <span className="card_text">{this.state.card_info["card_exp"]}</span>
            }
          </div>
          <img src={visa} className={`card_brand ${this.state.brand.toLowerCase() === "visa" && "active"}`} alt={""}/>
          <img src={mastercard} className={`card_brand ${this.state.brand.toLowerCase() === "mastercard" && "active"}`}
               alt={""}/>
          <img src={amex} className={`card_brand ${this.state.brand.toLowerCase() === "amex" && "active"}`} alt={""}/>
          <img src={discover} className={`card_brand ${this.state.brand.toLowerCase() === "discover" && "active"}`}
               alt={""}/>
          <img src={diners} className={`card_brand ${this.state.brand.toLowerCase() === "diners" && "active"}`}
               alt={""}/>
          <img src={jcb} className={`card_brand ${this.state.brand.toLowerCase() === "jcb" && "active"}`} alt={""}/>
          <div>
            <span className="card_info_title">CVC</span><br/>
            {this.state.card_info == null ?
              <CardCVCElement className="card_info_content"
                              {...createOptions("18px")}
              /> :
              <span className="card_text">***</span>
            }
          </div>
          <span className="error_msg">{this.state.error_msg}</span>
        </div>

        {this.state.card_info != null && 
        <div className="edit_card_btn" type="button" onClick={this.clickEditBtn}>
          <button>編集</button>
        </div>
        }

        <div className="tip">
          VISA/MASTER/Dinersカードのセキュリティーコードは、裏面署名欄の末尾３桁の数字です。<br/>
          American Expressカードのセキュリティーコードは表面の4桁の数字です。
        </div>
        {this.state.card_info != null ?
          <button className="complete_btn" type="button" disabled>確認完了</button> :
          <button>確認</button>
        }
        {this.state.edit_mode &&
        <button onClick={this.clickCancelBtn} style={{marginLeft: "10px"}}>キャンセル</button>
        }
      </form>
    );
  }
}

export default injectStripe(MypageCardStripe);
