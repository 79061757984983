import React from 'react'
import './css/MovingImage.css'
import {Row, Col, Grid} from 'react-bootstrap'

//import MovingImage1 from './img/home_edge_hawaii.png'
import MovingImage2 from './img/home_edge_kids.png'
import MovingImage3 from './img/home_edge_biz.png'

import {Link} from 'react-router-dom';
import {trackEvent} from "../../utils/tracking"


class MovingImage extends React.Component {
  /*constructor(props) {
    super(props)

  }
*/

  /*    this.scrollRef.scrollTo(this.scro
  constructor(props){
    super(props)

    let images = this.props.children
    images = images.concat(images)
    let aniStyle = {
        animationName: 'image_move',
        animationDuration: this.props.runningSpeed+'s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear'
    }

    images[0] = React.cloneElement(images[0],
      {
        id: 'moving_image',
        style: aniStyle
      })

    this.state = {
      children: images
    }

     let styleSheet = document.styleSheets[0]
     let moving_percent = 50 * images.length/2
     let keyframes = `
     @keyframes image_move{
       0% {
         margin-left: 0px;
       }
       100% {
         margin-left: -${moving_percent}%;
       }
     }`
     styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }
  */
  render() {
    return (
      <div style={{userSelect: 'none'}}>
        <Grid>
          <Row>
            <Col className="moving_image_wrapper">

              <div className="image_wrapper">
                <Link to={"/edge/adult"} onClick={() => {trackEvent("HOME", "Click from Home: edge ADULT", "")}}>
                  <Col style={{width: '50%', display: 'inline-block'}}>
                    <img src={MovingImage3} alt="MovingImage3" style={{width: '100%'}}
                    />
                  </Col>
                </Link>
                <Link to={"/edge/kids"} onClick={() => {trackEvent("HOME", "Click from Home: edge KIDS", "")}}>
                  <Col style={{width: '50%', display: 'inline-block'}}>
                    <img src={MovingImage2} alt="MovingImage2" style={{width: '100%'}}
                    />
                  </Col>
                </Link>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default MovingImage;
