import React, { Fragment } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { Route, withRouter, Switch } from "react-router-dom";
import { get_auth, login_fail } from "./actions/auth";
import "./App.css";
import SBActivation from "./components/activation/SBActivation";
import AdminCreateProducts from "./components/admin/AdminCreateProducts";
import AdminEditProducts from "./components/admin/AdminEditProducts";
import AdminLoginMsg from "./components/admin/AdminLoginMsg";
import AdminOrder from "./components/admin/AdminOrder";
import AdminProducts from "./components/admin/AdminProducts";
import AdminSale from "./components/admin/AdminSale";
import AdminSaleDetail from "./components/admin/AdminSaleDetail";
import AdminSaleProducts from "./components/admin/AdminSaleProducts";
import CartMain from "./components/cart/CartMain";
import ContentsMain from "./components/contents/ContentsMain";
import Detail from "./components/detail/Detail";
import EdgeDetail from "./components/detail/EdgeDetail";
import GoodsMain from "./components/goods/GoodsMain";
import Home from "./components/home/Home";
import HomeJapan from "./components/home/HomeJapan";
import MypageAddress from "./components/mypage/MypageAddress";
import MypageCard from "./components/mypage/MypageCard";
import MypageEdge from "./components/mypage/MypageEdge";
import MypageNavbar from "./components/mypage/MypageNavbar";
import MypageOrder from "./components/mypage/MypageOrder";
import MyPageQnA from "./components/mypage/MyPageQnA";
import PurchaseMain from "./components/purchase/PurchaseMain";
import SuccessPage from "./components/purchase/SuccessPage";
import Service from "./components/service/Service";
import Header from "./header/Header";
import Loading from "./utils/Loading";
import { LOG } from "./utils/Log";
import { initGA, pageView } from "./utils/tracking";


// const AsyncHome = asyncComponent(() => import("./components/home/Home"));
// const AsyncGoodsMain = asyncComponent(() =>
//   import("./components/goods/GoodsMain")
// );
// const AsyncEdgeDetail = asyncComponent(() =>
//   import("./components/detail/EdgeDetail")
// );
// const AsyncContentsMain = asyncComponent(() =>
//   import("./components/contents/ContentsMain")
// );
// const AsyncDetail = asyncComponent(() => import("./components/detail/Detail"));
// const AsyncService = asyncComponent(() =>
//   import("./components/service/Service")
// );
// const AsyncCartMain = asyncComponent(() =>
//   import("./components/cart/CartMain")
// );
// const AsyncPurchaseMain = asyncComponent(() =>
//   import("./components/purchase/PurchaseMain")
// );
// const AsyncSuccessPage = asyncComponent(() =>
//   import("./components/purchase/SuccessPage")
// );
// const AsyncMypageNavbar = asyncComponent(() =>
//   import("./components/mypage/MypageNavbar")
// );
// const AsyncMypageAddress = asyncComponent(() =>
//   import("./components/mypage/MypageAddress")
// );
// const AsyncMypageCard = asyncComponent(() =>
//   import("./components/mypage/MypageCard")
// );
// const AsyncMypageOrder = asyncComponent(() =>
//   import("./components/mypage/MypageOrder")
// );
// const AsyncMypageEdge = asyncComponent(() =>
//   import("./components/mypage/MypageEdge")
// );

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: null,
    };

    // LOG("App @ componentWillMount");
    if (this.props.jwt === undefined) {
      let session_token = this.props.cookies.cookies.session_token;
      // let session_token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc19pbnN0aXR1dGUiOmZhbHNlLCJpZCI6MjAwMzIsImlzX2FkbWluIjpmYWxzZSwiZXhwIjoxNjgzMDcyNjgzLCJpYXQiOjE2ODI5ODYyODMsImVtYWlsIjoic2hlcnJ5KzIwMjMwMTMwbXNAYWthY29ycC5qcCIsImNsaWVudF9rZXkiOiJvMDdyRUU4TFltc2dHVEJrdlVPUWZubGI5QWZLQjVCZXFpczltT0RqOUZzN2ZkRk4zSDBiTUUxaUpuOVZTaFVKNHJpQU9Yd2J5ZjhxOUlsdG5uclppMWRHcjlvUzJrVjM5V1hEN1R5N2JlWndrbTR1ZzIzazB1TlI1TFhMbGpFUyJ9.YYH18ptlLWQYVkLRnOeKm975yV2ZmabdYhB_740dV-c"; //for test
      if (
        session_token !== undefined &&
        session_token !== null &&
        session_token !== "None"
      ) {
        this.props.getToken(session_token);
      } else {
        this.props.loginFail();
      }
    }
  }

  /*componentWillMount() {
    LOG("App @ componentWillMount");

    if (this.props.jwt === undefined) {
      let session_token = this.props.cookies.cookies.session_token;
      //session_token="gtbngfh67j5u90czhh62lqcyiz7l0zlp"; //for test
      if (
        session_token !== undefined &&
        session_token !== null &&
        session_token !== "None"
      ) {
        LOG("error!!!!");
        this.props.getToken(session_token);
      } else {
        //LOG("here");
        this.props.loginFail();
      }
    }
  }*/

  componentDidMount() {
    // LOG("App @ componentDidMount");
    initGA("UA-144159165-1");
    pageView();
  }

  render() {
    return (
      <div>
        {this.props.location.pathname.includes("mypage") ||
        this.props.location.pathname.includes("admin") ? (
          <div />
        ) : (
          <Header />
        )}
        
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/product/:product_id" component={Detail} />
        <Route exact path="/purchase" component={PurchaseMain} />
        <Route exact path="/purchaseSuccess" component={SuccessPage} />
        <Route exact path="/cart" component={CartMain} />
        <Route exact path="/goods" component={GoodsMain} />
        <Route exact path="/edge/:type" component={EdgeDetail} />
        <Route exact path="/contents" component={ContentsMain} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/sbActivation" component={SBActivation} />

        <Route exact path="/musios" component={HomeJapan}/>
        <Route exact path="/musios/home" component={HomeJapan}/>
        <Route exact path="/musios/product/:product_id" component={Detail} />
        <Route exact path="/musios/purchase" component={PurchaseMain} />
        <Route exact path="/musios/purchaseSuccess" component={SuccessPage} />
        <Route exact path="/musios/cart" component={CartMain} />
        <Route path="/mypage" component={MypageNavbar} />
        <Route exact path="/mypageAddress" component={MypageAddress} />
        <Route exact path="/mypageCard" component={MypageCard} />
        <Route exact path="/mypageOrder" component={MypageOrder} />
        <Route exact path="/mypageEdge" component={MypageEdge} />
        <Route exact path="/mypageRedeem" component={MypageEdge} />
        <Route exact path="/mypageQnA" component={MyPageQnA} />


        {this.props.jwt !== undefined ? (
          <Fragment>
            <Route
              exact
              path="/adminProductPost"
              component={AdminCreateProducts}
            />
            <Route exact path="/adminProduct" component={AdminProducts} />
            <Route
              exact
              path="/adminProductEdit/:product_id"
              component={AdminEditProducts}
            />
            <Route exact path="/adminOrder" component={AdminOrder} />
            <Route exact path="/adminSale" component={AdminSale} />
            <Route
              exact
              path="/adminSaleProducts"
              component={AdminSaleProducts}
            />
            <Route
              exact
              path="/adminSaleProducts/:product_id"
              component={AdminSaleDetail}
            />
          </Fragment>
        ) : (
          <Fragment>
            {this.props.location.pathname.includes("admin") ? (
              <Route component={AdminLoginMsg} />
            ) : undefined}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
    pending: state.AuthReducer.pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getToken: (session_token) => dispatch(get_auth(session_token)),
    loginFail: () => dispatch(login_fail()),
  };
};

export default withRouter(
  withCookies(connect(mapStateToProps, mapDispatchToProps)(App))
);
