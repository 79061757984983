import React from 'react'
import './css/DetailCommentList.css'
import DetailComment from './DetailComment'
//import PropTypes from 'prop-types'
import {Button, Col, Grid, Row} from 'react-bootstrap'
import DetailReview from './DetailReview'
import Loading from '../../utils/Loading'
import axios from 'axios'
import {AUTH_LOGIN, STORE_API_URL, STORE_REST_PRODUCTS, USERMODEL_URL} from "../../utils/url";
import {LOG} from "../../utils/Log";

class DetailCommentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onReviewToggle: false,
      last_key: undefined,
      comments: [],
      product_id: this.props.product.id
    };
    this.onMoreClick = this.onMoreClick.bind(this);
    this.onReviewToggle = this.onReviewToggle.bind(this);
    this.postComment = this.postComment.bind(this);
    this.getComments = this.getComments.bind(this);
  }

  componentWillMount() {
    this.getComments()
  }

  componentDidMount() {
  }

  getComments() {
    let request_url = STORE_API_URL + STORE_REST_PRODUCTS + "/" + this.state.product_id + "/reviews";

    this.setState({
      ...this.state,
      pending: true
    });

    axios.get(request_url, {
      params: {
        LastEvaluatedKey: this.state.last_key
      }
    }).then(
      (response) => {
        this.setState({
          ...this.state,
          pending: false,
          last_key: response.data.LastEvaluatedKey,
          comments: [
            ...this.state.comments,
            ...response.data.data
          ]
        })
      })
      .catch(
        (error) => {
        }
      )
  }

  onReviewToggle() {
    if (!this.props.isAuthenticated) {
      alert('Please Login');
      window.location = USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
      return;
    }
    this.setState({
      onReviewToggle: !this.state.onReviewToggle
    })
  }

  postComment(content, score) {
    let request_url = STORE_API_URL + STORE_REST_PRODUCTS + "/" + this.state.product_id + "/reviews";
    let jwt_token = this.props.jwt;

    this.setState({
      ...this.state,
      pending: true
    });
    axios.post(request_url, {
      content: content,
      score: score
    }, {headers: {"Authorization": "Bearer " + jwt_token}})
      .then(
        (response) => {
          this.onReviewToggle();
          this.setState({
            ...this.state,
            pending: false,
            comments: [
              response.data.data,
              ...this.state.comments
            ]
          })
        }
      )
      .catch(
        (error) => {
          LOG(error)
        }
      )
  }

  onMoreClick() {
    this.getComments(this.state.last_key)
  }

  render() {
    return (
      <div id="comment_wrapper">

        <Grid className="detail">
          <Row>
            <div className="comment">
              <Col md={5} xs={12}>
                コメントの作成
              </Col>
              <Col md={7} xs={12}>
                <div className="comment_form">
                  {
                    this.state.onReviewToggle &&
                    <input onClick={this.onReviewToggle} type="text" className="comment_input"
                           placeholder="書き込みを作成してください" disabled/>
                  }

                  {
                    !this.state.onReviewToggle &&
                    <input onClick={this.onReviewToggle} type="text" className="comment_input"
                           placeholder="書き込みを作成してください"/>
                  }

                  <Button className="comment_submit" onClick={this.onReviewToggle}>登録</Button>
                </div>
                <div className="comment_content_wrapper">
                  {
                    this.state.comments.map((comment, i) => {
                      return (
                        <DetailComment key={i} {...comment}  />
                      )
                    })
                  }
                </div>
                <div id="more_wrapper">
                  {
                    this.state.last_key &&
                    <div className="comment_more" onClick={this.onMoreClick}>
                      more
                    </div>
                  }
                </div>
              </Col>
            </div>
          </Row>
        </Grid>
        {
          this.state.pending &&
          <Loading/>
        }

        {
          this.state.onReviewToggle &&
          <DetailReview
            thumbnail={this.props.product.thumbnail}
            title={this.props.product.name}
            onReviewToggle={this.onReviewToggle}
            postComment={this.postComment}
            pending={this.state.pending}
          />
        }
      </div>
    );
  }
}

export default DetailCommentList;
