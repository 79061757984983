export const STORE_API_URL = "https://store-api.themusio.com/v2";
//export const STORE_API_URL = "http://localhost.themusio.com:8000"; //for test , on linux, change the host(localhost or 127.0.0.1) to 'http://localhost.themusio.com' in /etc/hosts

export const THE_MUSIO_URL = "https://themusio.com";
//export const THE_MUSIO_URL = "http://test.themusio.com"; // for test

export const STORE_DOMAIN = "https://store.themusio.com";
//export const STORE_DOMAIN = "https://storetest.themusio.com"; // for test

export const USERMODEL_URL = "https://usermodel.themusio.com";
//export const USERMODEL_URL = "http://test.themusio.com"; // for test

export const STRIPE_KEY = "pk_live_7DOiYyVStvnUGhB7AIbiCkDm";
//export const STRIPE_KEY = "pk_test_ipN4imKuGdNbZqZW5iJPfJiM"; //for test


// store apis
export const STORE_ADMIN_AUTH = "/admin/auth";
export const STORE_ADMIN_PRODUCTS = "/admin/products";
export const STORE_ADMIN_ORDER = "/admin/order";
export const STORE_ADMIN_SALE = "/admin/sale";
export const STORE_ADMIN_SALE_PRODUCTS = "/admin/sale/products/";

export const STORE_REST_PRODUCTS = "/rest/products";
export const STORE_REST_CART = "/rest/cart";
export const STORE_REST_CHARGE = "/rest/charge";
export const STORE_REST_ADDRESS= "/rest/address";
export const STORE_REST_ADDRESS_SELECT = "/rest/address/select";
export const STORE_REST_CARD = "/rest/card";
export const STORE_REST_EDGE = "/rest/edge";
export const STORE_REST_ORDER = "/rest/order";
export const STORE_REST_CONTENT_ACTIVATION = "/rest/activation";

// usermodel apis
export const AUTH_SIGN_IN = "/auth/signin/token/";
export const AUTH_LOGOUT = "/logout";
export const AUTH_SIGN_UP = "/signup";
export const AUTH_LOGIN = "/login";

// themusio.com apis
export const AUTH_CM_REST_SUBSCRIBERS = "cm/rest/subscribers/";
