import React from "react";

import "./css/Detail.css";
import DetailCommentList from "./DetailCommentList";
import DetailInfo from "./DetailInfo";
import { connect } from "react-redux";
import Footer from "../../footer/Footer";
//import DetailReview from './DetailReview'
import DetailContent from "./DetailContent";
import Loading from "../../utils/Loading";
import axios from "axios";
import {
  STORE_API_URL,
  STORE_DOMAIN,
  STORE_REST_PRODUCTS,
} from "../../utils/url";
import { LOG } from "../../utils/Log";
import Mamari from "./Mamari";

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: undefined,
    };
  }

  getDetail = (jwt) => {
    LOG("Detail @ getDetail , jwt : " + jwt);

    let product_id = this.props.match.params.product_id;
    let request_url = STORE_API_URL + STORE_REST_PRODUCTS + "/" + product_id;
    LOG("request_url : " + request_url);

    axios
      .get(request_url, {
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((response) => {
        LOG(response.data.data);
        if (response.data.data === null || response.data.data === undefined) {
          alert("No access this product");
          window.location = STORE_DOMAIN;
          return;
        }

        this.setState({
          ...this.state,
          product: response.data.data,
        });
      })
      .catch((error) => {
        LOG(error);
      });
  };

  componentWillMount() {
    LOG("Detail @ componentWillMount");

    this.getDetail(this.props.jwt);
  }

  componentDidMount() {
    LOG("Detail @ componentDidMount");
    window.scrollTo(0, 0);
  }

  render() {
    LOG("Detail @ render");

    LOG("this.state.product : " + this.state.product);
    if (this.state.product === undefined) {
      return <Loading />;
    }
    const isMamari =
      this.props.match.params.product_id ===
      "36861fae-0d54-5798-8740-4873baed8dee";

    return (
      <div>
        {isMamari ? (
          <Mamari
            productId={this.state.product.id}
            history={this.props.history}
          />
        ) : (
          <div>
            <DetailInfo
              product={this.state.product}
              jwt={this.props.jwt}
              history={this.props.history}
              isAuthenticated={this.props.isAuthenticated}
            />

            <DetailContent
              description_image={this.state.product.description_image}
              description={
                this.state.product.name === "Musio X Set"
                  ? this.state.product.description
                  : undefined
              }
            />
            <DetailCommentList
              product={this.state.product}
              isAuthenticated={this.props.isAuthenticated}
              jwt={this.props.jwt}
            />
            <Footer marginTop={0} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Detail);
