import React, {Component} from 'react'
//import {Grid, Row, Col, PanelGroup, Glyphicon, Panel} from 'react-bootstrap'
//import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Loading from '../../utils/Loading';
import {LOG} from "../../utils/Log";

//import edgeKidsIndex from "edgekids/index.html";

class EdgeKidsDetailContent extends Component {

  constructor() {
    super();
    this.state = {
      iFrameHeight: 300,
      counter: undefined,
      iframeLoading: true
    };

    //this.edgeKidsPath ="http://aka-store-web.s3-website-ap-northeast-1.amazonaws.com/edgekids/index.html";
    //this.edgeKidsPath = STORE_DOMAIN+"/edgekids/index.html";
    this.edgeKidsPath = "/edgekids/index.html";
    this.iframeResize = this.iframeResize.bind(this);
  }

  iframeResize() {
    LOG("iframeResize");
    const timer = () => {
      const iframeDOMNode = ReactDOM.findDOMNode(this.iframeRef);
      LOG(iframeDOMNode);

      let iFrameHeight = this.state.iFrameHeight
      if(iframeDOMNode != null) {
        iFrameHeight = iframeDOMNode.contentWindow.document.body.scrollHeight;
      }

      LOG("iFrameHeight : " + iFrameHeight);
      LOG("this.state.iFrameHeight : " + this.state.iFrameHeight);
      if (this.state.iFrameHeight !== iFrameHeight) {
        this.setState(
          {
            ...this.state,
            iFrameHeight: iFrameHeight,
            iframeLoading: false
          }
        )
      }
    };

    if(this.state.counter !== undefined) {
      clearInterval(this.state.counter);
    }
    
    let counter = setInterval(timer, 200);
    this.setState({
      ...this.state,
      counter: counter
    })
    console.log('counter: ' + counter)
  }

  componentWillUnmount() {
    clearInterval(this.state.counter);
    console.log(this.state.counter)
    console.log('UNOMOUNT!')
  }

  render() {
    if (this.state.iframeLoading === undefined) {
      return (
        <Loading/>
      )
    }

    return (
      <div className="detail">
        <iframe src={this.edgeKidsPath}
                title={"edgeKids"}
                ref={ref => {this.iframeRef = ref}}
                style={{width: '100%', position: 'relative', height: this.state.iFrameHeight, border: '0px'}}
                onLoad={this.iframeResize}
                scrolling='no'
        />
      </div>
    );
  }
}


export default EdgeKidsDetailContent
