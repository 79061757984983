import React from 'react'
//import { Link } from 'react-router-dom';
import {Row, Col, Grid} from 'react-bootstrap'
import './css/Mypage.css'
import axios from 'axios'
import {AUTH_LOGIN, STORE_API_URL, STORE_REST_ORDER, USERMODEL_URL} from '../../utils/url'
import MypageSidebar from './MypageSidebar'
import Footer from '../../footer/Footer'
import {connect} from 'react-redux'
import {LOG} from "../../utils/Log";
import Loading from '../../utils/Loading'


class MypageOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders_contents: [],
      orders_etc: [],
    }
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      window.location = USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
    }

    if(this.props.jwt != undefined) {
      axios.get(STORE_API_URL + STORE_REST_ORDER, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
    
      })
      .then((response) => {

        this.setState({
          orders_contents: response.data.data["contents"],
          orders_etc: response.data.data["etc"],
        })
      })
      .catch((error) => {
        // LOG(error);
      })
    }
    window.scrollTo(0, 0);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(
      (nextProps.jwt != undefined && nextProps.jwt != this.props.jwt) ||
      (nextState != this.state)
    ) {
      return true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.jwt != undefined && prevProps != this.props) {
      axios.get(STORE_API_URL + STORE_REST_ORDER, {
        headers: {"Authorization": "Bearer " + this.props.jwt},
            })
      .then((response) => {
        this.setState({
          orders_contents: response.data.data["contents"],
          orders_etc: response.data.data["etc"],
        })
      })
      .catch((error) => {
        // LOG(error);
      })
    }
  }

  handleClickBlackBox = () => {
    document.querySelector("#sidebar-left").style.left = "";
    document.querySelector("#black_box").style.backgroundColor = "";
    document.querySelector("#black_box").style.zIndex = "";
    document.querySelector(".side-toggle").dataset.clicked = "false";
  };

  render() {
    if (this.props.auth_pending) {
      return (
        <Loading/>
      )
    }

    return (
      <div id="mypage_div">
        <Grid>
          <Row>
            <MypageSidebar jwt={this.props.jwt}/>
            <Col xs={12} sm={9} lg={10} className="purchase_container" id="mypage_content_div">
              <div id="black_box" className="visible-xs" onClick={this.handleClickBlackBox}>
              </div>
              <div>
                <span className="title">注文履歴照会</span>
                <br/>
                <span className="desc">
                    注文した商品の履歴を確認できます。
                  </span>
                <hr/>
                <table className="table" id="order_table">
                  <thead>
                  <tr id="order_list_title">
                    <th className="text-center hidden-xs" id="date">日付</th>
                    <th className="text-center" id="product">商品</th>
                    <th className="text-center hidden-xs" id="quantity">個数</th>
                    <th className="text-center hidden-xs" id="status">状況</th>
                    {/* <th className="text-center hidden-xs" id="post">配送状況</th> */}
                  </tr>
                  </thead>
                  <tbody>
                  {this.state != null && this.state.orders_etc.map((item, index) => (
                    <tr className="order_list_content" key={"order_list" + index}>
                      <td className="text-left hidden-xs date">
                        <div>
                          <span className="date_span">{item["create_date"].slice(0, 10)}</span><br/>
                        </div>
                      </td>
                      <td className="text-center product">
                        <div>
                          <span className="product_name">{item["product_name"]}</span>
                          {item["option"].length > 0 &&
                          item["option"].map((option, index_option) => (
                            <span key={"options" + index_option} className="product_description">
                                    {option["name"]}
                                  </span>
                          ))
                          }
                          <span className="visible-xs" style={{marginTop: '20px'}}>quantity : {item["count"]}</span>
                          <span className="visible-xs">{item["create_date"].slice(0, 10)}</span>
                          <hr className="visible-xs"/>
                          <span className="visible-xs state_span">
                                  {item["state"]}
                              </span>
                          <br/>
                          {item["product_name"] != "Friends Plan" &&
                            <div className="visible-xs m_post">
                              お問い合わせ番号 : {item["post_number"] ? item["post_number"] : "準備中"}<br/>
                              <a href="https://toi.kuronekoyamato.co.jp/cgi-bin/tneko">Link</a>
                            </div>
                          }
                        </div>
                      </td>
                      <td className="text-center hidden-xs quantity">{item["count"]}</td>
                      <td className="text-center hidden-xs status">
                        {item["state"]}
                      </td>
                      {/* <td className="text-center hidden-xs post">
                        {item["product_name"] != "Friends Plan" &&
                          <div>
                            お問い合わせ番号 : {item["post_number"] ? item["post_number"] : "準備中"}<br/>
                            <a href="https://toi.kuronekoyamato.co.jp/cgi-bin/tneko">Link</a>
                          </div>
                        }
                      </td> */}
                    </tr>
                  ))}
                  </tbody>
                </table>

                <span className="title">Contents</span>
                <br/>
                <table className="table" id="order_table">
                  <thead>
                  <tr id="order_list_title">
                    <th className="text-center hidden-xs" id="date">日付</th>
                    <th className="text-center" id="product">商品</th>
                    <th className="text-center hidden-xs" id="quantity">個数</th>
                    <th className="text-center hidden-xs" id="status">状況</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.orders_contents.map((item, index) => (
                    <tr className="order_list_content" key={"order_list" + index}>
                      <td className="text-left hidden-xs date">
                        <div>
                          <span className="date_span">{item["create_date"].slice(0, 10)}</span><br/>
                        </div>
                      </td>
                      <td className="text-center product">
                        <div>
                          <span className="product_name">{item["product_name"]}</span>
                          {item["option"].length > 0 &&
                          item["option"].map((option, index_option) => (
                            <span key={"options" + index_option} className="product_description">
                                    {option["name"]}
                                  </span>
                          ))
                          }
                          <span className="visible-xs" style={{marginTop: '20px'}}>quantity : {item["count"]}</span>
                          <span className="visible-xs">{item["create_date"].slice(0, 10)}</span>
                          <hr className="visible-xs"/>
                          <span className="visible-xs state_span">
                                  {item["state"]}
                              </span>
                          <br/>
                        </div>
                      </td>
                      <td className="text-center hidden-xs quantity">{item["count"]}</td>
                      <td className="text-center hidden-xs status">
                        {item["state"]}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
    auth_pending: state.AuthReducer.pending
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(MypageOrder);
