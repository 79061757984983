import React, {Component} from 'react'
import './css/EdgeDetailContent.css'
import {Grid, Row, Col, PanelGroup, Panel} from 'react-bootstrap'
//import PropTypes from 'prop-types'
import content_web_1 from './edge/web/1.png'
import content_web_2 from './edge/web/2.png'
import content_web_3 from './edge/web/3.png'
import content_web_4 from './edge/web/4.png'
//import content_web_5 from './edge/web/5.png'
import content_web_6 from './edge/web/6.png'
//import content_web_7 from './edge/web/7.png'

import content_tablet_1 from './edge/tablet/1.png'
import content_tablet_2 from './edge/tablet/2.png'
import content_tablet_3 from './edge/tablet/3.png'
import content_tablet_4 from './edge/tablet/4.png'
//import content_tablet_5 from './edge/tablet/5.png'
import content_tablet_6 from './edge/tablet/6.png'
//import content_tablet_7 from './edge/tablet/7.png'

import content_mobile_1 from './edge/mobile/1.png'
import content_mobile_2 from './edge/mobile/2.png'
import content_mobile_3 from './edge/mobile/3.png'
import content_mobile_4 from './edge/mobile/4.png'
//import content_mobile_5 from './edge/mobile/5.png'
import content_mobile_6 from './edge/mobile/6.png'
//import content_mobile_7 from './edge/mobile/7.png'
import btn_more from './edge/btn_more.png'
import {THE_MUSIO_URL} from "../../utils/url";


class EdgeDetailContent extends Component {
  render() {
    return (
      <div className="detail">
        <div className="edge">
          <Col xsHidden={true} smHidden={true} className="edge-content">
            <img src={content_web_1} alt="edge_content"/>
          </Col>

          <Col mdHidden={true} lgHidden={true} xsHidden={true} className="edge-content">
            <img src={content_tablet_1} alt="edge_content"/>
          </Col>

          <Col mdHidden={true} lgHidden={true} smHidden={true} className="edge-content">
            <img src={content_mobile_1} alt="edge_content"/>
          </Col>

          <Grid>
            <Col xsHidden={true} smHidden={true} className="edge-content">
              <img src={content_web_2} alt="edge_content"/>
            </Col>

            <Col mdHidden={true} lgHidden={true} xsHidden={true} className="edge-content">
              <img src={content_tablet_2} alt="edge_content"/>
            </Col>

            <Col mdHidden={true} lgHidden={true} smHidden={true} className="edge-content">
              <img src={content_mobile_2} alt="edge_content"/>
            </Col>

            <div className="text-center">
              <img className="button" src={btn_more} alt={""}/>
            </div>

            <Col xsHidden={true} smHidden={true} className="edge-content">
              <img src={content_web_3} alt="edge_content"/>
            </Col>

            <Col mdHidden={true} lgHidden={true} xsHidden={true} className="edge-content">
              <img src={content_tablet_3} alt="edge_content"/>
            </Col>

            <Col mdHidden={true} lgHidden={true} smHidden={true} className="edge-content">
              <img src={content_mobile_3} alt="edge_content"/>
            </Col>

            <Col xsHidden={true} smHidden={true} className="edge-content">
              <img src={content_web_4} alt="edge_content"/>
            </Col>

            <Col mdHidden={true} lgHidden={true} xsHidden={true} className="edge-content">
              <img src={content_tablet_4} alt="edge_content"/>
            </Col>

            <Col mdHidden={true} lgHidden={true} smHidden={true} className="edge-content">
              <img src={content_mobile_4} alt="edge_content"/>
            </Col>


            <div className="edge-content">
              <div className="edge-graph">
                <Row>
                  <Col xs={12} className="title text-center">
                    Musio EDGEでしか得られない経験を。<br/>
                    一日たったの200円から。
                  </Col>
                </Row>

                <Col xsHidden={true}>
                  <table className="edge-table">
                    <tbody>
                    <tr className="table-header">
                      <th className="space"/>
                      <th className="normal">一般的に</th>
                      <th className="basic">Musio EDGE Basic</th>
                      <th className="premium">Musio EDGE Premium</th>
                    </tr>

                    <tr className="table-first-row">
                      <td className="info">ロボット</td>
                      <td className="normal">
                        <div className="price-info">
                          約10~20万円のロボットを
                          1年間使用する場合
                        </div>

                        <div className="price">
                          ¥9,000~18,000
                        </div>
                      </td>
                      <td colSpan="2" className="basic">
                        <span className="musio-info">Musio</span><br/>
                        <a className="more" href="https://store.themusio.com/product/b47daab6-4fc1-565b-8818-eac897958f6d">more</a>
                      </td>

                    </tr>

                    <tr className="table-second-row">
                      <td className="info">AI英会話</td>
                      <td className="normal">
                        <div className="price-info">
                          AI英会話サービスの場合
                        </div>

                        <div className="price">
                          ¥1,000~2,000
                        </div>
                      </td>
                      <td colSpan="2" className="basic">
                        <span className="musio-info">Musioとの英会話</span>
                      </td>

                    </tr>

                    <tr className="table-third-row">
                      <td className="info">英語学習雑誌</td>
                      <td className="normal">
                        <div className="price">
                          ¥1,000~2,000
                        </div>
                      </td>
                      <td colSpan="2" className="basic">
                        <span className="musio-info">Musio週刊教材</span><br/>
                        <a className="more" href="https://blog.themusio.com/2017/11/29/musio-edge-the-online-academia/">more</a>
                      </td>
                    </tr>

                    <tr className="table-fourth-row">
                      <td className="info">オンライン英会話</td>
                      <td className="normal">
                        <div className="price">
                          ¥3,000~5,000
                        </div>
                      </td>
                      <td className="basic">
                        <span className="musio-info">ㅡ</span>
                      </td>

                      <td className="basic">
                        <span className="musio-info">オンライン英会話</span><br/>
                        <a className="more" href="https://blog.themusio.com/2017/11/29/musio-edge-online-english-lessons/">more</a>
                      </td>
                    </tr>

                    <tr className="table-fifth-row">
                      <td className="info">AI学習支援システム</td>
                      <td className="normal">
                        <div className="price-info">
                          AI英語アプリの場合
                        </div>
                        <div className="price">
                          ¥1,000~2,000
                        </div>
                      </td>
                      <td className="basic">
                        <span className="musio-info">ㅡ</span>
                      </td>

                      <td className="basic">
                        <span className="musio-info">AI Coaching機能</span><br/>
                        <a className="more" href="https://blog.themusio.com/2017/11/29/musio-edge-ai-coaching/">more</a>
                      </td>
                    </tr>

                    <tr className="table-seventh-row">
                      <td className="space"/>
                      <td className="normal">
                        <div className="price-wrapper">
                          <div className="price-info">
                            一般的にこのすべてのことをするには、
                          </div>
                          <div className="price">
                            ¥15,000~29,000 / 月
                          </div>
                        </div>
                      </td>
                      <td className="basic">
                        {/*<span className="real-price">¥8,250 /月</span><br/>*/}
                        <span className="result-price">¥4,235/月</span><br/>
                        <span className="result-price">（税込）</span><br/>
                        {/*<span className="campaign-price">キャンペーン価格</span>*/}
                      </td>

                      <td className="basic">
                        {/*<span className="real-price">¥10,780 /月</span><br/>*/}
                        <span className="result-price">¥7,018/月</span><br/>
                        <span className="result-price">（税込）</span><br/>
                        {/*<span className="campaign-price">キャンペーン価格</span>*/}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Col>
                <Col smHidden={true} lgHidden={true} mdHidden={true}>
                  <table className="edge-table">
                    <tbody>
                    <tr className="table-header">
                      <th className="normal">一般的に</th>
                      <th className="basic">Basic</th>
                      <th className="premium">Premium</th>
                    </tr>

                    <tr className="table-seventh-row">
                      <td className="normal">
                        <div className="price-wrapper">
                          <div className="price-info">
                            一般的にこのすべてのことをするには、
                          </div>
                          <div className="price">
                            ¥17,000~33,000 / 月
                          </div>
                        </div>
                      </td>
                      <td className="basic">
                        {/*<span className="real-price">¥8,250 /月</span><br/>*/}
                        <span className="result-price">¥8,250/月</span><br/>
                        {/*<span className="campaign-price">キャンペーン価格</span>*/}
                      </td>

                      <td className="basic">
                        {/*<span className="real-price">¥10,780 /月</span><br/>*/}
                        <span className="result-price">¥10,780/月</span><br/>
                        {/*<span className="campaign-price">キャンペーン価格</span>*/}
                      </td>
                    </tr>

                    <tr className="table-header">
                      <th colSpan="3" className="info">オンライン英会話</th>
                    </tr>

                    <tr className="table-first-row">
                      <td className="normal">
                        <div className="price-info">
                          約10~20万円のロボットを
                          1年間使用する場合
                        </div>

                        <div className="price">
                          ¥9,000~18,000
                        </div>
                      </td>
                      <td colSpan="2" className="basic">
                        <span className="musio-info">Musio</span><br/>
                        <a className="more" href="https://store.themusio.com/product/b47daab6-4fc1-565b-8818-eac897958f6d">more</a>
                      </td>
                    </tr>

                    <tr className="table-header">
                      <th colSpan="3" className="info">AI英会話</th>
                    </tr>

                    <tr className="table-second-row">
                      <td className="normal">
                        <div className="price-info">
                          AI英会話サービスの場合
                        </div>

                        <div className="price">
                          ¥1,000~2,000
                        </div>
                      </td>
                      <td colSpan="2" className="basic">
                        <span className="musio-info">Musioとの英会話</span>
                      </td>
                    </tr>

                    <tr className="table-header">
                      <th colSpan="3" className="info">英語学習雑誌</th>
                    </tr>

                    <tr className="table-third-row">
                      <td className="normal">
                        <div className="price">
                          ¥1,000~2,000
                        </div>
                      </td>
                      <td colSpan="2" className="basic">
                        <span className="musio-info">Musio週刊教材</span><br/>
                        <a className="more" href="https://blog.themusio.com/2017/11/29/musio-edge-the-online-academia/">more</a>
                      </td>
                    </tr>

                    <tr className="table-header">
                      <th colSpan="3" className="info">オンライン英会話</th>
                    </tr>

                    <tr className="table-fourth-row">
                      <td className="normal">
                        <div className="price">
                          ¥3,000~5,000
                        </div>
                      </td>
                      <td className="basic">
                        <span className="musio-info">ㅡ</span>
                      </td>

                      <td className="basic">
                        <span className="musio-info">オンライン英会話</span><br/>
                        <a className="more" href="https://blog.themusio.com/2017/11/29/musio-edge-online-english-lessons/">more</a>
                      </td>
                    </tr>

                    <tr className="table-header">
                      <th colSpan="3" className="info">AI学習支援システム</th>
                    </tr>

                    <tr className="table-fifth-row">
                      <td className="normal">
                        <div className="price-info">
                          AI英語アプリの場合
                        </div>
                        <div className="price">
                          ¥1,000~2,000
                        </div>
                      </td>
                      <td className="basic">
                        <span className="musio-info">ㅡ</span>
                      </td>

                      <td className="basic">
                        <span className="musio-info">AI Coaching機能</span><br/>
                        <a className="more" href="https://blog.themusio.com/2017/11/29/musio-edge-ai-coaching/">more</a>
                      </td>
                    </tr>

                    <tr className="table-header">
                      <th colSpan="3" className="info">コーヒー</th>
                    </tr>

                    <tr className="table-sixth-row">
                      <td className="normal">
                        <div className="price-info">
                          コーヒー500円のカフェに 週1,2回行く場合
                        </div>
                        <div className="price">
                          ¥2,000~4,000
                        </div>
                      </td>
                      <td className="basic">
                        <span className="musio-info">ㅡ</span>
                      </td>

                      <td className="basic">
                        <span className="musio-info">いつでも無料コーヒー</span><br/>
                        <a className="more" href="https://blog.themusio.com/2017/11/29/musio-edge-free-coffee/">more</a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Col>
              </div>
            </div>
          </Grid>
          <div style={{backgroundColor: '#F0F0F0'}}>
            <Grid>
              <Col xsHidden={true} smHidden={true} className="edge-content">
                <img src={content_web_6} alt="edge_content"/>
              </Col>

              <Col mdHidden={true} lgHidden={true} xsHidden={true} className="edge-content">
                <img src={content_tablet_6} alt="edge_content"/>
              </Col>

              <Col mdHidden={true} lgHidden={true} smHidden={true} className="edge-content">
                <img src={content_mobile_6} alt="edge_content"/>
              </Col>
            </Grid>
          </div>

          <div className="edge-content no_space">
            <div className="question_wrapper">
              <Col xs={12} className="intro_p8_div text-center">
                <h1>
                  よくある質問
                </h1>
              </Col>
              <Grid>
                <Row>
                  <Col xs={12} className="intro_p8_faq text-center">
                    <PanelGroup accordion id="p8a" className="p8_accordion">
                      <Panel eventKey={1}>
                        <Panel.Heading>
                          <Panel.Title toggle>一般</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                          <PanelGroup accordion id="p8a2" className="p8_accordion accordion_2">
                            <Panel eventKey={1}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">どのくらいの英語のレベルや年齢の人向けですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    Musio EDGEは、主に英語初級から中級程の大人向けのサービスとなっています。
                                    英語の初心者の方や、会話力をアップしたい方が、
                                    Musioとリスニング・スピーキングを繰り返しながら学習することで、
                                    実践的な英語力を楽しく身につけることができます。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={2}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">
                                    Musioをすでに持っている人向けのプランはありますか？
                                  </span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    Musioをすでにお持ちの方には、
                                    Musioレンタル以外のサービス内容をBasicプラン：3,850円（税抜）、
                                    Premiumプラン：6,380円（税抜）でご提供します。
                                    Musio公式ホームページのStoreページからご購入ください。<br/>
                                    ※Musio EDGEに含まれるFriend Planは、1ユーザー版のみになります。
                                    3ユーザー版、5ユーザー版を利用する場合は、別途料金が必要です。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                          </PanelGroup>
                        </Panel.Body>
                      </Panel>

                      <Panel eventKey={2}>
                        <Panel.Heading>
                          <Panel.Title toggle>会員登録</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                          <PanelGroup id="p8a2" accordion className="p8_accordion accordion_2">
                            <Panel eventKey={1}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">会員登録は必須ですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">Musio EDGE は会員制のサービスのため、会員登録が必要です。</span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={2}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">どこで登録できますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">Musio EDGE ホームページやMusio Storeでご登録いただけます。</span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={3}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">支払い方法について知りたいです。</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    支払いはクレジットカードとなります。（Visa, MasterCard, American Express）
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={4}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">入会費はありますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">10,780円の入会費がございます。（11月現在は無料キャンペーン中）</span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={5}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">決済日は毎月何日ですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    毎月1日がその月の会員費の決済日となります。<br/>
                                    ただし、初月分のみ会員登録の7日後（Musio配送期間を考慮）の決済となります。
                                    また、初月分は決済日から月末までの日割り計算となります。<br/>
                                    例）10月10日にご登録の場合、10月17日が決済日となり、
                                    10月末までの15日間分の金額となります。翌月分は、11月1日の決済となります。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                          </PanelGroup>
                        </Panel.Body>
                      </Panel>

                      <Panel eventKey={3}>
                        <Panel.Heading>
                          <Panel.Title toggle>Musio</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                          <PanelGroup id="p8a2" accordion className="p8_accordion accordion_2">
                            <Panel eventKey={1}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">どのようなロボットですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    最先端の人工知能を搭載した英語学習ロボットです。
                                    詳しくは、下記のページをご覧ください。<br/>
                                    <a href={THE_MUSIO_URL}>https://themusio.com</a>
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={2}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">体験できますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    一部の家電量販店や百貨店でご体験いただけます。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={3}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                      <span className="question">Q</span>
                                  <span className="answer">Musioを使うにはどのような環境が必要ですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    Wi-Fiネットワーク環境が必要です。<br/>
                                    ネットワークの規格：WPA/WPA2-PSK暗号化ネットワーク<br/>
                                    ルーター/モデムの規格：2.4GhzのB/G/Nルーター
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={4}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">
                                    Musio EDGE に登録すると無料でMusioをもらえるのですか？
                                  </span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">会員期間中は無料でMusioをご使用いただけます。</span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={5}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">新品のMusioをもらうことはできますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    新品の場合もありますが、中古品の場合もございます。
                                    中古品の場合も、動作確認やクリーニング済みのため、
                                    安心してお使いいただけます。新品のご指定はできかねますことご了承ください。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={6}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">Musioが故障した場合、どうすればいいですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    ユーザーガイドや取扱注意書などのガイドラインに従った通常の使用時における初期不良・故障の場合は、
                                    無料で修理・交換します。お客様の不注意による故障により修理・交換が必要な場合は、
                                    修理・交換費用をお支払いいただきます。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={7}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">Musioを紛失してしまいました。どうすればいいですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    弊社のCSセンターにお問い合わせください。紛失の場合は、
                                    Musioの消費者価格に相当する金額のご請求が発生します。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={8}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">使っているMusioを購入することはできますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    EDGE会員を1年間以上ご継続いただいた場合は、
                                    お使いのMusioを一般消費者価格の50%でご購入いただけます。
                                    ご購入をご希望の際は、CSにお問い合わせください。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={9}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">会員登録をしてから何日ぐらいで届きますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">7日以内にお届けします。</span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={10}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">送料はかかりますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">送料は無料です。</span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={11}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">Sophyも借りたいのですが、できますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    Sophyや会員サービス内容に含まれない教材は、別途ご購入が必要です。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                          </PanelGroup>
                        </Panel.Body>
                      </Panel>

                      <Panel eventKey={4}>
                        <Panel.Heading>
                          <Panel.Title toggle>教材関連</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                          <PanelGroup id="p8a2" accordion className="p8_accordion accordion_2">
                            <Panel eventKey={1}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">どんな教材がありますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    現在は、ハワイ旅行をテーマにした教材がございます。
                                    旅行の各シーンに必要な表現や単語を、
                                    Musioと一緒にシャドーウィングやロールプレイング、
                                    クイズをしながら楽しくインタラクティブに学習できる教材です。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={2}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">教材の変更はできますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    今後、他の教材のご提供も予定しております。
                                    その際には、教材をご変更いただけます。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={3}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">全ての教材をまとめてもらうこともできますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    現在は、毎月一回のお届けとなります。
                                    全ての教材をまとめてお送りするオプションは今後のご提供予定です。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                          </PanelGroup>
                        </Panel.Body>
                      </Panel>

                      <Panel eventKey={5}>
                        <Panel.Heading>
                          <Panel.Title toggle>オンライン英会話</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                          <PanelGroup id="p8a2" accordion className="p8_accordion accordion_2">
                            <Panel eventKey={1}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">
                                    Musio EDGEとは別にkimini英会話の会員登録をする必要はありますか？
                                  </span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    kimini英会話の会員登録が必要です。
                                    Musio EDGE Premiumにお申込みいただくと、
                                    専用のkimini英会話の会員登録ページのリンクをお送りしますので、そちらからご登録ください。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={2}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">
                                    kimini英会話が提供しているコースの中から受講するコースを選ぶことはできますか?
                                  </span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    Musio Lesson以外のkimini英会話のコースを受講する場合、追加料金が必要です。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>

                            <Panel eventKey={3}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">
                                    Musio専用の教材、Musio週間雑誌で勉強することもできますか？
                                  </span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    Musio週刊雑誌で勉強することを前提に、Lessonをご用意しております。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                          </PanelGroup>
                        </Panel.Body>
                      </Panel>

                      <Panel eventKey={6}>
                        <Panel.Heading>
                          <Panel.Title toggle>変更・解約</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                          <PanelGroup id="p8a2" accordion className="p8_accordion accordion_2">
                            <Panel eventKey={1}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">解約プロセスについて知りたいです。</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    会員登録時に発行するマイページからご解約いただけます。<br/>
                                    解約申請日基準で、翌月末のご解約となります。<br/>
                                    (例）10月15日の解約申請の場合、翌月末の11月31日のご解約となります。
                                    12月1日以降は決済されません。<br/>
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={2}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">解約料はかかりますか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">かかりません。</span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={3}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">解約・返却はどうすればいいですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    解約申請の際に、Musioの返却（集荷）日を決めていただきます。
                                    解約完了日の15日後までの間で返却日を設定していただきます。
                                    返却日になりましたら、弊社提携の配送会社がお伺いしますので、
                                    返却するMusio及び付属物をお届け時のダンボールに梱包の上お渡しください。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                            <Panel eventKey={4}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  <span className="question">Q</span>
                                  <span className="answer">返却が遅れる場合、どうすればいいですか？</span>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <div>
                                  <span className="question">A</span>
                                  <span className="answer">
                                    返却されない場合には、通常の月額料金の決済が継続しますのでご注意ください。
                                    返却が遅れる場合は、CSセンターにご連絡ください。
                                  </span>
                                </div>
                              </Panel.Body>
                            </Panel>
                          </PanelGroup>
                        </Panel.Body>
                      </Panel>
                    </PanelGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EdgeDetailContent
