import {combineReducers} from 'redux'
import ContentsReducer from './ContentsReducer'
import GoodsReducer from './GoodsReducer'
import AuthReducer from './AuthReducer'
import FreeReducer from './FreeReducer'

const reducer = combineReducers({
  ContentsReducer,
  GoodsReducer,
  FreeReducer,
  AuthReducer
});

export default reducer
