import ReactGA from 'react-ga'

export const initGA = (id) => {
  ReactGA.initialize(id);
}

export const pageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  console.log(window.location.pathname)
}

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label:label
  })
}
