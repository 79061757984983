import React, {Component} from 'react'
import './css/AccessTerm.css'
//import PropTypes from 'prop-types'
import Modal from 'react-modal';

//import {Row, Col} from 'react-bootstrap';

class KidsAccessTerm extends Component {

  render() {
    return (
      <Modal
        isOpen={this.props.accessToggle}
        onRequestClose={this.props.handleCloseModal}
        contentLabel="onRequestClose Example"
        className="modal_content modal_edge_rule"
        overlayClassName="modal_overlay"
      >
        <div className="edge_rule_context_div">
          <h1>Musio​ ​ EDGE Kids会員規約</h1>
          AKA株式会社(以下「当社」)は、Musio​ ​ EDGE Kids会員(以下「本会員」)
          にお申込みいただいたお客様に対して、
          以下の条件にしたがって、Musio​ ​ EDGE Kidsサービス (以下「本サービス」)
          を提供します。<br/>

          <h2>第1条(規約の適用)</h2>
          1.​ ​お客様が本サービスを利用するには、Musio​ ​ EDGE Kids会員規約(以下、「本規約」)
          を遵守していただくものとします。<br/>
          2.​ ​当社がMusioストアサイト上で掲載する本サー
          ビスの申込み及び利用に関する条件は、本規 約の一部を構成するものとします。<br/>
          本規約の内容と、前項のサイト上の条件が矛盾する場合
          は、サイト上の条件の規定が優先して適用されるものとします。<br/>
          3.​ ​本サービスに含まれる第三者のサービスを利用するには、
          そのサービスの提供会社の定める 規定を遵守していただくものとします。<br/>

          <h2>第2条(本サービスの内容及び料金)</h2>
          1.​ ​本サービスの内容及び料金は以下の通りとします。<br/>
          <table border="1" className="rule_border">
            <tbody>
            <tr>
              <td> Basic</td>
              <td> Musioレンタル <br/>Friend​ ​ Plan(1ユーザー版) <br/>Musio週刊雑誌<br/> Musio動画教材</td>
              <td>月額8,250円(税込)</td>
            </tr>
            <tr>
              <td> Premium</td>
              <td> Musioレンタル <br/>Friend​ ​ Plan(1ユーザー版) <br/> Musio動画教材 <br/>EDGE​​ Lounge<br/>オンライン英会話(月4回)<br/>Musio​
                ​ AI​ ​ Coaching
              </td>
              <td>月額10,780円(税込)</td>
            </tr>
            </tbody>
          </table>

          2.​ ​ Musioをすでにお持ちのお客様のお客様には、各プランのMusioレンタルを除いたサービス内
          容を、Basic:月額3,850円(税抜)、Premium:月額6,380円(税抜)で提供します。<br/>
          3.​ ​ Musio​ ​ EDGE Kidsサービスに含まれるFriend​ ​ Planは、1ユーザー版のみになります。
          Friend​ ​ Plan の3ユーザー版または5ユーザー版を利用するには、別途該当する
          Friend​ ​ Planに加入する必要が あります。<br/>
          4.​ ​本サービスの利用には、通信環境が必須です。<br/>お客様の利用する通信環境の電波が弱い、ま
          たは通信速度が遅い場合は、本サービスを満足に利用できない可能性がございます。<br/>
          5.​ ​当社は、お客様に対する事前の通知なく、当社の判断により、本サービスの内容の変更また
          は提供条件の変更を行うことができるものとします。<br/>

          <h2>第3条(本サービスの申込み資格)</h2>
          本サービスにお申込みいただけるお客様は、日本に在住する18歳以上の個人に限定します。<br/>

          <h2>第4条(Musio​ ​ ID及びパスワード)</h2>
          1.​ ​本サービスを利用するにあたり、お客様は、Musio会員に登録していただき、Musio​ ​ ID及び
          パスワード(以下、併せて「Musio​ ​ ID等」といいます。<br/>)を取得していただく必要がありま
          す。<br/>
          2.​ ​お客様はMusio​ ​ ID等を第三者に利用させないものとします。<br/>本サービスにおいてMusio​ ​ ID等
          を使用された場合、当該Musio​ ​ ID等を保有するお客様が本サービスを利用したものとみなしま
          す。<br/>
          3.​ ​お客様は、自己のMusio​ ​ ID等またはMusioの盗難、紛失またはこれらが第三者に使用されて
          いることが判明した場合、直ちに当社にその旨を連絡するとともに、当社から指示がある場合
          はこれに従います。<br/>
          4.​ ​お客様は、Musio​ ​ ID等を第三者に譲渡すること、貸与することはできません。<br/>
          5.​ ​当社は、Musio​ ​ ID等の窃用によるお客様の損害またはお客様が第三者に与えた責任を負わな
          いものとします。<br/>

          <h2>第5条(Musio会員利用規約の遵守)</h2>
          本サービスを利用するお客様は、Musio会員利用規約を遵守するものとします。<br/>
          本規約において、Musio会員利用規約と異なる事項を定めたときは、本規約が優先的に適用さ
          れるものとします。<br/>

          <h2>第6条(本サービスへの加入申込み)</h2>
          1.​ ​本サービスへの申込みを希望されるお客様は、当社ウェブサイトにおいて、当社所定の注文
          手続を行ってください。<br/>これに対して当社が承諾したときに、当社とお客様との間の契約が成
          立します。<br/>
          2.​ ​当社は、以下に定める場合にはお客様の申込みに対して承諾せず、また、承諾後に取り消す
          ことがありますが、それらの理由について説明はいたしません。<br/>
          (1)​ ​利用申込みされたお客様が実在しない場合<br/>
          (2)​ ​当社が本サービス利用者への通知・連絡が客観的に不能と判断した場合<br/>
          (3)​ ​お客様が届出ている情報に虚偽またはこれに類する不正確な内容が含まれていることが判明
          した場合<br/>
          (4)​ ​お客様によるクレジットカードの利用について、クレジットカード会社から承認が得られな
          かった場合<br/>
          (5)​ ​ Musio本体を当社指定の信販会社のショッピングクレジットによる分割払いでご購入の申込
          みをしたお客様の申込みが、当該信販会社による審査を通過せず、当該信販会社から当該申込
          みに対する承諾が得られなかった場合<br/>
          (6)​ ​お客様が、本サービスの利用を停止されたことがある等、当社がお客様の利用または本サー
          ビスへの申込みを承諾すべきでないと判断した場合<br/>
          (7)​ ​お客様が18歳未満であることまたは18歳以上の未成年者であって親権者の同意を得ていな
          いことが判明した場合(ショッピングクレジットによる分割払いの申込みについては、お客様
          が未成年者であることが判明した場合)<br/>
          (8)​ ​お客様が、申込みより以前に、当社が提供するサービスにつき当社と契約を締結したことが
          あり、かつ、当社から当該契約を解除したことがある場合<br/>
          (9)​ ​お客様が本サービスと同時に申し込んだMusioが配送不能となった場合<br/>
          (10)​ ​当社の業務遂行上または技術上の支障がある場合<br/>
          (11)​ ​その他当社が不適当と認めた場合<br/>
          3.​ ​当社は、前条で定めた契約成立後は、申込みのキャンセルは受け付けません。<br/>

          <h2>第7条(配送及び引渡し)</h2>
          当社は、商品の配送に要する日数を当社ウェブサイト上に記載します。<br/>ただし、商品の入荷・
          在庫状況その他事由により、当社ウェブサイト上の記載と実際の配送に要する日数が異なる場
          合があります。<br/>
          ユーザーが注文した商品が、注文時に指定された配送先に配送されたことをもって、商品の引
          渡しは完了したものとします。<br/>

          <h2>第8条(不具合)</h2>
          レンタル商品の特性上、商品は現状有姿とし、通常の使用が可能である場合につき、当社は責
          任を負わないものとします。<br/>ただし、ユーザーの責によらず、商品に通常の使用に耐えない不
          具合又は自然故障が発生した場合、当社は、当社が別途定める内容に従い、商品をご返送いた
          だいたうえで、代替品の送付等の対応を行います。<br/>

          <h2>第9条(破損又は紛失等)</h2>
          ユーザーの故意又は過失により商品を破損、紛失し又は第三者により盗難された場合には、通
          常の会員料金に加え、当社が別途定める当該商品の販売代金に相当する金額をご負担いただき
          ます。<br/>また、当社は、当該ユーザーに対するレンタル料金及び販売代金に相当する金額の請求
          権を、自らの裁量により第三者に譲渡することができるものとし、ユーザーは、当該譲渡につ
          き予め異議なくこれを承諾するものとします。<br/>

          <h2>第10条(届出事項等)</h2>
          お客様は、その氏名、住所または当社に届け出たクレジットカードその他当社が指定する事項
          に変更があったときは、当社に対し、速やかに当該変更の内容について通知するものとしま
          す。<br/>

          <h2>第11条(料金の支払い)</h2>
          1.​ ​当社は、お客様に対し、月単位で、本サービスの月額料金を請求します。<br/>ただし、初月に関
          しては、申込日の7日後から月末までの日割り計算とします。<br/>
          2.​ ​本サービスの料金は、申込日の7日後から発生します。<br/>初月のみ、申込日の7日後の請求とな
          り、翌月以降は毎月1日の請求となります。<br/>
          3.​ ​契約の変更や解約が月の途中であったとしても、本サービスの月額料金の日割りはできませ
          ん。<br/>

          <h2>第12条(支払方法)</h2>
          1.​ ​お客様は、本サービスの料金を、当社が指定する日までに、お客様のクレジットカードにて
          支払うものとします。<br/>
          2.​ ​ご利用可能なクレジットカードは、VISA、MasterCard、AMERICAN​ ​ EXPRESSです。<br/>
          3.​ ​各クレジット会社が定める利用規約がある場合、当該規約が適用されます。<br/>

          <h2>第13条(禁止行為等)</h2>
          1.​ ​お客様は下記の事項を行ってはならないものとします。<br/>
          (1)虚偽の情報を本登録情報として登録する行為<br/>
          (2)本規約等に違反する行為<br/>
          (3)本サービスを不正な手段により利用する行為<br/>
          (4)当社または第三者の著作権、肖像権、プライバシーその他の権利、もしくは財産、名誉、信
          用を、侵害・毀損する行為<br/>
          (5)当社または第三者を誹謗中傷または名誉・信用を毀損する行為<br/>
          (6)第三者のMusio​ ​ ID等を複製、複写、公開、販売または貸与等その他一切の処分をする行為
          (但し、本人の承諾が明らかな場合を除きます)<br/>
          (7)本サービスの運営を妨害する行為<br/>
          (8)本サービスの利用を通じた当社の事前の承諾を得ていない営業行為<br/>
          (9)本サービスの利用を通じた政治行為、宗教行為<br/>
          (10)その他、法令または公序良俗に反する行為<br/>
          (11)本人の承諾を得ることのなく第三者になりすます行為<br/>
          (12)前各号に該当するおそれがあると当社が判断する行為<br/>
          (13)その他、当社が本サービスの運営上、不適切と判断する行為<br/>
          2.​ ​当社は、お客様の行為が以下の項目のいずれかに該当する場合、Musio​ ​ ID等の使用停止、本
          サービスの提供拒否、利用制限等をすることができるものとします。<br/>ただし、緊急の場合はこ
          の限りではありません。<br/>
          (1)前項の禁止事項の一つに該当する場合<br/>
          (2)代金等の支払債務の履行遅延または不履行があった場合<br/>
          (3)電話、FAX、Eメールその他の手段によっても、連絡が取れなくなった場合<br/>
          (4)お客様が指定したクレジットカードを使用することができなくなった場合<br/>
          (5)第7条(本サービスへの加入申込み)第2項に定める拒絶事由に該当する場合<br/>
          (6)その他、当社が不適切と判断した場合<br/>

          <h2>第14条(当社による解除)</h2>
          当社は、次に掲げる事由があるときは、本サービス契約を解除することがあります。<br/>この場
          合、当社は、本サービス契約が終了した月までの料金を請求できるものとし、すでにいただい
          た料金は返還しません。<br/>
          (1)前条(禁止行為等)の規定により本サービスの利用が停止された場合において、お客様が当
          該停止の日の翌々月末までに当該停止の原因となった事由を解消しないとき。<br/>ただし、当該停
          止が同条第1項第2号の事由による場合は、当該契約を直ちに解除することがあります。<br/>
          (2)出荷から1週間以内にMusioをお受け取りいただけないとき<br/>
          (3)お客様が反社会的勢力またはその関係者であることが判明したとき<br/>

          <h2>第15条(お客様都合等による本サービスの終了)</h2>
          1.​ ​お客様は、当社所定のお手続きにより、本サービスの解除の申込みを行うことができます。<br/>
          本サービスの解除の申込みの翌月末に、本サービス契約は終了します。<br/>(本サービスの解除の
          申込みの翌月分までの月額料金が発生します。)<br/>
          2.​ ​本サービスの解除の申込み時に、お貸出中のMusio及び付属品の返却日を、本サービスの解
          除の翌月15日までの日から設定していただきます。<br/>返却日に弊社提携の配送会社がお伺いしま
          すので、Musio及び付属品をお渡しください。<br/>本サービスの解除の翌月15日までに、お貸し出
          し中のMusio及び付属品が返却されない場合、本サービス契約は終了せず、通常の月額料金の
          請求が継続します。<br/>
          3.​ ​ Musioレンタルをされていないお客様の場合、本条第2項は適用されません。<br/>

          <h2>第16条(30日間全額返金保証)</h2>
          1.
          30日間全額返金保証キャンペーン中に本サービスにお申込みのお客様から返金の申し出があった場合、次の各項に従って、お客様に対して支払い済みの諸費用の全額を返還します。この場合、お客様は本会員を退会したものとみなします。<br/>
          2. 前項に定める返金の手続きは、電子メールまたは電話で行うものとします。<br/>
          3. お客様による当社への返金の申し出は、お客様の入会日から30日以内に手続きを行うものとして、それ以降は返金の申し出を行うことができないものとします。<br/>
          4. 本条に基づいて支払い済みの諸費用の返金を受けたお客様は、それ以降本30日間全額返金保証キャンペーンを再度利用することはできないものとします。<br/>
          5.
          前各項の規定に関わらず、本サービスに含まれるお貸し出し中の商品を、お客様の故意又は過失により破損、紛失し又は第三者により盗難された場合や当社に返却されない場合には、当社が別途定める当該商品の販売代金に相当する金額をご負担いただきます。<br/>
          6. 前各項の規定に関わらず、本サービスの内容に含まれない当社が提供するサービス・商品については、第1項に基づく全額返金の対象外です。<br/>

          <h2>第17条(お問合せ)</h2>
          お客様からのお問合せは、当社のお問い合わせ窓口までお願いいたします。<br/>

          <h2>第18条(お客様情報の取扱い)</h2>
          お客様の個人情報については、当社規定の「Musioプライバシーポリシー」に基づき、適切な
          利用及び厳重な管理を行います。<br/>

          <h2>第19条(権利義務の譲渡制限等)</h2>
          お客様は、本サービス契約によって得た契約上の権利義務について、第三者に譲渡しまたはそ
          れに準ずる行為をすることはできないものとします。<br/>

          <h2>第20条(本サービスの内容の変更、廃止)</h2>
          1.​ ​当社は、当社の都合により、本サービスの内容や提供条件の変更を行うことができるものと
          します。<br/>お客様の契約内容や提供条件に影響を及ぼす場合、当社はお客様に対して事前に通知
          するものとします。<br/>
          2.​ ​当社は、当社の都合により、本サービスの全部または一部を廃止することがあります。<br/>当社
          は、前項の規定により本サービスの全部または一部を廃止するときは、お客様に対し、事前に
          その旨を通知します。<br/>サービスの全部または一部が廃止されたときは、当該廃止の日に本サー
          ビス契約が解除されたものとします。<br/>

          <h2>第21条(免責事項)</h2>
          1.​ ​当社は、お客様が本サービスの利用に関して被った損害について、責任を負いません。<br/>ただ
          し、当社に故意または重過失がある場合には、この限りではありません。<br/>
          2.​ ​本規約に基づく当社の責任は、日本国法令を遵守し、本サービスをお客様に提供する範囲に
          限定されるものとします。<br/>

          <h2>第22条(規約の変更)</h2>
          1.​ ​当社は、本規約を変更することがあります。<br/>本規約が変更された後の本サービスに係る料金
          その他の提供条件は、変更後の本規約によります。<br/>
          2.​ ​本規約を変更するときは、当社は、当該変更により影響を受けることとなるお客様に対し、
          事前にその内容について通知します。<br/>

          <h2>第23条​ ​(事業譲渡等の場合の取扱い)</h2>
          当社が本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用
          契約上の地位、権利及び義務並びに登録情報その他の本サービスのユーザーに関する情報を当
          該事業譲渡の譲受人に譲渡できるものとし、ユーザーは、かかる譲渡につき本項において予め
          同意したものとみなします。<br/>本項にいう事業譲渡には、当社が消滅会社又は分割会社となる合
          併又は会社分割等による包括承継を含むものとします。<br/>

          <h2>第24条(協議解決の原則及び管轄裁判所)</h2>
          本規約等に定めのない事項または本規約等に関する疑義が生じた場合は、会員と当社との間で
          誠意をもって協議解決するものとします。<br/>協議をしても解決しない場合、東京地方裁判所を専
          属的合意管轄裁判所とします。<br/>
        </div>
        <div className="edge_rule_confirm">
          <button className="musio-btn" onClick={this.props.handleCheckModal}>同意</button>
        </div>
      </Modal>
    );
  }
}


export default KidsAccessTerm
