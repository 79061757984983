import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-app-polyfill/ie11';
//import registerServiceWorker, {unregister} from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom';
import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import reducer from './reducers/Reducer'
import ReduxThunk from 'redux-thunk'
import {CookiesProvider} from 'react-cookie';

let store = createStore(reducer, applyMiddleware(ReduxThunk));
//unregister();

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root'));
//registerServiceWorker();
