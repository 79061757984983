import React from 'react'
//import PropTypes from 'prop-types'
import {Row, Col, Grid} from 'react-bootstrap'

import ContentsList from './../public_components/ContentsList'
//import Content from './../public_components/Content'

import FreeContentsBanner from './img/home_free_contents.png'
import {get_free} from './../../actions/free'
import {connect} from 'react-redux'
import ContentCategory from './../public_components/ContentCategory'
//import ReactLoading from 'react-loading';
import {Link} from 'react-router-dom';
import {trackEvent} from "../../utils/tracking"

class FreeContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: 0
    };

    this.onCategoryClick = this.onCategoryClick.bind(this)
  }

  componentWillMount() {
    if (this.props.free_contents[this.state.tag].data.length === 0) {
      this.props.getFree(this.state.tag, true)
    }
  }

  componentDidMount() {
  }

  onCategoryClick(i) {
    this.setState({
      ...this.state,
      tag: i
    });
    if (this.props.free_contents[i].data.length === 0)
      this.props.getFree(i, false)
  }

  render() {
    return (
      <div style={{marginTop: 50}}>
        <Grid className="title-grid">
          <Row>
            <Col className="title-col" xs={12}>
              <div className="free_banner_img">
                <img src={FreeContentsBanner} alt="content_title"/>
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <ContentCategory onCategoryClick={this.onCategoryClick}/>
          <ContentsList contents={this.props.free_contents[this.state.tag].data.slice(0, 6)}/>
          <Row>
            <Link to={{pathname: "/contents", search: 'type=0'}} onClick={() => {trackEvent("HOME", "Click from Home: FREE contents", "")}}>
              <Col className="seeall_button" xsOffset={4} xs={4}>
                <div className="text-center">
                  See All
                </div>
              </Col>
            </Link>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    free_contents: state.FreeReducer.free_contents
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getFree: (tag, is_new) => dispatch(get_free(tag, is_new))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeContents);
