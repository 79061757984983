let initialState = {
  jwt: undefined,
  // jwt:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc19pbnN0aXR1dGUiOmZhbHNlLCJpZCI6MTU0OTUsImlzX2FkbWluIjp0cnVlLCJleHAiOjE2NzE2MDQ4MDksImlhdCI6MTY3MTUxODQwOSwiZW1haWwiOiJzaGVycnlAYWthY29ycC5qcCIsImNsaWVudF9rZXkiOiJvMDdyRUU4TFltc2dHVEJrdlVPUWZubGI5QWZLQjVCZXFpczltT0RqOUZzN2ZkRk4zSDBiTUUxaUpuOVZTaFVKNHJpQU9Yd2J5ZjhxOUlsdG5uclppMWRHcjlvUzJrVjM5V1hEN1R5N2JlWndrbTR1ZzIzazB1TlI1TFhMbGpFUyJ9.P9ZOqjnHMTW5FLp5XqqgfAA8eztHeDkQcKHUh0w8slU',
  isAuthenticated: true,
  pending: false
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_AUTH_PENDING':
      return {
        ...state,
        pending: true,
        error: false,
        isAuthenticated: true
      };
    case 'GET_AUTH_SUCCESS':
      return {
        ...state,
        pending: false,
        error: false,
        jwt: action.jwt,
        isAuthenticated: true
      };
    case 'GET_AUTH_ERROR':
      return {
        ...state,
        pending: false,
        error: true,
        isAuthenticated: false
      };

    case 'GET_AUTH_LOGOUT':
      return {
        jwt: undefined,
        pending: false,
        error: false,
        isAuthenticated: false
      };

    case 'GET_AUTH_NO_SESSION':
      return {
        isAuthenticated: false,
        pending: false,
        error: true
      };

    default:
      return state
  }
};

export default AuthReducer
