import React from 'react';
import axios from 'axios';
import {AUTH_LOGIN, STORE_API_URL, STORE_REST_CART, USERMODEL_URL} from '../../utils/url';
import './css/DetailInfo.css';
import {Row, Col, Grid, DropdownButton, MenuItem, Glyphicon, Carousel} from 'react-bootstrap';
import Loading from '../../utils/Loading';
import {LOG} from "../../utils/Log";
import {trackEvent} from "../../utils/tracking"

//import { connect } from 'react-redux'
//import AccessTerm from './AccessTerm'
//import PropTypes from 'prop-types'

const TAX_RATE=1.1

function round10(num){
  return Math.round(num / 10) * 10
}

class EdgeDetailInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_options: [],
      selected_picture: 0,
      pending: false
    };
    this.onAddCart = this.onAddCart.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
    this.onOptionDelete = this.onOptionDelete.bind(this);
    this.onPictureSelect = this.onPictureSelect.bind(this);
    this.beforeCartAddCheck = this.beforeCartAddCheck.bind(this);
  }

  onOptionSelect(eventKey, event) {
    LOG(event);

    let selected_options = this.state.selected_options;
    let now_option = undefined;
    let index = 0;
    for (let option of this.props.product.option) {
      let tmp = option.find(e => {
        return e.id === eventKey;
      });

      if (tmp !== undefined) {
        now_option = tmp;
        now_option.index = index;
      }
      index++
    }
    let is_resist;
    is_resist = selected_options.find(e => {
      return e.id === eventKey;
    });

    if (is_resist !== undefined) {
      return;
    }

    now_option.amount = 1;

    is_resist = selected_options.findIndex(e => {
      return e.index === now_option.index;
    });

    if (is_resist !== -1) {
      selected_options[is_resist] = now_option;
    } else {
      selected_options.push(now_option);
    }

    this.setState({
      ...this.state,
      selected_options: selected_options
    })
  }

  onOptionDelete(eventKey) {
    let selected_options = this.state.selected_options;
    let now_option_index = selected_options.findIndex(e => {
      return e.id === eventKey;
    });
    selected_options.splice(now_option_index, 1);

    this.setState({
      ...this.state,
      selected_options: selected_options
    })
  }

  onPictureSelect(key) {
    this.setState({
      ...this.state,
      selected_picture: key
    })
  }

  beforeCartAddCheck(buy = false) {
    if (!this.props.isAuthenticated) {
      alert('Please Login');
      trackEvent("PRODUCT", "Error: Add to cart", "User is not logged in")
      window.location = USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
      return;
    }

    if (this.state.selected_options.length !== this.props.product.option.length) {
      alert('Please select options!');
      trackEvent("PRODUCT", "Error: Add to cart", "Options are not selected")
      return;
    }

    this.props.handleOpenModal(buy)
  }

  onAddCart = (buy = false) => {
    this.setState({
      ...this.state,
      pending: true
    });

    let option = [];
    this.state.selected_options.map((item) => {
      let option_obj = {};
      option_obj["id"] = item["id"];
      option_obj["count"] = item["amount"];
      option_obj["name"] = item["name"];
      option_obj["price"] = item["price"];
      return option.push(option_obj);
    });

    axios.post(STORE_API_URL + STORE_REST_CART, {
      product_id: this.props.product.id,
      option: option,
    }, {
      headers: {"Authorization": "Bearer " + this.props.jwt}
    })
      .then(() => {
        this.setState({
          ...this.state,
          pending: false
        });
        if (buy) {
          trackEvent("PRODUCT", "Buy: " + this.props.product.id, "")
          this.props.history.push('/cart')
        } else {
          alert("Success, add in Cart")
          trackEvent("PRODUCT", "Add to cart: " + this.props.product.id, "")
        }
      })
      .catch((error) => {
          LOG(error)
          trackEvent("PRODUCT", "Error: Add to cart " + this.props.product.id, "Error: " + error)
      })
  };

  render() {
    return (
      <div>
        {this.state.pending && <Loading/>}
        <Grid className="detail">
          <Row>
            <Col className="images" md={2} xsHidden={true} smHidden={true}>
              {this.props.product.images.map((picture, i) => {
                return (<img key={"picture" + i} alt="product_image" onClick={() => this.onPictureSelect(i)}
                             className={this.state.selected_picture === i ? 'select' : 'default'} src={picture}/>
                )
              })
              }
            </Col>
            <Col className="main_image" md={5} xsHidden={true} smHidden={true}>
              <img alt="product_select_image" src={this.props.product.images[this.state.selected_picture]}/>
            </Col>
            <Col className="image_carousel" xs={12} mdHidden={true} lgHidden={true}>
              <Carousel interval={1000000}>
                {
                  this.props.product.images.map((picture, i) => {
                    return (
                      <Carousel.Item key={"Carousel" + i}>
                        <img alt="product_image" src={picture}/>
                      </Carousel.Item>
                    )
                  })
                }
              </Carousel>
            </Col>
            <Col className="info" md={5} xs={12}>
              {this.props.product.badges.map((badge, i) => {
                return (<div className="best" key={"badge" + i}>{badge}</div>)
              })}

              <div className="title">{this.props.product.name}</div>
              <div className="tag_wrapper">
                {this.props.product.tags.map(tag => {
                  return (<div className="tag">{tag}</div>)
                })}
              </div>
              <div className="price">¥{round10(this.props.product.price*TAX_RATE).toLocaleString()} / 月</div>
              {this.props.product.option.length > 0 ? (this.props.product.option.map((selector, i) => {
                  return (
                    <DropdownButton key={i} title="商品のオプションを選択してください。" id="dropdown-size-medium"
                                    onSelect={this.onOptionSelect}>
                      {
                        selector.map((option, i) => {
                          return (<MenuItem key={i} eventKey={option.id}>
                              {`${option.name} / +${round10(option.price*TAX_RATE).toLocaleString()}¥ （税込）`}
                            </MenuItem>
                          )
                        })
                      }
                    </DropdownButton>
                  )
                })
              ) : (
                <div/>
              )
              }
              {
                this.state.selected_options.map((option, i) => {
                  return (
                    <div key={i} className="option_wrapper">
                      <div className="option">
                        {`${option.name} / +${round10(option.price*TAX_RATE).toLocaleString()}¥`}
                      </div>
                      <div className="amount">
                        <div className="minus"
                             style={{backgroundColor: '#DDDDDD', border: 'solid 1px #DDDDDD'}}>
                          <Glyphicon glyph="glyphicon glyphicon-minus"/>
                        </div>
                        <div className="number"><span>{option.amount}</span></div>
                        <div className="plus"
                             style={{backgroundColor: '#DDDDDD', border: 'solid 1px #DDDDDD'}}>
                          <Glyphicon glyph="glyphicon glyphicon-plus"/>
                        </div>
                      </div>
                      <div className="remove" onClick={() => this.onOptionDelete(option.id)}>
                        <Glyphicon glyph="glyphicon glyphicon-remove"/>
                      </div>
                    </div>
                  )
                })
              }

              <div className="etc">
                <div className="blog">Blog</div>
                <div className="shop" onClick={() => {
                  if (this.props.product.state === 'default')
                    this.beforeCartAddCheck()
                }}
                     style={this.props.product.state === 'soldout' ||
                     this.props.product.state === 'prepare'
                       ? {cursor: 'not-allowed'} : {}}>
                  <Glyphicon glyph="glyphicon glyphicon-shopping-cart"/>
                </div>
                <div className="buy" style={this.props.product.state === 'soldout' ||
                this.props.product.state === 'prepare'
                  ? {cursor: 'not-allowed'} : {}}
                     onClick={
                       () => {
                         if (this.props.product.state === 'default')
                           this.beforeCartAddCheck(true)
                       }
                     }>
                  {
                    this.props.product.state === 'soldout' &&
                    <span>Sold Out</span>
                  }

                  {
                    this.props.product.state === 'default' &&
                    <span>Buy</span>
                  }

                  {
                    this.props.product.state === 'prepare' &&
                    <span>Coming Soon</span>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

/*EdgeDetailInfo.propTypes = {
  product: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      option: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            id: PropTypes.string.isRequired
        })
      ),
      images: PropTypes.array.isRequired,
      tags: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      badges: PropTypes.array.isRequired,
      category: PropTypes.string.isRequired
    }
  )
};*/

export default EdgeDetailInfo;
