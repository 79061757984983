import React from 'react'
import {Row, Col} from 'react-bootstrap'
import PropTypes from 'prop-types'

class ContentCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {now_category: 0};

    this.categoryClick = this.categoryClick.bind(this)
  }

  categoryClick(i) {
    this.setState({
      now_category: i
    });

    this.props.onCategoryClick(i)
  }

  render() {
    return (
      <Row className="content-category">
        <Col xs={12}>
            <span className={this.state.now_category === 0 ? "highlight" : ""}
                  onClick={() => this.categoryClick(0)}>
              全年齢
            </span>
          <span className={this.state.now_category === 1 ? "highlight" : ""}
                onClick={() => this.categoryClick(1)}>
              幼児期
            </span>
          <span className={this.state.now_category === 2 ? "highlight" : ""}
                onClick={() => this.categoryClick(2)}>
              小学生
            </span>
          <span className={this.state.now_category === 3 ? "highlight" : ""}
                onClick={() => this.categoryClick(3)}>
              中高生
            </span>
          <span className={this.state.now_category === 4 ? "highlight" : ""}
                onClick={() => this.categoryClick(4)}>
              大人
            </span>
        </Col>
      </Row>
    );
  }
}

ContentCategory.propTypes = {
  onCategoryClick: PropTypes.func.isRequired
};
export default ContentCategory;
