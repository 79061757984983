import React from 'react'
//import { Link } from 'react-router-dom';
import {Row, Col, Grid} from 'react-bootstrap'
import './css/AdminOrder.css'
import axios from 'axios'
import {STORE_ADMIN_ORDER, STORE_API_URL} from '../../utils/url'
//import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
//import Dropzone from 'react-dropzone'
import {connect} from 'react-redux'
import {LOG} from "../../utils/Log";


const TAX_RATE=1.1

function round1(num){
  return Math.round(num / 1) * 1
}
class AdminOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_admin: false,
      loading: false,

      order_list: [],
      next_token: false,
      type: "oneshot",
      check_1: false,
      check_2: false,
      check_3: false,
      check_4: false,
    }
  }

  componentWillMount() {
    LOG("AdminOrder @ componentWillMount");
    this.setState({
      loading: true,
    });

    axios.get(STORE_API_URL + STORE_ADMIN_ORDER, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        this.setState({
          order_list: response.data.data,
          next_token: response.data.next_token,
          is_admin: true,
          loading: false,
        })
        console.log(this.state.order_list)
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loading: false,
        })
      })
  }

  componentDidMount() {
    LOG("AdminOrder @ componentDidMount");
  }

  onChangeType = (type) => {
    LOG("onChangeType @ type : " + type);
    this.setState({
      loading: true,
    });
    let params = {
      type: type,
    };
    let filters = "";
    if (this.state.check_1) filters += "contents,";
    if (this.state.check_2) filters += "free,";
    if (this.state.check_3) filters += "musio&friends,";
    if (this.state.check_4) filters += "goods,";
    if (filters !== "") {
      filters = filters.slice(0, filters.length - 1);
      params["filter"] = filters;
    }
    axios.get(STORE_API_URL + STORE_ADMIN_ORDER, {
      params: params,
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        LOG(response.data.data);
        this.setState({
          type: type,
          order_list: response.data.data,
          next_token: response.data.next_token,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  };
  handleCheck = (val) => {
    LOG("handleCheck @ val : " + val);
    this.setState({
      loading: true,
    });
    let params = {
      type: this.state.type,
    };

    //this.state[val] = !this.state[val];
    let filters = "";
    if (this.state.check_1) filters += "contents,";
    if (this.state.check_2) filters += "free,";
    if (this.state.check_3) filters += "musio&friends,";
    if (this.state.check_4) filters += "goods,";
    if (filters !== "") {
      filters = filters.slice(0, filters.length - 1);
      params["filter"] = filters;
    }

    LOG(filters);
    axios.get(STORE_API_URL + STORE_ADMIN_ORDER, {
      params: params,
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        LOG(response.data.data);
        this.setState({
          type: this.state.type,
          order_list: response.data.data,
          next_token: response.data.next_token,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  };
  onClickMoreBtn = () => {
    LOG("onClickMoreBtn");
    this.setState({
      loading: true,
    });

    let params = {
      start_token: this.state.next_token,
      type: this.state.type,
    };

    let filters = "";
    if (this.state.check_1) filters += "contents,";
    if (this.state.check_2) filters += "free,";
    if (this.state.check_3) filters += "musio&friends,";
    if (this.state.check_4) filters += "goods,";
    if (filters !== "") {
      filters = filters.slice(0, filters.length - 1);
      params["filter"] = filters
    }

    axios.get(STORE_API_URL + STORE_ADMIN_ORDER, {
      params: params,
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        this.setState({
          order_list: [...this.state.order_list, ...response.data.data],
          next_token: response.data.next_token,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  };

  render() {
    LOG("AdminOrder @ render");

    if (this.state.is_admin === false && this.state.loading === false)
      return (<div><h1> unauthorized </h1></div>);

    return (
      <div>
        {this.state.loading &&
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>Loading...</span>
          </div>
        </div>
        }
        <Grid className="admin_grid">
          <Row>
            <Col xs={12} className="category_bar type_bar">
              <hr/>
              <span style={{display: "block", fontWeight: "bold", fontSize: "16px"}}> Type </span>
              <button onClick={() => this.onChangeType("oneshot")}
                      className={`${this.state.type === "oneshot" ? "active" : " "}`}>一括払い/일시불 상품
              </button>
              <button onClick={() => this.onChangeType("edge")}
                      className={`${this.state.type === "edge" ? "active" : " "}`}>Edge商品/Edge상품
              </button>
              <button onClick={() => this.onChangeType("installment")}
                      className={`${this.state.type === "installment" ? "active" : " "}`}>分割払い/할부상품
              </button>
              <button onClick={() => this.onChangeType("edge_refund")}
                      className={`${this.state.type === "edge_refund" ? "active" : " "}`}>Edge解約商品/Edge 해지상품
              </button>
              <span style={{display: "block", fontWeight: "bold", fontSize: "16px"}}> Filter </span>
              <div className="filter_cate">
                <input type="checkbox" onChange={() => this.handleCheck("check_1")}
                       defaultChecked={this.state.check_1}/>有料 Contents / 유료 컨텐츠
              </div>
              <div className="filter_cate">
                <input type="checkbox" onChange={() => this.handleCheck("check_2")}
                       defaultChecked={this.state.check_2}/>無料 Contents / 무료 컨텐츠
              </div>
              <div className="filter_cate">
                <input type="checkbox" onChange={() => this.handleCheck("check_3")}
                       defaultChecked={this.state.check_3}/> Musio & Friends
              </div>
              <div className="filter_cate">
                <input type="checkbox" onChange={() => this.handleCheck("check_4")}
                       defaultChecked={this.state.check_4}/> Goods
              </div>
              <hr/>
            </Col>
            <Col xs={12} className="admin_container">
              <div className="scroll_div">
                {this.state.order_list.map((item, index) => (
                  <div className="admin_order_box" key={"box" + index}>
                    <img src={item.product_img} alt={""}/>
                    <div className="item_info_box">
                      <span className="item_name">{item.product_name}</span>
                      <span>count: {item.count}</span>
                      { <span>price: {(item.price==0 && item.option[0]!=undefined) ?round1(item.option[0].price*TAX_RATE)
                      : (item.price==0&&item.option[0]==undefined&&item.option[2]!=undefined)?round1(item.option[2].price*TAX_RATE)
                      :round1(item.price*TAX_RATE)}</span> }
                    </div>
                    <div key={"box" + index} className="item_name_box">
                      <span className="item_date">{item.create_date}</span>
                      <span className="item_email">{item.user_email}</span>
                      {
                        item.option.map((option, i) => (
                          <span key={i} className="item_item">{option.name} * {option.count}</span>
                        ))
                      }
                    </div>
                    {item.address !== null &&
                    <div className="item_address_box">
                      Name:
                      <span className="address_name">{item.address.last_name} {item.address.first_name}</span><br/>
                      <hr/>
                      Address: <span className="address_item">{item.address.address_state}</span>
                      <span className="address_item">{item.address.address_city}</span>
                      <span className="address_item">{item.address.address_line}</span>
                      <span className="address_item">{item.address.address_line2}</span>
                      <hr/>
                      Zip: <span className="address_item">{item.address.address_zip}</span>
                      <hr/>
                      Phone: <span className="address_item">{item.address.phone}</span>
                      <hr/>
                      { item.cancel_data !== undefined && item.cancel_data !== null &&
                        <div>
                          <span style={{display: 'inline-block'}}>Return date: </span>
                          <span className="address_item">&nbsp;{item.cancel_data.request_date}</span>
                          <span className="address_item">&nbsp;{item.cancel_data.time}</span>
                        </div>
                      }
                    </div>
                    }
                  </div>
                ))}
                {this.state.next_token !== null && <button className="more_btn" onClick={this.onClickMoreBtn}> More </button>}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrder);
