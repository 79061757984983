import React from 'react'
//import PropTypes from 'prop-types'
import {Row, Col, Grid} from 'react-bootstrap'
//import Goods from './../public_components/Goods'
import GoodsList from './../public_components/GoodsList'
import GoodsTitle from './img/home_goods_title.png'
import {connect} from 'react-redux'
import {get_goods} from './../../actions/goods'

import {Link} from 'react-router-dom';
import {trackEvent} from "../../utils/tracking"

class HomeGoods extends React.Component {

  /*constructor(props){
    super(props);
  }*/

  componentWillMount() {
    if (this.props.goods.length === 0)
      this.props.getGoods(true)
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <Grid className="title-grid">
          <Row>
            <Col xs={12} className="title-col">
              <div className="banner_title">
                Goods
              </div>

              <div className="banner_img">
                <img src={GoodsTitle} alt="goods_title"/>
              </div>
            </Col>
          </Row>
        </ Grid>
        <Grid>
          <GoodsList goods={this.props.goods.slice(0, 12)}/>

          <Row>
            <Link to="/goods" onClick={() => {trackEvent("HOME", "Click from Home: goods", "")}}>
              <Col className="seeall_button" xsOffset={4} xs={4}>
                <div className="text-center">
                  See All
                </div>
              </Col>
            </Link>
          </Row>

        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    goods: state.GoodsReducer.goods
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getGoods: (is_new) => dispatch(get_goods(is_new))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeGoods);
