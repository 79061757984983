import React from 'react'
import axios from 'axios'
import {connect} from 'react-redux'
//import { Link } from 'react-router-dom';
import {Row, Col, Grid, Button} from 'react-bootstrap'

import './css/Activation.css'
import Footer from '../../footer/Footer'

import {STORE_API_URL, USERMODEL_URL, AUTH_LOGIN, STORE_REST_CONTENT_ACTIVATION} from '../../utils/url'
import {LOG} from "../../utils/Log";

import instruction_box from './img/web/rectangle-11.png'
import success_box from './img/web/rectangle-12.png'
import musio_img_web from './img/web/4.png'
import musio_img_tablet from './img/tablet/drawable-xxxhdpi/4.png'
import musio_img_mobile from './img/mobile/4.png'
import musio_success_web from './img/web/4-2.png'
import musio_success_tablet from './img/tablet/drawable-xxhdpi/4-2.png'
import musio_success_mobile from './img/mobile/4-2.png'
import instruction_web from './img/web/invalid-name.png'
import instruction_tablet from './img/tablet/drawable-xxhdpi/invalid_name.png'
import instruction_mobile from './img/mobile/invalidName.png'
import success_web from './img/web/mus.png'
import success_tablet from './img/tablet/drawable-xxhdpi/mus.png'
import success_mobile from './img/mobile/mus.png'


class SBActivation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActivated: 0,
      activationCode: ""
    }
  }

  componentWillMount() {
    if (!this.props.isAuthenticated) {
      window.location = USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleActivationCodeSubmit = (event) => {
    event.preventDefault();

    if(this.state.activationCode.length < 16) {
      alert("16桁のコードを入力してください")
    }
    else {
      axios.post(STORE_API_URL + STORE_REST_CONTENT_ACTIVATION, {
        activation_code: this.state.activationCode
      }, {
        headers: {
          "Authorization": "Bearer " + this.props.jwt
        },
      })
      .then((response) => {
        LOG("SBActivation @ handleActivationCodeSubmit , STORE_REST_CONTENT_ACTIVATION , response : " + JSON.stringify(response));
        if(response.data.status) {
          this.setState({
            isActivated: 1,
          });
          setTimeout(() => {
            this.props.history.push('/')
          }, 1500);
        }
        else {
          if(response.data.message === 'Member doesn\'t exist') {
            alert("Please select a member through Member app in your Musio device then open EDU app")
          }
          else {
            alert("このコードは有効ではありません")
          }
          this.setState({
            activationCode: ""
          });
        }
        //this.props.history.push('/')
      })
      .catch((error) => {
        LOG("SBActivation @ handleActivationCodeSubmit , STORE_REST_CONTENT_ACTIVATION , error : " + error);
      })
    }
  }

  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      activationCode: event.target.value
    })
  }

  render() {
    let content;
    if (this.state.isActivated === 0) {
      content = (
        <div>
          <Grid>
            <Row>
              {/*image musio and instruction*/}
              <div className="musio-icon-wrapper">
                <div>
                  <Col xs={4}></Col>
                  <Col xs={4} xsHidden={true} smHidden={true}>
                    <img src={musio_img_web} alt="musio" />
                    <img src={instruction_box} alt="instruction-box" className="instruction-box" />
                    <img src={instruction_web} alt="instruction-word" className="instruction-word"/>
                  </Col>
                  <Col sm={4} xsHidden={true} mdHidden={true} lgHidden={true}>
                    <img src={musio_img_tablet} alt="musio" />
                    <img src={instruction_box} alt="instruction-box" className="instruction-box" />
                    <img src={instruction_tablet} alt="instruction-word" className="instruction-word"/>
                  </Col>
                  <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true}>
                    <img src={musio_img_mobile} alt="musio" />
                    <img src={instruction_box} alt="instruction-box" className="instruction-box" />
                    <img src={instruction_mobile} alt="instruction-word" className="instruction-word"/>
                  </Col>
                </div>
              </div>
            </Row>

            {/*input field*/}
            <Row>
              <div className="form-wrapper">
                <Col xs={3} xsHidden={true}>
                  <div className="input-sign">コード入力</div>
                </Col>
                <Col xs={9} xsHidden={true}>
                  <input required className="activation-field"  type="text" maxLength="16" placeholder="16桁のコード" value={this.state.activationCode} onChange={this.handleInputChange.bind(this)}/>
                  <Button className="activation-btn" onClick={this.handleActivationCodeSubmit}>確認</Button>
                </Col>

                <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
                  <div className="input-sign">コード入力</div>
                </Col>
                <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
                  <input required className="activation-field" type="text" maxLength="16" placeholder="16桁のコード" value={this.state.activationCode} onChange={this.handleInputChange.bind(this)}/>
                  <Button className="activation-btn" onClick={this.handleActivationCodeSubmit}>確認</Button>
                </Col>
              </div>
            </Row>
          </Grid>
        </div>
      ) }
      else {
        content = (
          <div>
            <Grid>
              <Row>
                {/*image musio and instruction*/}
                <div className="musio-icon-wrapper">
                  <div>
                    <Col xs={4}></Col>
                    <Col xs={4} xsHidden={true} smHidden={true}>
                      <img src={musio_success_web} alt="musio" />
                      <img src={success_box} alt="instruction-box" className="instruction-box" />
                      <img src={success_web} alt="success-word" className="success-word"/>
                    </Col>
                    <Col sm={4} xsHidden={true} mdHidden={true} lgHidden={true}>
                      <img src={musio_success_tablet} alt="musio" />
                      <img src={success_box} alt="instruction-box" className="instruction-box" />
                      <img src={success_tablet} alt="success-word" className="success-word"/>
                    </Col>
                    <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true}>
                      <img src={musio_success_mobile} alt="musio" />
                      <img src={success_box} alt="instruction-box" className="instruction-box" />
                      <img src={success_mobile} alt="success-word" className="success-word"/>
                    </Col>
                  </div>
                </div>
              </Row>

              {/*input field*/}
              <Row>
                <div className="form-wrapper">
                  <Col xs={3} xsHidden={true}>
                    <div className="input-sign" style={{color:"#A9A9A9"}}>コード入力</div>
                  </Col>
                  <Col xs={9} xsHidden={true}>
                    <input disabled className="activation-field" style={{color:"#A9A9A9"}} type="text" maxLength="16" placeholder="" />
                    <Button className="activation-btn" style={{backgroundColor:"#A9A9A9"}} disabled>確認</Button>
                  </Col>

                  <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
                    <div className="input-sign" style={{color:"#A9A9A9"}}>コード入力</div>
                  </Col>
                  <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
                    <input disabled className="activation-field" style={{color:"#A9A9A9"}} type="text" maxLength="16" placeholder="" />
                    <Button className="activation-btn" style={{backgroundColor:"#A9A9A9"}} disabled>確認</Button>
                  </Col>
                </div>
              </Row>
            </Grid>
          </div>
        )}
    return (
      <div>
        {content}
        <Footer/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(SBActivation);
