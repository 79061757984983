import React from 'react'
import {Col, Grid, Row} from 'react-bootstrap'
import './css/AdminEditProducts.css'
import axios from 'axios'
import {STORE_ADMIN_PRODUCTS, STORE_API_URL, STORE_REST_PRODUCTS} from '../../utils/url';
import Dropzone from 'react-dropzone'
import {connect} from 'react-redux'
import {LOG} from "../../utils/Log";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class AdminEditProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_admin: false,
      loading: false,

      name: "",
      category: "contents",
      description: "",
      small_files: [],
      medium_files: [],
      large_files: [],
      product_files: [],
      thumbnail_file: [],
      price: "",
      sale_price: "",
      option: {
        type: "single",
        items: [],
      },
      add_option_name: "",
      add_option_price: "",
      contents: [],
      add_content_id: "",
      state: "default",
      type: "oneshot",
      tags: [],
      add_tag_name: "",
      badges: [],
      add_badge_name: "",
      manufacturer: "",
      sophy_textbook: false,

      next_small_files: [],
      next_medium_files: [],
      next_large_files: [],
      next_product_files: [],
      next_thumbnail_file: [],
    }
  }

  componentWillMount() {
    let product_id = this.props.match.params.product_id;
    this.setState({
      loading: true,
    });
    axios.get(STORE_API_URL + STORE_REST_PRODUCTS + "/" + product_id, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    }).then((response) => {
      this.setState({
        is_admin: true,
        loading: false,
        name: response.data.data["name"],
        category: response.data.data["category"],
        description: response.data.data["description"],
        small_files: response.data.data["description_image"]["small"] ? response.data.data["description_image"]["small"] : [],
        medium_files: response.data.data["description_image"]["medium"] ? response.data.data["description_image"]["medium"] : [],
        large_files: response.data.data["description_image"]["large"] ? response.data.data["description_image"]["large"] : [],
        product_files: response.data.data["images"],
        thumbnail_file: [response.data.data["thumbnail"]],
        price: response.data.data["price"],
        sale_price: response.data.data["sale_price"],
        option: {
          type: response.data.data["option"] ? response.data.data["option"]["type"] : "single",
          items: response.data.data["option"] ? response.data.data["option"]["items"] : [],
        },
        contents: response.data.data["contents"],
        state: response.data.data["state"],
        type: response.data.data["type"],
        tags: response.data.data["tags"],
        badges: response.data.data["badges"],
        manufacturer: response.data.data["manufacturer"],
        sophy_textbook: response.data.data["sophy_textbook"] || false
      })
    }).catch((error) => {
        LOG("AdminEditProducts @ rest get product , error : " + error);
      })
  }

  componentDidMount() {
  }

  handleChange = (event) => {
    if (event.currentTarget.name === 'sophy_textbook') {
      this.setState({
        sophy_textbook: event.currentTarget.value.toLowerCase() === "true"
      });
    } else {
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value
      })
    }
  };
  onSubmitContents = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let description_image = {};
    if (this.state.next_small_files.length > 0)
      description_image["small"] = this.state.next_small_files.map(item => item.img);
    if (this.state.next_medium_files.length > 0)
      description_image["medium"] = this.state.next_medium_files.map(item => item.img);
    if (this.state.next_large_files.length > 0)
      description_image["large"] = this.state.next_large_files.map(item => item.img);
    LOG(description_image);

    let data = {
      product_id: this.props.match.params.product_id,
      category: this.state.category,
      name: this.state.name,
      description: this.state.description,
      description_image: description_image,
      price: this.state.price,
      sale_price: this.state.sale_price !== "" ? this.state.sale_price : null,
      option: this.state.option["items"].length === 0 ? null : this.state.option,
      contents: this.state.contents,
      state: this.state.state,
      type: this.state.type,
      tags: this.state.tags,
      badges: this.state.badges,
      manufacturer: this.state.manufacturer,
      sophy_textbook: this.state.sophy_textbook
    };
    if (this.state.next_thumbnail_file.length > 0) {
      data["thumbnail"] = this.state.next_thumbnail_file[0]["img"];
    }
    if (this.state.next_product_files.length > 0) {
      data["images"] = this.state.next_product_files.map(item => item.img);
    }
    LOG(this.state);
    LOG(data);
    axios.put(STORE_API_URL + STORE_ADMIN_PRODUCTS, data, {
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then(() => {
        alert("Success");
        this.setState({
          loading: false,
        });
        window.location.href = "/adminProduct";
      })
      .catch((error) => {

      })
  };

  onDrop = (type, files) => {
    let result_files = [];
    files.map((item, index) => {
      return getBase64(item).then((data) => {
        result_files[index] = {
          name: item.name,
          img: data,
        };
        this.setState({
          [type]: result_files,
          ["next_" + type]: result_files
        });
      })
    })
  };

  onCancel = (type) => {
    this.setState({
      [type]: []
    });
  };
  onClickAddOption = () => {
    if (this.state.add_option_name === "" || this.state.add_option_price === "")
      return false;
    this.state.option["items"].push({
      name: this.state.add_option_name,
      price: parseInt(this.state.add_option_price),
    });
    this.setState({
      add_option_name: "",
      add_option_price: "",
    })
  };
  onClickDeleteOption = (index) => {
    this.state.option["items"].splice(index, 1);
    this.forceUpdate();
  };
  onClickAddContent = () => {
    if (this.state.add_content_id === "")
      return false;
    this.state.contents.push(this.state.add_content_id);
    this.setState({
      add_content_id: "",
    })
  };
  onClickDeleteContent = (index) => {
    this.state.contents.splice(index, 1);
    this.forceUpdate();
  };
  onClickAddTag = () => {
    if (this.state.add_tag_name === "")
      return false;
    this.state.tags.push(this.state.add_tag_name);
    this.setState({
      add_tag_name: "",
    })
  };
  onClickDeleteTag = (index) => {
    this.state.tags.splice(index, 1);
    this.forceUpdate();
  };
  onClickAddBadge = () => {
    if (this.state.add_badge_name === "")
      return false;
    this.state.badges.push(this.state.add_badge_name);
    this.setState({
      add_badge_name: "",
    })
  };
  onClickDeleteBadge = (index) => {
    this.state.badges.splice(index, 1);
    this.forceUpdate();
  };

  render() {
    if (this.state.is_admin === false && this.state.loading === false)
      return (<div><h1> unauthorized </h1></div>);

    /*
        if (this.state.is_admin === false)
          return false;
    */
    return (
      <div>
        {this.state.loading &&
        <div className="loading_container purchase_loading">
          <span className="vertical_space"/>
          <div style={{display: "inline-block"}}>
            <div className="loader">
            </div>
            <br/>
            <span>Loading...</span>
          </div>
        </div>
        }
        <Grid className="admin_grid">
          <Row>
            <Col xs={12} className="admin_container">
              <h1 className="title">Edit Product</h1>
            </Col>
            <form className="contents_form" onSubmit={this.onSubmitContents}>
              {LOG("category : " + this.state.category)}
              <select required className="select_input" name="category" value={this.state.category}
                      onChange={this.handleChange}>
                <option>contents</option>
                <option>goods</option>
                <option>free</option>
                <option>edge</option>
                <option>musio&friends</option>
              </select>
              <input required className="pull_input" type="text" placeholder="Name" name="name" value={this.state.name}
                     onChange={this.handleChange}/>
              <textarea className="pull_input" placeholder="description" name="description"
                        value={this.state.description} onChange={this.handleChange}/>
              <input required className="pull_input" type="text" placeholder="manufacturer" name="manufacturer"
                     value={this.state.manufacturer} onChange={this.handleChange}/>
              <Dropzone
                onDrop={(files) => this.onDrop('small_files', files)}
                className={`dropzone ${this.state.small_files.length > 0 ? "active" : ""}`}
                acceptClassName="dropzone_accept"
              >
                {this.state.small_files.length < 1 ?
                  <span className="drop_text">
                      Small Images<br/>
                      Drag & Drop / Click
                    </span> :
                  <span className="drop_text">
                      <strong>-SMALL IMAGES-</strong>
                    {this.state.small_files.map((f, index) => <li
                      key={f.name + "_" + index}>{f.name} - {f.size} bytes <span>Complete</span></li>)}
                    </span>
                }
                <span className="vertical_space"/>
              </Dropzone>
              <br/>
              <Dropzone
                onDrop={(files) => this.onDrop('medium_files', files)}
                className={`dropzone ${this.state.medium_files.length > 0 ? "active" : ""}`}
                acceptClassName="dropzone_accept"
              >
                {this.state.medium_files.length < 1 ?
                  <span className="drop_text">
                      medium Images<br/>
                      Drag & Drop / Click
                    </span> :
                  <span className="drop_text">
                      <strong>-MEDIUM IMAGES-</strong>
                    {this.state.medium_files.map((f, index) => <li
                      key={f.name + "_" + index}>{f.name} - {f.size} bytes <span>Complete</span></li>)}
                    </span>
                }
                <span className="vertical_space"/>
              </Dropzone>
              <br/>
              <Dropzone
                onDrop={(files) => this.onDrop('large_files', files)}
                className={`dropzone ${this.state.large_files.length > 0 ? "active" : ""}`}
                acceptClassName="dropzone_accept"
              >
                {this.state.large_files.length < 1 ?
                  <span className="drop_text">
                      large Images<br/>
                      Drag & Drop / Click
                    </span> :
                  <span className="drop_text">
                      <strong>-LARGE IMAGES-</strong>
                    {this.state.large_files.map((f, index) => <li
                      key={f.name + "_" + index}>{f.name} - {f.size} bytes <span>Complete</span></li>)}
                    </span>
                }
                <span className="vertical_space"/>
              </Dropzone>
              <br/>
              <Dropzone
                onDrop={(files) => this.onDrop('product_files', files)}
                className={`dropzone product_files ${this.state.product_files.length > 0 ? "active" : ""}`}
                acceptClassName="dropzone_accept"
              >
                {this.state.product_files.length < 1 ?
                  <span className="drop_text">
                      product Images<br/>
                      Drag & Drop / Click
                    </span> :
                  <span className="drop_text">
                      <strong>-PRODUCT IMAGES-</strong>
                    {this.state.product_files.map((f, index) => <li
                      key={f.name + "_" + index}>{f.name} - {f.size} bytes <span>Complete</span></li>)}
                    </span>
                }
                <span className="vertical_space"/>
              </Dropzone>
              <Dropzone
                onDrop={(files) => this.onDrop('thumbnail_file', files)}
                className={`dropzone thumbnail_file ${this.state.thumbnail_file.length > 0 ? "active" : ""}`}
                acceptClassName="dropzone_accept"
                onFileDialogCancel={() => this.onCancel("thumbnail_file")}
                multiple={false}
              >
                {this.state.thumbnail_file.length < 1 ?
                  <span className="drop_text">
                      Thumbnail (Option)<br/>
                      Drag & Drop / Click
                    </span> :
                  <span className="drop_text">
                      <strong>-Thumbnail IMAGES-</strong>
                    {this.state.thumbnail_file.map((f, index) => <li
                      key={f.name + "_" + index}>{f.name} - {f.size} bytes <span>Complete</span></li>)}
                    </span>
                }
                <span className="vertical_space"/>
              </Dropzone>
              <input required className="pull_input" type="text" placeholder="price" name="price"
                     value={this.state.price} onChange={this.handleChange}/>
              <input className="pull_input" type="text" placeholder="Sale Price (Option)" name="sale_price"
                     value={this.state.sale_price} onChange={this.handleChange}/>
              <div className="options_div">
                <span> Options </span> <br/>
                {this.state.option["items"].map((item, index) => (
                  <div className="added_option" key={"option" + index}>
                    <span> {item.name + " / " + parseInt(item.price).toLocaleString()} </span>
                    <button type="button" onClick={() => this.onClickDeleteOption(index)}> Delete</button>
                  </div>
                ))}
                <input className="option_input" type="text" placeholder="Name" name="add_option_name"
                       value={this.state.add_option_name} onChange={this.handleChange}/>
                <input className="option_input" type="text" placeholder="Price" name="add_option_price"
                       value={this.state.add_option_price} onChange={this.handleChange}/>
                <button type="button" onClick={this.onClickAddOption}> Add</button>
              </div>
              <div className="options_div">
                <span> Link Content Id </span> <br/>
                {this.state.contents.map((item, index) => (
                  <div className="added_option" key={"content" + index}>
                    <span> {item} </span>
                    <button type="button" onClick={() => this.onClickDeleteContent(index)}> Delete</button>
                  </div>
                ))}
                <input className="option_input" type="text" placeholder="ID" name="add_content_id"
                       value={this.state.add_content_id} onChange={this.handleChange}/>
                <button type="button" onClick={this.onClickAddContent}> Add</button>
              </div>
              <strong style={{fontSize: "16px"}}> State </strong>
              <select required className="select_input" name="state" value={this.state.state}
                      onChange={this.handleChange}>
                <option>default</option>
                <option>soldout</option>
                <option>prepare</option>
              </select>
              <br/>
              <strong style={{fontSize: "16px"}}> Type </strong>
              <select required className="select_input" name="type" value={this.state.type}
                      onChange={this.handleChange}>
                <option>oneshot</option>
                <option>edge</option>
              </select>
              <br/>
              <strong style={{fontSize: "16px"}}> sophy_textbook </strong>
              <div className="sophy_text_input">
                <label>
                  <input
                    type="radio"
                    name="sophy_textbook"
                    checked={true === this.state.sophy_textbook}
                    value={true}
                    onChange={this.handleChange}
                  />
                  true
                </label>
                <label>
                  <input
                    type="radio"
                    name="sophy_textbook"
                    checked={false === this.state.sophy_textbook}
                    value={false}
                    onChange={this.handleChange}
                  />
                  false
                </label>
              </div>
              <br/>
              <div className="options_div">
                <span> Tags </span> <br/>
                {this.state.tags.map((item, index) => (
                  <div className="added_option" key={"tag" + index}>
                    <span> {item} </span>
                    <button type="button" onClick={() => this.onClickDeleteTag(index)}> Delete</button>
                  </div>
                ))}
                <input className="option_input" type="text" placeholder="tag name" name="add_tag_name"
                       value={this.state.add_tag_name} onChange={this.handleChange}/>
                <button type="button" onClick={this.onClickAddTag}> Add</button>
              </div>
              <div className="options_div">
                <span> Bages </span> <br/>
                {this.state.badges.map((item, index) => (
                  <div className="added_option" key={"badge" + index}>
                    <span> {item} </span>
                    <button type="button" onClick={() => this.onClickDeleteBadge(index)}> Delete</button>
                  </div>
                ))}
                <input className="option_input" type="text" placeholder="Badge name" name="add_badge_name"
                       value={this.state.add_badge_name} onChange={this.handleChange}/>
                <button type="button" onClick={this.onClickAddBadge}> Add</button>
              </div>
              <button type="submit" style={{backgroundColor: "#ec6c00"}}>Submit</button>
            </form>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditProducts);
