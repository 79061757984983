import axios from "axios";
import React from "react";
import { Col, DropdownButton, Grid, MenuItem, Row } from "react-bootstrap";
import { LOG } from "../../utils/Log";
import { trackEvent } from "../../utils/tracking";
import {
  AUTH_LOGIN,
  STORE_API_URL,
  STORE_REST_CART,
  USERMODEL_URL,
} from "../../utils/url";
import "./css/DetailInfo.css";
const TAX_RATE=1.1

function round10(num){
  return Math.round(num / 10) * 10
}
class DetailInfo extends React.Component {
  state = {
    selectedItems: null,
  };

  onOptionSelect = (eventKey) => {
    LOG("DetailInfo @ onOptionSelect , eventKey : " + eventKey);

    const selectedItem = this.props.product.option.items.find((e) => {
      return e.id === eventKey;
    });

    selectedItem.amount = 1;
    this.setState({
      ...this.state,
      selectedItems: selectedItem,
    });
  };

  onAddCart = () => {
    if (this.props.product.state !== "default") return;

    if (!this.props.isAuthenticated) {
      alert("Please Login");
      trackEvent("PRODUCT", "Error: Add to cart", "User is not logged in");
      window.location =
        USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
      return;
    }
    if (this.props.product.option && !this.state.selectedItems) {
      alert("Please Select Options");
      trackEvent("PRODUCT", "Error: Add to cart", "Options are not selected");
      return;
    }

    LOG("jwt : " + this.props.jwt);

    axios
      .post(
        STORE_API_URL + STORE_REST_CART,
        {
          product_id: this.props.product.id,
          option: [
            {
              id: this.state.selectedItems["id"],
              count: this.state.selectedItems["amount"],
              name: this.state.selectedItems["name"],
              price: this.state.selectedItems["price"],
            },
          ],
        },
        {
          headers: { Authorization: "Bearer " + this.props.jwt },
        }
      )
      .then(() => {
        trackEvent("PRODUCT", "Buy: " + this.props.product.id, "");
        this.props.history.push("/cart");
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          selectedItems: null,
        });
        LOG(error);
        trackEvent(
          "PRODUCT",
          "Error: Add to cart " + this.props.product.id,
          "Error: " + error
        );
      });
  };

  render() {
    LOG("DetailInfo @ render");

    return (
      <Grid className="detail">
        <Row>
          <Col
            key={this.props.product.badges}
            className="info"
            md={6}
            mdOffset={3}
            xs={12}
          >
            {this.props.product.badges.map((badge, i) => {
              return (
                <div className="best" key={"badge" + i}>
                  {badge}
                </div>
              );
            })}

            <div className="title">{this.props.product.name}</div>
            <div className="tag_wrapper">
              {this.props.product.tags.map((tag) => {
                return <div className="tag">{tag}</div>;
              })}
            </div>
            <div className="price">
              {this.props.product.sale_price && (
                <span className="product_sale_price">
                  <s>¥{round10(this.props.product.price*TAX_RATE).toLocaleString()}</s>
                </span>
              )}
              {this.props.product.sale_price ? (
                <span>¥{this.props.product.sale_price.toLocaleString()}</span>
              ) : (
                <span>¥{round10(this.props.product.price*TAX_RATE).toLocaleString()}</span>
              )}
            </div>
            {this.props.product.option ? (
              <DropdownButton
                title={
                  this.state.selectedItems
                    ? this.state.selectedItems.name
                    : "商品のオプションを選択してください。"
                }
                id="dropdown-size-medium"
                onSelect={this.onOptionSelect}
                key={0}
              >
                {this.props.product.option.items.map((option, i) => {
                  return (
                    <MenuItem key={i} eventKey={option.id}>
                      {`${option.name} / +${option.price.toLocaleString()}¥`}
                    </MenuItem>
                  );
                })}
              </DropdownButton>
            ) : (
              <div />
            )}

            <div className="etc">
              <div className="blog">Blog</div>
              <div
                className="buy"
                style={
                  this.props.product.state === "soldout" ||
                  this.props.product.state === "prepare"
                    ? { cursor: "not-allowed" }
                    : {}
                }
                onClick={this.onAddCart}
              >
                {this.props.product.state === "soldout" && (
                  <span>Sold Out</span>
                )}
                {this.props.product.state === "default" && <span>Buy</span>}
                {this.props.product.state === "prepare" && (
                  <span>Coming Soon</span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default DetailInfo;
