import React from 'react'

import './css/DetailReview.css'
//import {Grid, Row, Col} from 'react-bootstrap'

import star_img from './img/star.png'
import star_on_img from './img/star_on.png'
import exit_img from './img/exit.png'
//import kiddicat from '../service/img/img_W_kiddicat.png'

import PropTypes from 'prop-types'
import Loading from '../../utils/Loading'

class DetailReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      score: 0
    };

    this.onStarClick = this.onStarClick.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
  }

  onStarClick(score) {
    this.setState({
      score: score
    })
  }

  onSubmitReview() {
    if (this.textRef.value === '') {
      alert('Please input content!');
      return;
    }
    this.props.postComment(
      this.textRef.value,
      this.state.score
    )
  }

  render() {
    return (
      <div>

        <div className="modal-wrapper">
          <div className="review-modal">
            <div className="modal-header">
              <div>コメントの作成</div>
              <div className="exit" onClick={this.props.onReviewToggle}>
                <img src={exit_img} alt="exit"/>
              </div>
            </div>
            <div className="modal-title">
              <div className="modal-thumbnail">
                <img src={this.props.thumbnail} alt="product_thumnail"/>
              </div>
              <div className="body">{this.props.title}</div>

            </div>
            <div className="modal-score">
              <div className="star" onClick={() => this.onStarClick(1)}>
                <img src={this.state.score >= 1 ? star_on_img : star_img} alt="star"/>
              </div>
              <div className="star" onClick={() => this.onStarClick(2)}>
                <img src={this.state.score >= 2 ? star_on_img : star_img} alt="star"/>
              </div>
              <div className="star" onClick={() => this.onStarClick(3)}>
                <img src={this.state.score >= 3 ? star_on_img : star_img} alt="star"/>
              </div>
              <div className="star" onClick={() => this.onStarClick(4)}>
                <img src={this.state.score >= 4 ? star_on_img : star_img} alt="star"/>
              </div>
              <div className="last_star" onClick={() => this.onStarClick(5)}>
                <img src={this.state.score >= 5 ? star_on_img : star_img} alt="star"/>
              </div>
            </div>
            <div className="modal-review">
              <textarea ref={(ref) => this.textRef = ref} className="comment_input" placeholder="書き込みを作成してください"/>
            </div>

            <div className="modal-submit" onClick={this.onSubmitReview}>
              <div className="submit-button">登録</div>
            </div>
          </div>
        </div>
        {
          this.props.pending &&
          <Loading/>
        }
      </div>
    );
  }
}


DetailReview.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  onReviewToggle: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired
};

export default DetailReview;
