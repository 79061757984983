import axios from 'axios'
import {STORE_REST_PRODUCTS, STORE_API_URL} from "../utils/url";
import {LOG} from "../utils/Log";

const GET_CONTENTS_PENDING = 'GET_CONTENTS_PENDING';
//const GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS';
const GET_CONTENTS_ERROR = 'GET_CONTENTS_ERROR';

export function get_contents(tag_index, is_new) {
  // LOG("contents @ get_contents , tag_index : " + tag_index + " , is_new : " + is_new);
  return (dispatch, getState) => {
    dispatch({type: GET_CONTENTS_PENDING});
    //axios.get(url+"/rest/products?catergory=${category}&tag=${tag}")
    let request_url = STORE_API_URL + STORE_REST_PRODUCTS;

    let tag;
    if (tag_index === 1) tag = "乳児";
    if (tag_index === 2) tag = "小学生";
    if (tag_index === 3) tag = "中高生";
    if (tag_index === 4) tag = "大人";

    let next_token = getState().ContentsReducer.contents[tag_index].next_token;

    axios.get(request_url, {
      params: {
        category: 'contents',
        tag: tag,
        start_token: next_token
      }
    }).then(
      (response) => {
        let type = 'APPEND_CONTENTS_SUCCESS';
        if (is_new) type = 'GET_CONTENTS_SUCCESS';

        dispatch({
          type: type,
          data: response.data.data,
          tag_index: tag_index,
          next_token: response.data.next_token
        })
      }
    ).catch(
        (error) => {
          // LOG(error);
          dispatch({type: GET_CONTENTS_ERROR})
        }
      )
  }
}
