import React from "react";
//import PropTypes from 'prop-types'
import { Row, Col, Grid } from "react-bootstrap";

import ContentsList from "./../public_components/ContentsList";
import ContentCategory from "./../public_components/ContentCategory";
//import Content from './../public_components/Content'

import ContentTitle from "./img/home_contents_title.png";
import { get_contents } from "./../../actions/contents";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import Loading from "../../utils/Loading";
import { trackEvent } from "../../utils/tracking";

class HomeContents extends React.Component {
  state = { tag: 0 };

  componentWillMount() {
    if (this.props.contents[this.state.tag].data.length === 0) {
      this.props.getContents(this.state.tag, true);
    }
  }

  onCategoryClick = (i) => {
    this.setState({
      ...this.state,
      tag: i,
    });
    if (this.props.contents[i].data.length === 0)
      this.props.getContents(i, false);
  };

  render() {
    return (
      <div>
        {this.props.pending && <Loading />}
        <Grid className="title-grid">
          <Row>
            <Col className="title-col" xs={12}>
              <div className="banner_title">充実した学習コンテンツ</div>
              <div className="banner_img">
                <img src={ContentTitle} alt="content_title" />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <ContentCategory onCategoryClick={this.onCategoryClick} />
          <ContentsList
            contents={this.props.contents[this.state.tag].data.slice(0, 6)}
          />
          <Row>
            <Link
              to="/contents"
              onClick={() => {
                trackEvent("HOME", "Click from Home: contents", "");
              }}
            >
              <Col className="seeall_button" xsOffset={4} xs={4}>
                <div className="text-center">See All</div>
              </Col>
            </Link>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.ContentsReducer.contents,
    pending: state.ContentsReducer.pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContents: (tag, is_new) => dispatch(get_contents(tag, is_new)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContents);
