import React, {Component} from 'react'
import {Row, Col} from 'react-bootstrap'
import Content from './Content'

class ContentsList extends Component {
  render() {
    return (
      <Row >
        <div style={{marginTop:'20px', marginLeft:'10px'}}>＊商品はすべて税込価格で表示されています。</div>
        {
          this.props.contents.map((content) => (
            <Col key={content.id} sm={4} xs={6} style={{minHeight:'550px'}}>
              <Content key={content.id} {...content} />
            </Col>
          ))
        }
      </Row>
    );
  }
}

export default ContentsList;
