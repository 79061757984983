import React from 'react';
//import PropTypes from 'prop-types'
import './css/Footer.css'

import {Grid, Button} from 'react-bootstrap'
import musio_badge from './img/musio_badge.png'
import apple_store from './img/apple_store.png'
import google_store from './img/google_store.png'

import btn_facebook from './img/btn_facebook.png'
import btn_insta from './img/btn_insta.png'
import btn_twitter from './img/btn_twitter.png'
import btn_youtube from './img/btn_youtube.png'

import aka_logo from './img/aka-logo.png'
import axios from "axios";
import {AUTH_CM_REST_SUBSCRIBERS, THE_MUSIO_URL} from "../utils/url";
import {LOG} from "../utils/Log";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  subscribe = () => {
    let bodyFormData = [];
    bodyFormData["email"] = this.textInput.current.value;
    axios.post(THE_MUSIO_URL + "/"+ AUTH_CM_REST_SUBSCRIBERS, {
      bodyFormData,
      headers: {"Authorization": "Bearer " + this.props.jwt},
    })
      .then((response) => {
        LOG("success : " + response);
        this.textInput.current.value = '';
      })
      .catch((error) => {
        LOG(error);
        this.textInput.current.value = '';
      });
  };

  render() {
    let marginTop = this.props.marginTop;
    if (marginTop === undefined) {
      marginTop = 60
    }
    return (
      <div style={{marginTop: marginTop}}>
        <div className="top-footer">
          <Grid>
            <div className="title">
              ポケットにもMusio<br/>
              あなたの学習をサポートする
            </div>
            <div className="badges">
              <div>
                <img src={musio_badge} alt="musio_badge"/>
              </div>
              <div className="stores">
                <span className="google-badge">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.akaintelligence.musio.musioapplication#details-reviews">
                    <img src={google_store} alt="google_store"/></a>
                </span>
                <span className="apple-badge">
                  <a href="https://itunes.apple.com/jp/app/musio/id1055252985?mt=8">
                    <img src={apple_store} alt="apple_store"/></a>
                </span>
              </div>
            </div>
          </Grid>
        </div>

        <div className="bottom-footer">
          <Grid>
            <div className="bottom-footer-wrapper1">
              <div className="sns-btns">
                <a target="_blank" rel="noopener noreferrer"
                   href="https://www.facebook.com/musioAKA/?ref=ts&fref=ts">
                  <img src={btn_facebook} alt="facebook"/>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/musio_aka/">
                  <img src={btn_insta} alt="insta"/>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Musio_AKA">
                  <img src={btn_twitter} alt="twitter"/>
                </a>
                <a target="_blank" rel="noopener noreferrer"
                   href="https://www.youtube.com/channel/UCHXmMzxX7cMVPaLihhcTGLA">
                  <img src={btn_youtube} alt="youtube"/>
                </a>
              </div>

              <div className="email-form">
                <input type="text" ref={this.textInput} className="email-input" placeholder="Email address"/>
                <Button className="email-submit" onClick={this.subscribe}>Subscribe</Button>
              </div>
            </div>

            <div className="bottom-footer-wrapper2">
              <div className="logo">
                <a href="http://akaintelligence.com"><img src={aka_logo} alt="logo"/></a>
              </div>

              <div className="etc">
                <span id="copyright">ⓒ COPYRIGHT 2016. ALL RIGHTS RESERVED.</span>
                <a href="https://blog.themusio.com/category/a-i/agreement/" target="_blank"><span id="access-term">利用規約</span></a>
                <a href='https://blog.themusio.com/category/a-i/rule/' target="_blank"><span id="law">特定商取引法に基づく表示</span></a>
                <a href='https://blog.themusio.com/category/a-i/privacypolicy/' target="_blank"><span id="policy"> プライバシーポリシー</span></a>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Footer;