import axios from "axios";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { trackEvent } from "../../utils/tracking";
import {
  AUTH_LOGIN,
  STORE_API_URL,
  STORE_REST_CART,
  USERMODEL_URL,
} from "../../utils/url";
import "./css/Service.css";
import friendplan from "./img/friendplan.png";
import mobileChart from "./img/mobile/chart.png";
import mobilebtn1_1 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile.png";
import mobilebtn3_1 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_2.png";
import mobilebtn5_1 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_3.png";
import mobilebtn1_6 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_4.png";
import mobilebtn3_6 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_5.png";
import mobilebtn5_6 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_6.png";
import mobilebtn1_12 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_7.png";
import mobilebtn3_12 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_8.png";
import mobilebtn5_12 from "./img/mobile/friends_plan/drawable-xxhdpi/card_mobile_9.png";
import mobilegroup1 from "./img/mobile/friends_plan/drawable-xxhdpi/group.png";
import mobilegroup3 from "./img/mobile/friends_plan/drawable-xxhdpi/group_2.png";
import mobilegroup5 from "./img/mobile/friends_plan/drawable-xxhdpi/group_3.png";
import tabletChart from "./img/tablet/chart.png";
import tabletbtn1_6 from "./img/tablet/friends_plan/drawable-xhdpi/card_2_tablet.png";
import tabletbtn3_6 from "./img/tablet/friends_plan/drawable-xhdpi/card_2_tablet_2.png";
import tabletbtn5_6 from "./img/tablet/friends_plan/drawable-xhdpi/card_2_tablet_3.png";
import tabletbtn1_12 from "./img/tablet/friends_plan/drawable-xhdpi/card_3_tablet.png";
import tabletbtn3_12 from "./img/tablet/friends_plan/drawable-xhdpi/card_3_tablet_2.png";
import tabletbtn5_12 from "./img/tablet/friends_plan/drawable-xhdpi/card_3_tablet_3.png";
import tabletgroup1 from "./img/tablet/friends_plan/drawable-xhdpi/group_2.png";
import tabletgroup3 from "./img/tablet/friends_plan/drawable-xhdpi/group_3.png";
import tabletgroup5 from "./img/tablet/friends_plan/drawable-xhdpi/group_5.png";
import tabletbtn3_1 from "./img/tablet/friends_plan/drawable-xhdpi/group_6.png";
import tabletbtn5_1 from "./img/tablet/friends_plan/drawable-xhdpi/group_7.png";
import tabletbtn1_1 from "./img/tablet/friends_plan/drawable-xhdpi/plan_tablet.png";
import webChart from "./img/web/chart.png";
import webbtn1_1 from "./img/web/friends_plan/friend-card-monthly.png";
import webbtn3_1 from "./img/web/friends_plan/friend-card-monthly_2.png";
import webbtn5_1 from "./img/web/friends_plan/friend-card-monthly_3.png";
import group3 from "./img/web/friends_plan/group-2.png";
import group5 from "./img/web/friends_plan/group-3.png";
import group1 from "./img/web/friends_plan/group.png";
import webbtn1_6 from "./img/web/friends_plan/plan-card.png";
import webbtn3_6 from "./img/web/friends_plan/plan-card_2.png";
import webbtn5_6 from "./img/web/friends_plan/plan-card_3.png";
import webbtn1_12 from "./img/web/friends_plan/plan-card_4.png";
import webbtn3_12 from "./img/web/friends_plan/plan-card_5.png";
import webbtn5_12 from "./img/web/friends_plan/plan-card_6.png";

export const FriendsPlanContent = (props) => {
  const { jwt, isAuthenticated } = props;
  const history = useHistory();
  const [selectedButton, setSelectedButton] = useState();
  const onFriendsPlanClick = (i) => setSelectedButton(i);
  const onFriendsPlanBuy = async () => {
    if (!isAuthenticated) {
      alert("Please Login");
      window.location =
        USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href;
      return;
    }

    const option = [];
    if (selectedButton === 0) {
      const optionItem = {};
      optionItem["id"] = "Plan_1";
      optionItem["count"] = 1;
      optionItem["name"] = "1 Friends Plan";
      optionItem["price"] = 982;
      optionItem["duration"] = 1;
      option.push(optionItem);
    } else if (selectedButton === 1) {
      const optionItem = {};
      optionItem["id"] = "Plan_1_6";
      optionItem["count"] = 1;
      optionItem["name"] = "1 Friends Plan";
      optionItem["price"] = 5391;
      optionItem["duration"] = 6;
      option.push(optionItem);
    } else if (selectedButton === 2) {
      const optionItem = {};
      optionItem["id"] = "Plan_1_12";
      optionItem["count"] = 1;
      optionItem["name"] = "1 Friends Plan";
      optionItem["price"] = 10782;
      optionItem["duration"] = 12;
      option.push(optionItem);
    } else if (selectedButton === 3) {
      const optionItem = {};
      optionItem["id"] = "Plan_2";
      optionItem["count"] = 1;
      optionItem["name"] = "3 Friends Plan";
      optionItem["price"] = 1482;
      optionItem["duration"] = 1;
      option.push(optionItem);
    } else if (selectedButton === 4) {
      const optionItem = {};
      optionItem["id"] = "Plan_2_6";
      optionItem["count"] = 1;
      optionItem["name"] = "3 Friends Plan";
      optionItem["price"] = 8145;
      optionItem["duration"] = 6;
      option.push(optionItem);
    } else if (selectedButton === 5) {
      const optionItem = {};
      optionItem["id"] = "Plan_2_12";
      optionItem["count"] = 1;
      optionItem["name"] = "3 Friends Plan";
      optionItem["price"] = 16282;
      optionItem["duration"] = 12;
      option.push(optionItem);
    } else if (selectedButton === 6) {
      const optionItem = {};
      optionItem["id"] = "Plan_3";
      optionItem["count"] = 1;
      optionItem["name"] = "5 Friends Plan";
      optionItem["price"] = 1782;
      option.push(optionItem);
    } else if (selectedButton === 7) {
      const optionItem = {};
      optionItem["id"] = "Plan_3_6";
      optionItem["count"] = 1;
      optionItem["name"] = "5 Friends Plan";
      optionItem["price"] = 9791;
      optionItem["duration"] = 6;
      option.push(optionItem);
    } else if (selectedButton === 8) {
      const optionItem = {};
      optionItem["id"] = "Plan_3_12";
      optionItem["count"] = 1;
      optionItem["name"] = "5 Friends Plan";
      optionItem["price"] = 19582;
      optionItem["duration"] = 12;
      option.push(optionItem);
    } else {
      return;
    }
    const payload = {
      product_id: "b7855c9b-92dd-5c91-b219-315b716eb481",
      option: option,
    };
    try {
      await fetch(STORE_API_URL + STORE_REST_CART, {
        method: "post",
        headers: {
          Authorization: "Bearer " + jwt,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      trackEvent("PRODUCT", "Success: Add to cart " + option["name"], "");
      history.push("/cart");
    } catch (error) {
      trackEvent(
        "PRODUCT",
        "Failed: Add to cart " + option["name"],
        "Error: " + error
      );
    }
  };

  return (
    <div>
      <div>
        <Col xs={12} className="banner">
          <img src={friendplan} alt="banner" />
        </Col>
      </div>

      <div>
        <Col xs={12}>
          <div className="text">
            Friend Planは、
            Musioの会話機能及びMusioモバイルアプリケーションの機能をご利用いただくために必要なプランです。
            Friend
            Planに加入しなくても、Musioの教材学習機能がご利用いただけます。
          </div>
        </Col>
      </div>

      <div>
        <Col xs={12} xsHidden={true} smHidden={true}>
          <img src={webChart} alt="chart" />
        </Col>

        <Col xs={12} xsHidden={true} mdHidden={true} lgHidden={true}>
          <img src={tabletChart} alt="chart" />
        </Col>

        <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
          <img src={mobileChart} alt="chart" />
        </Col>
      </div>

      <div>
        <div>
          <Col xs={4} xsHidden={true} smHidden={true} className="planbtn">
            {/*<!--<img src={webbtn1} alt="btn1"/>-->*/}
            <img src={group1} className="friend-plan_group" alt="group1" />
            <img
              src={webbtn1_1}
              style={selectedButton !== 0 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(0)}
              className="friend-card_monthly_1"
              alt="btn1_1"
            />
            <img
              src={webbtn1_6}
              style={selectedButton !== 1 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(1)}
              className="friend-card_monthly_6"
              alt="btn1_6"
            />
            <img
              src={webbtn1_12}
              style={selectedButton !== 2 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(2)}
              className="friend-card_monthly_12"
              alt="btn1_12"
            />
          </Col>

          <Col
            xs={4}
            xsHidden={true}
            mdHidden={true}
            lgHidden={true}
            className="planbtn"
          >
            {/*<img src={tabletbtn1} alt="btn1"/>*/}
            <img
              src={tabletgroup1}
              className="friend-plan_group"
              alt="table-group-1"
            />
            <img
              src={webbtn1_1}
              style={selectedButton !== 0 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(0)}
              className="friend-card_monthly_1"
              alt="btn1_1"
            />
            <img
              src={webbtn1_6}
              style={selectedButton !== 1 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(1)}
              className="friend-card_monthly_6"
              alt="btn1_6"
            />
            <img
              src={webbtn1_12}
              style={selectedButton !== 2 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(2)}
              className="friend-card_monthly_12"
              alt="btn1_12"
            />
          </Col>
        </div>

        <div>
          <Col xs={4} xsHidden={true} smHidden={true} className="planbtn">
            {/*<img src={webbtn3} alt="btn3"/>*/}
            <img src={group3} className="friend-plan_group" alt="group-3" />
            <img
              src={webbtn3_1}
              style={selectedButton !== 3 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(3)}
              className="friend-card_monthly_1"
              alt="btn3_1"
            />
            <img
              src={webbtn3_6}
              style={selectedButton !== 4 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(4)}
              className="friend-card_monthly_6"
              alt="btn3_6"
            />
            <img
              src={webbtn3_12}
              style={selectedButton !== 5 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(5)}
              className="friend-card_monthly_12"
              alt="btn3_12"
            />
          </Col>

          <Col
            xs={4}
            xsHidden={true}
            mdHidden={true}
            lgHidden={true}
            className="planbtn"
          >
            <img
              src={tabletgroup3}
              className="friend-plan_group"
              alt="table-group-3"
            />
            <img
              src={webbtn3_1}
              style={selectedButton !== 3 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(3)}
              className="friend-card_monthly_1"
              alt="btn3_1"
            />
            <img
              src={webbtn3_6}
              style={selectedButton !== 4 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(4)}
              className="friend-card_monthly_6"
              alt="btn3_6"
            />
            <img
              src={webbtn3_12}
              style={selectedButton !== 5 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(5)}
              className="friend-card_monthly_12"
              alt="btn3_12"
            />
          </Col>
        </div>

        <div>
          <Col xs={4} xsHidden={true} smHidden={true} className="planbtn">
            {/*<img src={webbtn5} alt="btn5"/>*/}
            <img src={group5} className="friend-plan_group" alt="group-5" />
            <img
              src={webbtn5_1}
              style={selectedButton !== 6 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(6)}
              className="friend-card_monthly_1"
              alt="btn5_1"
            />
            <img
              src={webbtn5_6}
              style={selectedButton !== 7 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(7)}
              className="friend-card_monthly_6"
              alt="btn5_6"
            />
            <img
              src={webbtn5_12}
              style={selectedButton !== 8 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(8)}
              className="friend-card_monthly_12"
              alt="btn5_12"
            />
          </Col>

          <Col
            xs={4}
            xsHidden={true}
            mdHidden={true}
            lgHidden={true}
            className="planbtn"
          >
            {/*<img src={tabletbtn5} alt="btn5"/>*/}
            <img
              src={tabletgroup5}
              className="friend-plan_group"
              alt="table-group-5"
            />
            <img
              src={webbtn5_1}
              style={selectedButton !== 6 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(6)}
              className="friend-card_monthly_1"
              alt="btn5_1"
            />
            <img
              src={webbtn5_6}
              style={selectedButton !== 7 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(7)}
              className="friend-card_monthly_6"
              alt="btn5_6"
            />
            <img
              src={webbtn5_12}
              style={selectedButton !== 8 ? { opacity: 0.5 } : {}}
              onClick={() => onFriendsPlanClick(8)}
              className="friend-card_monthly_12"
              alt="btn5_12"
            />
          </Col>
        </div>

        <Col
          xs={12}
          smHidden={true}
          mdHidden={true}
          lgHidden={true}
          style={{ marginTop: "32px" }}
        >
          <div className="planbtn">
            <img
              src={mobilegroup1}
              className="friend-plan_group"
              alt="mobile-group-1"
            />
            <img
              src={mobilebtn1_1}
              style={
                selectedButton !== 0
                  ? { opacity: 0.5, top: "9%" }
                  : { top: "9%" }
              }
              onClick={() => onFriendsPlanClick(0)}
              className="friend-card_monthly_1"
              alt="btn1_1"
            />
            <img
              src={mobilebtn1_6}
              style={
                selectedButton !== 1
                  ? { opacity: 0.5, top: "17%" }
                  : { top: "17%" }
              }
              onClick={() => onFriendsPlanClick(1)}
              className="friend-card_monthly_6"
              alt="btn1_6"
            />
            <img
              src={mobilebtn1_12}
              style={
                selectedButton !== 2
                  ? { opacity: 0.5, top: "25%" }
                  : { top: "25%" }
              }
              onClick={() => onFriendsPlanClick(2)}
              className="friend-card_monthly_12"
              alt="btn1_12"
            />
          </div>
          <div className="planbtn">
            <img
              src={mobilegroup3}
              className="friend-plan_group"
              alt="mobile-group-3"
            />
            <img
              src={mobilebtn3_1}
              style={
                selectedButton !== 3
                  ? { opacity: 0.5, top: "42.5%" }
                  : { top: "42.5%" }
              }
              onClick={() => onFriendsPlanClick(3)}
              className="friend-card_monthly_1"
              alt="btn3_1"
            />
            <img
              src={mobilebtn3_6}
              style={
                selectedButton !== 4
                  ? { opacity: 0.5, top: "50.5%" }
                  : { top: "50.5%" }
              }
              onClick={() => onFriendsPlanClick(4)}
              className="friend-card_monthly_6"
              alt="btn3_6"
            />
            <img
              src={mobilebtn3_12}
              style={
                selectedButton !== 5
                  ? { opacity: 0.5, top: "58.5%" }
                  : { top: "58.5%" }
              }
              onClick={() => onFriendsPlanClick(5)}
              className="friend-card_monthly_12"
              alt="btn3_12"
            />
          </div>
          <div className="planbtn">
            <img
              src={mobilegroup5}
              className="friend-plan_group"
              alt="mobile-group-5"
            />
            <img
              src={mobilebtn5_1}
              style={
                selectedButton !== 6
                  ? { opacity: 0.5, top: "76%" }
                  : { top: "76%" }
              }
              onClick={() => onFriendsPlanClick(6)}
              className="friend-card_monthly_1"
              alt="btn5_1"
            />
            <img
              src={mobilebtn5_6}
              style={
                selectedButton !== 7
                  ? { opacity: 0.5, top: "84%" }
                  : { top: "84%" }
              }
              onClick={() => onFriendsPlanClick(7)}
              className="friend-card_monthly_6"
              alt="btn5_6"
            />
            <img
              src={mobilebtn5_12}
              style={
                selectedButton !== 8
                  ? { opacity: 0.5, top: "92%" }
                  : { top: "92%" }
              }
              onClick={() => onFriendsPlanClick(8)}
              className="friend-card_monthly_12"
              alt="btn5_12"
            />
          </div>
        </Col>
      </div>

      <Col xs={12} style={{ textAlign: "center" }}>
        <div
          className={"confirm-btn " + (selectedButton >= 0 ? "selected" : "")}
          onClick={onFriendsPlanBuy}
        >
          [Musio] に加入する
        </div>
      </Col>
    </div>
  );
};
