import './css/MobileHeader.css';
//import PropTypes from 'prop-types'
import React from 'react';

import HamburgerStick from './img/stick.jpg'
import Logo from './img/logo.png'

import {Motion, spring} from 'react-motion';
import {Accordion, AccordionItem} from 'react-sanfona';
import {Row, Col} from 'react-bootstrap'

import HeaderInfo from './HeaderInfo.js'
import {Link, NavLink} from 'react-router-dom';

import CartIcon from './img/home_gnb_btn_cart.png'
import EdgeIcon from './img/home_gnb_btn_edge.png'
import MyPageIcon from './img/home_gnb_btn_my.png'

import {connect} from 'react-redux'
import {logout} from '../actions/auth'
import {withCookies} from 'react-cookie';
import {LOG} from "../utils/Log";
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SIGN_UP,
  THE_MUSIO_URL,STORE_DOMAIN,
  USERMODEL_URL,
  STORE_API_URL,
  STORE_REST_ORDER,
} from "../utils/url";
import axios from 'axios'
import {trackEvent} from "../utils/tracking"

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: false,
      isOpen: false,
      items: HeaderInfo["store"],
      itemsJapan: HeaderInfo["storejapan"],
    };
    this.handleClick = this.handleClick.bind(this);
    this.logoutClick = this.logoutClick.bind(this);
    this.toggleFalse = this.toggleFalse.bind(this);
    this.dropdownButton = React.createRef();
  }



  logoutClick() {
    trackEvent("HEADER", "Logout", "")

    this.toggleFalse();
    this.props.dispatch_logout();
    window.location.reload();
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  toggleFalse() {
    this.setState({
      isToggleOn: false
    })
  }

  headerDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  openDropdown = () => {
    this.setState({ isOpen: true});
  }

  closeDropdown = () => {
    this.setState({ isOpen: false});
  }


  render() {
    const { isOpen } = this.state;
    return (
      <Motion defaultStyle={{height: 51}} style={
        {
          height: spring(this.props.isOpen && !this.state.isToggleOn ? 0 : 51, {stiffness: 170, precision: 0.03})
        }
      }>
        {style => (
          <Row className="header"
               style={{
                 height: style.height,
               }}>
                {this.state.productIdExists === true 
                  || window.location.pathname.startsWith('/musios')  
                  || window.location.pathname.includes('f7d73106-0f46-525f-b2a3-4f5e7b061293')
                  ? 
                <div>
                <img alt="logo" id="logo" src={Logo}/>
              </div> :
                <a  href={THE_MUSIO_URL} onClick={() => {trackEvent("HEADER", "Navigate to: themusio.com", "")}}>
                  <img alt="logo" id="logo" src={Logo}/>
                </a> 
                
                }
            
            <div className="dropdown" onMouseLeave={this.closeDropdown}>
              <div className="dropdown-button"
                onClick={this.toggleDropdown}
                onMouseOver={this.openDropdown}
                onFocus={this.openDropdown}
                ref={(button) => {
                  this.dropdownButton = button;
                }}
              >
                <span>▼</span>
              </div>

              <div className="move-landing-page" style={{ display: isOpen ? "block" : "none" }}>
                <ul className="landing-page" >
                  <li><Link to = "/">▼ 英語版Musioをお持ちの方</Link></li>
                  <li><Link to = "/musios">▼ 日本語版Musio</Link></li>
                </ul>
              </div>
            </div>
            

            <Motion defaultStyle={{opacity: 1, rotation: 45}} style={{
              opacity: spring(this.state.isToggleOn ? 0 : 1, {stiffness: 200}),
              rotation: spring(this.state.isToggleOn ? 43 : 0, {stiffness: 200, damping: 15})
            }}>
              {style => (
                <Col className="icons" onClick={this.handleClick}>
                  <img id="hamburger_stick1" alt="hamburger" src={HamburgerStick}
                       style={{
                         transform: `rotate(-${style.rotation}deg)`
                       }}/>
                  <img id="hamburger_stick2" alt="hamburger" src={HamburgerStick}
                       style={{
                         opacity: style.opacity
                       }}/>
                  <img id="hamburger_stick3" alt="hamburger" src={HamburgerStick}
                       style={{
                         transform: `rotate(${style.rotation}deg)`
                       }}/>
                  {/*<img alt="hamburger" src={EdgeIcon} onClick={this.handleClick}/>*/}
                </Col>
              )}
            </Motion>

            <Motion defaultStyle={{right: 0}}
                    style={
                      {
                        right: spring(this.state.isToggleOn ? -100 : 0, {stiffness: 150, damping: 15})
                      }
                    }>
              {style => (
                <Col className="menu_wrapper"
                     style={{
                       transform: `translateX(${style.right}%)`
                     }}>

                  <div className="login-info-wrapper">
                    {
                      !this.props.isAuthenticated &&
                      <div>
                        <div className="login-info">
                          ログインが必要です!
                        </div>
                        <a href={USERMODEL_URL + AUTH_LOGIN + "?next=" + window.location.href} onClick={() => {trackEvent("HEADER", "Login", "")}}>
                          <div className="login-button">
                            簡易ログイン
                          </div>
                        </a>
                      </div>
                    }
                    {
                      this.props.isAuthenticated &&
                      <div className="isAuthenticated">
                        おはようございます,<br/>
                        お客様.
                      </div>
                    }
                  </div>

                  <div className="tabs_wrapper">
                    {/* <a href="https://edge.themusio.com/" onClick={() => {trackEvent("HEADER", "Navigate to: Edge", "")}}>
                      <div className="tabs" onClick={this.toggleFalse}>
                        <img src={EdgeIcon} alt={""}/>
                        <span>edge</span>
                      </div>
                    </a> */}
                    <a href="https://themusio.com/mypage/info">
                      <div className="tabs" onClick={this.toggleFalse}>
                        <img src={MyPageIcon} alt={""}/>
                        <span>my</span>
                      </div>
                    </a>
                    <Link to="/cart" onClick={() => {trackEvent("HEADER", "Navigate to: Cart", "")}}>
                      <div className="tabs" onClick={this.toggleFalse}>
                        <img src={CartIcon} alt={""}/>
                        <span>cart</span>
                      </div>
                    </Link>
                  </div>

                  <div className="accordion_wrapper">
                    <Accordion allowMultiple={true}>
                      {this.state.productIdExists === true 
                  || window.location.pathname.startsWith('/musios')  
                  || window.location.pathname.includes('f7d73106-0f46-525f-b2a3-4f5e7b061293')
                  ? 
                  <AccordionItem title='Menu' key={"arccordionJapan"} expanded={true}>{
                  this.state.itemsJapan.map((item, index) => {
                  let active = "";
                  if (item.name === "Home" && window.location.pathname === "/")
                    active = "active";

                  return (
                    <NavLink
                      to={item.link}
                      key={"header" + index}

                      className="accordion_item"
                      onClick={() => {
                        trackEvent("HEADER", "Navigate to: " + item.name, "");
                      }}
                      style={{fontSize: "18px", lineHeight: "30px"}}
                    >
                      <div className="menu">{item.name}</div>
                    </NavLink>
                  );
                })
              }
                </AccordionItem>
              : <AccordionItem title="Menu" key={"arccordionEng"} expanded={true}>{this.state.items.map((item, index) => {
                let active = "";
                if (item.name === "Home" && window.location.pathname === "/")
                  active = "active";
                return (
                  <NavLink
                    to={item.link}
                    key={"header" + index}
                    className="accordion_item"
                    onClick={() => {
                      trackEvent("HEADER", "Navigate to: " + item.name, "");
                    }}
                    style={{fontSize: "18px", lineHeight: "30px"}}
                  >
                    <div className="menu">{item.name}</div>
                  </NavLink>
                );
              })}
                      </AccordionItem>}
                    </Accordion>
                  </div>

                  {
                    this.props.isAuthenticated &&
                    <a href={THE_MUSIO_URL + AUTH_LOGOUT + "?next=" + STORE_DOMAIN} onClick={() => {trackEvent("HEADER", "Logout", "")}}>
                      <div className="logout">
                        ログアウト
                      </div>
                    </a>
                  } 
                </Col>
              )}
            </Motion>

            <Motion defaultStyle={{opacity: 0}} style={
              {
                opacity: spring(this.state.isToggleOn ? 0.5 : 0, {stiffness: 150})
              }
            }>
              {style => (
                <Col className="overlay" onClick={this.handleClick}
                     style={{
                       opacity: style.opacity,
                       pointerEvents: this.state.isToggleOn ? 'auto' : 'none'
                     }}>

                </Col>
              )}
            </Motion>


          </Row>
        )}
      </Motion>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.AuthReducer.jwt,
    isAuthenticated: state.AuthReducer.isAuthenticated
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch_logout: () => dispatch(logout()),
  }
};

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(MobileHeader));
