import React, {Component} from 'react'
import './css/DetailComment.css'
import PropTypes from 'prop-types'

class DetailComment extends Component {
  render() {
    let comment = this.props;
    return (
      <div className="comment_content">
        <div className="body">{comment.content}</div>
        <div className="score">
          <span className={comment.score > 0 ? "active" : "none"}>★</span>
          <span className={comment.score > 1 ? "active" : "none"}>★</span>
          <span className={comment.score > 2 ? "active" : "none"}>★</span>
          <span className={comment.score > 3 ? "active" : "none"}>★</span>
          <span className={comment.score > 4 ? "active" : "none"}>★</span>
        </div>
        <div className="author">{comment.name}</div>
        <div className="create_date">{comment.create_date}</div>
      </div>
    );
  }
}

DetailComment.propTypes = {
  product_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  create_date: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default DetailComment;
